
import config from '../config.js'

const webarStateNames = {
    REQUESTED: "Angefordert",
    ACTIVE: "Activ",
    INACTIVE: "Inactiv"
};

const appsStateNames = {
    REQUESTED: "Angefordert",
    ACTIVE: "Active",
    DEMO: "Demo"
};

// Takes in CLIPTHIS item.
export function getWebarStateName(item) {
    let status = item.webarStatus ? item.webarStatus : "INACTIVE";
    return webarStateNames[status];
}

// Takes in CLIPTHIS item.
export function getAppsStateName(item) {
    var status;
    // Old items have no appsStatus.
    // So we decide whether they are published based on minimum number of views.
    if (item.appsStatus) {
        status = item.appsStatus;
    } else if (item.maxViews <= config.DEMO_NUMBER_OF_VIEWS) {
        status = "DEMO";
    } else {
        status = "ACTIVE";
    }
    return appsStateNames[status];
}
