import React, { Component } from "react";
import MediaQuery from 'react-responsive';
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import logo from './assets/image/logo.png'
import logoBlack from './assets/image/logo_black.png'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'

import {withRouter, Switch, Route} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Button from "./components/CustomButtons/Button";
import Form from "./views/Pages/Shop/ExtendedForms.jsx";
import LoginPage from "./views/Pages/LoginRegister/LoginPage.jsx";
import LoginInFormPage from "./views/Pages/LoginRegister/LoginInForm.jsx";
import NewPassword from "./views/Pages/LoginRegister/NewPassword.jsx"
import LockScreenPage from "./views/Pages/LoginRegister/LockScreenPage.jsx";
import ForgotPasswordPage from "./views/Pages/LoginRegister/ForgotPassword.jsx";
import AGBPage from "./views/Pages/ExtraPages/AGB.jsx";
import DatenschutzPage from "./views/Pages/ExtraPages/Datenschutz.jsx";
import Dashboard from "./views/Pages/WebApp/Dashboard"; 
import Contact from "./views/Pages/ExtraPages/Contact";
import Home from "./views/Pages/FrontPage/Home";
import NotFound from "./views/Pages/ExtraPages/NotFound";
import Payment from "./views/Pages/Shop/Payment";
import ConfirmationPage from "./views/Pages/ExtraPages/ConfirmationPage.jsx"
import WizardPage from "./views/Pages/Shop/Wizard.jsx"
import ProductCylce from "./views/Pages/Shop/Product_Cycle.jsx"
import Wedding from "./views/Pages/ExtraPages/Wedding.jsx"
import ListItems from "./views/Pages/WebApp/ListItems.jsx";
import NewCampaign from "./views/Pages/WebApp/NewCampaign.jsx"
import ListCampaigns from "./views/Pages/WebApp/ListCampaigns.jsx"
import EditCampaign from "./views/Pages/WebApp/EditCampaign.jsx"
import ListCampaignItems from "./views/Pages/WebApp/ListCampaignItems.jsx"
import ItemDashboard from "./views/Pages/WebApp/ItemDashboard.jsx"
import Apps from "./views/Pages/FrontPage/Apps.jsx"
import Test from "./views/Pages/FrontPage/Test.jsx"
import NewItem from "./views/Pages/WebApp/NewItem.jsx"
import EditItem from "./views/Pages/WebApp/EditItem.jsx"
import Partner from "./views/Pages/FrontPage/Partner.jsx"
import AddItemToCampaign from "./views/Pages/WebApp/AddItemToCampaign.jsx"
import WebAR from "./views/Pages/WebAR/WebAR.jsx";
import ListPatients from "./views/Pages/Physio/ListPatients.jsx"
import NewPatient from "./views/Pages/Physio/NewPatient.jsx"
import StatsOfPatient from "./views/Pages/Physio/StatsOfPatient.jsx"
import FeedbackForPatient from "./views/Pages/Physio/FeedbackForPatient.jsx"
import NewExercise from "./views/Pages/Physio/NewExercises.jsx"
import ListExercises from "./views/Pages/Physio/ListExercises.jsx"

// Registation is disabled for now
// import RegisterPage from "./views/Pages/LoginRegister/RegisterPage.jsx";
// import RegisterInFormPage from "./views/Pages/LoginRegister/RegisterInFormPage.jsx"

import config from "./config";

import {Navbar, Nav, NavItem,} from "react-bootstrap"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// core components
import Footer from "./components/Footer/Footer.jsx";
import Header from "./components/Header/Header.jsx"
import pagesStyle2 from "./assets/layouts/pagesStyle2.jsx";

import "./App.css";
import { Auth } from "aws-amplify";
import AWSAppSyncClient from "aws-appsync";
import gql from 'graphql-tag';
import {registerUserActivity} from "./graphql/mutations";
import awsmobile from './aws-exports';

library.add(faStroopwafel)

class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      anchorEl: null,
      userName: "empty name",
      email: "empty email",
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogo = this.handleLogo.bind(this);
  }

  componentWillMount(){
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;
    
    this.setState({x:x,y:y});
  }

  async componentDidMount() {
    this.loadFacebookSDK();
    try {
      await Auth.currentAuthenticatedUser().then(user => 
        this.userHasAuthenticated(true, user.attributes.email)
        )
      .catch();

      try{
        const { identityId }  = await Auth.currentCredentials()
        this.setState({
          userName: identityId
        });
      }
      catch(e){}

    } catch (e) {
      if (e !== "not authenticated") {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }

  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : config.social.FB,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }
  
  userHasAuthenticated = (authenticated, email) => {
    if(email !== "null"){
    this.setState({ 
      isAuthenticated: authenticated,
      email: email
    });}
    else{
      this.setState({
        isAuthenticated: authenticated
      });
    };
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false, "null");
    this.props.history.push("/");
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogo = event => {
    this.props.history.push("/")
  };

  handleClose(path){
    this.props.history.push({
      pathname: path
      });
  };

  PATHS_WITHOUT_NAVBAR = ["/pages/pwa"];
  needsNavBarAndFooter() {
    const path = window.location.pathname;
    var needs = true;
    for (var i = 0; i < this.PATHS_WITHOUT_NAVBAR.length; i++) {
      if (path === this.PATHS_WITHOUT_NAVBAR[i]) {
        needs = false;
        break;
      }
    }
    return needs;
  }

  awsClient = new AWSAppSyncClient({
    url: awsmobile.aws_appsync_graphqlEndpoint,
    region: awsmobile.aws_appsync_region,
    auth: {
      type: awsmobile.aws_appsync_authenticationType,
      apiKey: awsmobile.aws_appsync_apiKey,
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
  });

  historyTracker = async data => {
    if (data === null)
      // TODO: report error
      console.lor('Data can\'t be null');
    try {
      this.awsClient.mutate({
        mutation: gql(registerUserActivity),
        variables: {
          userEmail: data.userEmail,
          path: data.path,
          details: data.details
        }
      }).then((response) => {
        // Success.
      });
    } catch (e) {
      // TODO: report the exception
    }
  } 
 
  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userName: this.state.userName,
      email: this.state.email,
      historyTracker: this.historyTracker
    };
    const { classes} = this.props;
    const needsNavBarAndFooter = this.needsNavBarAndFooter();
    
    return (
      !this.state.isAuthenticating &&
      <div>
        <MediaQuery minWidth={1224}>
        { needsNavBarAndFooter ? this.desktopNavBar() : null }        
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <Switch>
              <AppliedRoute path="/" exact component={Home} props={childProps} />
              <AppliedRoute path="/apps" exact component={Apps} props={childProps} />
              <AppliedRoute path="/tryit" exact component={Test} props={childProps} />
              <AuthenticatedRoute path="/pages/newItem" exact component={NewItem} props={childProps} />
              <AuthenticatedRoute path="/edititem" exact component={EditItem} props={childProps} />
              <AppliedRoute path="/pages/produkte" exact component={Home} props={childProps} />
              <AppliedRoute path="/pages/agb" exact component={AGBPage} props={childProps} />
              <AppliedRoute path="/pages/datenschutz" exact component={DatenschutzPage} props={childProps} />
              <AuthenticatedRoute path="/pages/itemdashboard" exact component={ItemDashboard} props={childProps} />
              <UnauthenticatedRoute path="/partner" exact component={Partner} props={childProps} />
              <AuthenticatedRoute path="/pages/dashboard" exact component={Dashboard} props={childProps} />
              <AppliedRoute path="/pages/productcycle" exact component={ProductCylce} props={childProps} detail={this.state.isAuthenticated}/>
              <UnauthenticatedRoute path="/pages/login" exact component={LoginPage} props={childProps} />
              <UnauthenticatedRoute path="/pages/newpassword" exact component={ NewPassword} props={childProps} />
              <UnauthenticatedRoute path="/pages/logininform" exact component={LoginInFormPage} props={childProps} />
              <UnauthenticatedRoute path="/pages/forgotpassword" exact component={ForgotPasswordPage} props={childProps}/>
              <AuthenticatedRoute path="/pages/form" exact component={Form} props={childProps} />
              <AuthenticatedRoute path="/pages/printandsend" exact component={WizardPage} props={childProps}/>
              <AuthenticatedRoute path="/pages/pay" exact component={Payment} props={childProps} />
              <AuthenticatedRoute path="/pages/listitems" exact component={ListItems} props={childProps} />
              <AuthenticatedRoute path="/pages/newCampaign" exact component={NewCampaign} props={childProps} />
              <AuthenticatedRoute path="/pages/listcampaigns" exact component={ListCampaigns} props={childProps} />
              <AuthenticatedRoute path="/pages/editcampaign" exact component={EditCampaign} props={childProps} />
              <AuthenticatedRoute path="/pages/listcampaignitems" exact component={ListCampaignItems} props={childProps} />
              <AuthenticatedRoute path="/pages/lock-screen-page" exact component={LockScreenPage} props={childProps} />
              <AuthenticatedRoute path="/pages/additemtocampaign" exact component={AddItemToCampaign} props={childProps} />
              <AuthenticatedRoute path="/pages/confirmation" exact component={ConfirmationPage} props={childProps} />
              <AppliedRoute path="/pages/contact" exact component={Contact} props={childProps} />
              <AppliedRoute path="/pages/wedding" exact component={Wedding} props={childProps} />

              <UnauthenticatedRoute path="/physio/listpatients" exact component={ListPatients} props={childProps}/>
              <UnauthenticatedRoute path="/physio/newpatient" exact component={NewPatient} props={childProps}/>
              <UnauthenticatedRoute path="/physio/feedbackforpatient" exact component={FeedbackForPatient} props={childProps}/>
              <UnauthenticatedRoute path="/physio/statsofpatient" exact component={StatsOfPatient} props={childProps}/>
              <UnauthenticatedRoute path="/physio/listexercises" exact component={ListExercises} props={childProps}/>
              <UnauthenticatedRoute path="/physio/newexercise" exact component={NewExercise} props={childProps}/>
              <AppliedRoute path="/pages/webar" exact component={WebAR} props={childProps}/>

              { /* Finally, catch all unmatched routes */ }
              <Route component={NotFound} />
            </Switch>
            <CookieConsent
              location="bottom"
              buttonText="Verstanden"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
              >
              Diese Website verwendet Cookies für Analysen, personalisierte Inhalte und Werbung. Indem Sie diese Website nutzen, erklären Sie sich mit dieser Verwendung einverstanden.
            </CookieConsent>
            {needsNavBarAndFooter ? <Footer white /> : null}
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div style={{backgroundColor:"#F5F5F7"}}>
          {this.needsNavBarAndFooter() ? this.mobileNavBar() : null }
          <div className={classes.wrapper} ref="wrapper">
            <div className={classes.fullPageMobile}>
              <Switch>
                <AppliedRoute path="/" exact component={Home} props={childProps} />
                <UnauthenticatedRoute path="/apps" exact component={Apps} props={childProps} />
                <UnauthenticatedRoute path="/pages/produkte" exact component={Home} props={childProps} />
                <UnauthenticatedRoute path="/tryit" exact component={Test} props={childProps} />
                <AuthenticatedRoute path="/pages/newItem" exact component={NewItem} props={childProps} />
                <AuthenticatedRoute path="/pages/listitems" exact component={ListItems} props={childProps} />
                <AuthenticatedRoute path="/pages/newCampaign" exact component={NewCampaign} props={childProps} />
                <AuthenticatedRoute path="/pages/listcampaigns" exact component={ListCampaigns} props={childProps} />
                <AuthenticatedRoute path="/pages/editcampaign" exact component={EditCampaign} props={childProps} />
                <AuthenticatedRoute path="/pages/listcampaignitems" exact component={ListCampaignItems} props={childProps} />
                <UnauthenticatedRoute path="/pages/agb" exact component={AGBPage} props={childProps} />
                <UnauthenticatedRoute path="/pages/datenschutz" exact component={DatenschutzPage} props={childProps} />
                <UnauthenticatedRoute path="/partner" exact component={Partner} props={childProps} />
                <UnauthenticatedRoute path="/pages/dashboard" exact component={Dashboard} props={childProps} />
                <UnauthenticatedRoute path="/partner" exact component={Partner} props={childProps} />
                <UnauthenticatedRoute path="/pages/login" exact component={LoginPage} props={childProps} />
                <UnauthenticatedRoute path="/pages/logininform" exact component={LoginInFormPage} props={childProps} />
                <UnauthenticatedRoute path="/pages/contact" exact component={Contact} props={childProps} />
                <AppliedRoute path="/pages/webar" exact component={WebAR} props={childProps}/>
                { /* Finally, catch all unmatched routes */ }
                <Route component={NotFound} />
              </Switch>
              {needsNavBarAndFooter ? <Footer white /> : null}
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
    );
  }

mobileNavBar() {
  return (
    <Navbar collapseOnSelect style={{backgroundcolor:"#F5F5F7", marginBottom:"0px"}}>
          <Navbar.Header>
            <Navbar.Brand>
              <div><img src={logoBlack} alt="Logo" style={{width: '40px'}} onClick={this.handleLogo}/></div>
            </Navbar.Brand>
            <Navbar.Toggle style={{color:"#000000", marginTop:"20px", border:"0"}}/>
          </Navbar.Header>
          <Navbar.Collapse>
            {this.state.isAuthenticated
              ?  
            <Nav>
                <NavItem key="home" selected={true} onClick={() => this.handleClose("/")}>Home</NavItem>
                <NavItem key="produkte" selected={false} onClick={() => this.handleClose("/partner")}>Partner</NavItem>
                <NavItem key="kontakt" selected={false} onClick={() => this.handleClose("/pages/contact")}>Kontakt</NavItem>
                <NavItem key="logout" selected={false} onClick={this.handleLogout}>Logout</NavItem>
              </Nav>
              :
              <Nav>
                <NavItem key="home" selected={true} onClick={() => this.handleClose("/")}>Home</NavItem>
                <NavItem key="apps" selected={false} onClick={() => this.handleClose("/apps")}>Apps</NavItem>
                <NavItem key="partner" selected={false} onClick={() => this.handleClose("/partner")}>Partner</NavItem>
                <NavItem key="kontakt" selected={false} onClick={() => this.handleClose("/pages/contact")}>Kontakt</NavItem>
              </Nav>}
          </Navbar.Collapse>
        </Navbar>
  )
}

PATHS_WITH_LOGOUT_ONLY_TOP_BAR = [
  "/pages/newItem", 
  "/edititem",
  "/pages/itemdashboard",
  "/pages/dashboard",
  "/pages/form",
  "/pages/printandsend",
  "/pages/pay",
  "/pages/listitems",
  "/pages/newCampaign",
  "/pages/listcampaigns",
  "/pages/editcampaign",
  "/pages/listcampaignitems",
  "/pages/lock-screen-page",
  "/pages/additemtocampaign",
  "/pages/additemtocampaign",
  "/pages/confirmation",
  "/pages/listCampaigns",
  "/pages/listItems"
]

hasLogoutInsteadOfNavigation() {
  const path = window.location.pathname;
  return this.PATHS_WITH_LOGOUT_ONLY_TOP_BAR.includes(path)
}

desktopNavBar() {
  const { classes, color} = this.props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  if (this.hasLogoutInsteadOfNavigation()) {
    return (
      // Shows only the lock button in top right corner
      <div style={{backgroundColor:"#3c4858"}}>
        <Header handleLogout={this.handleLogout} />    
      </div>
    )
  }

  return (
    <div style={{backgroundColor: "rgba(0, 0, 0, 0.65)"}}>
            <AppBar position="static" className={classes.appBar + appBarClasses}>
              <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                  <div className={classes.flex}>
                    <Button href="/" className={classes.title} color="transparent">
                    <img src={logo} alt="Logo" className={classes.logoStyle} hspace="10"/> CLIPTHIS
                    </Button>
                  </div>
                </Hidden>
                <Hidden smDown implementation="css">
                  <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <NavLink to={"/apps"} className={classes.navLink}>
                        <ListItemText
                          primary={"Apps"}
                          disableTypography={true}
                          className={classes.listItemText}
                        />
                      </NavLink>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <NavLink to={"/tryit"} className={classes.navLink}>
                        <ListItemText
                          primary={"Create"}
                          disableTypography={true}
                          className={classes.listItemText}
                        />
                      </NavLink>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <NavLink to={"/pages/contact"} className={classes.navLink}>
                        <ListItemText
                          primary={"Contact"}
                          disableTypography={true}
                          className={classes.listItemText}
                        />
                      </NavLink>
                    </ListItem>
                    <ListItem className={classes.listItem} style={{float: "right"}}>
                        <NavLink 
                          to={ this.state.isAuthenticated ? "/pages/listItems" : "/pages/login"} 
                          className={classes.navLink}>
                            <ListItemText
                              primary={"Anmelden"}
                              disableTypography={true}
                              className={classes.listItemText}
                            />
                        </NavLink>
                    </ListItem>
                  </List>
                </Hidden>
              </Toolbar>
            </AppBar>
            
          </div>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle2)(withRouter(App));