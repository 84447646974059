import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';

// @material-ui/icons
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
//import LoaderButton from "../../components/LoaderButton"
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import InfoArea from "../../../components/InfoArea/InfoArea.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx"


// Import Style
import registerPageStyle from "../../../assets/views/registerPageStyle.jsx";

//https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Auth/RequireNewPassword.jsx

class NewPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      isLoading: false,
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newPassword: null,
      usernameMinLength: 0,
      passwordMinLength: 7,
      emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      showPassword: false,
      showConfirmPassword: false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.nextPath = this.nextPath.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)

  }
  
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  validateForm() {
    return (
      true
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleSubmit = async event => {
    const user = this.props.location.state.authData;
    //const challengeParam = user.challengeParam;
    const email = "s.beck@clip-this.com" //challengeParam.requiredAttributes

    await Auth.completeNewPassword(
      user,               // the Cognito User Object
      this.state.password,       // the new password
      // OPTIONAL, the required attributes
      {   
        email
      }
      )
  }
  
  handlePasswordVisibility = name => {
    if(name === 'showConfirmPassword'){
      this.setState({
        showConfirmPassword: !this.state.showConfirmPassword
      });
    } else {
      this.setState({
        showPassword: !this.state.showPassword
      });
    }
  }

  
  nextPath(path) {

    this.props.history.push({
      pathname: path,
      });
  }

  render() {
    const { classes } = this.props;
    return (
          <div className={classes.content}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <Card className={classes.cardSignup}>
                    <h2 className={classes.cardTitle}>Neues Passwort anfordern</h2>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={8} md={5}>
                          <form className={classes.form} onSubmit={this.handleSubmit}>
                            <CustomInput
                              value={this.state.password}
                              onChange={this.handleChange('password')}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <LockOutline className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                placeholder: "New Password"
                              }}
                            />
                            <Button 
                              round 
                              color="primary" 
                              onClick={this.handleSubmit}
                              disabled={!this.validateForm()}>
                                Neues Passwort anfordern</Button>
                          </form>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
    );
  }
}

NewPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(NewPasswordPage);
