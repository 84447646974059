import gql from 'graphql-tag'

export default gql`
  query getClipThisItem(
    $clipThisId: String!
    ) {
      getClipThisItem(input: {
        clipThisId: $clipThisId
        })  {
        items {
          clipThisId
          userId
          displayName
          maxViews
          views
          analytics
        }
      }
    }
  `