import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import MediaQuery from 'react-responsive';

// core components
import Wizard from "../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import Step1Explanation from "../FormSteps/Step1Explanation.jsx";
import Step2ImageVideo from "../FormSteps/Step2ImageVideo.jsx";
import Step3CreateCard from "../FormSteps/Step3CreateCard.jsx";
import Step4Contacts from "../FormSteps/Step4Contacts.jsx";
//import Step5InfoAndSummary from "./FormSteps/Step5InfoAndSummary.jsx";
import Step6Checkout from "../FormSteps/Step6Checkout.jsx";

import wizardPageStyle from "../../../assets/views/wizardPageStyle.jsx";

class WizardView extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      product: "PrintAndSend",
      wunschLink: "Ihr Link",
      email: this.props.email,
      nameCustomer: this.props.name,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { classes } = this.props;
    console.log("EMAIL NAME ", this.state.email, this.state.name);
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <Wizard
                validate
                steps={[
                  { stepName: "Erklärung", stepComponent: Step1Explanation, stepId: "erklaerung" },
                  { stepName: "Bild / Video", stepComponent: Step2ImageVideo, stepId: "bildvideo" },
                  { stepName: "Kartentext", stepComponent: Step3CreateCard, stepId: "kartentext" },
                  { stepName: "Adressen", stepComponent: Step4Contacts, stepId: "adressen" },
                  { stepName: "Checkout", stepComponent: Step6Checkout, stepId: "checkout" }
                ]}
                title="Interaktive Weihnachtskarte"
                subtitle="Erstellen Sie Ihre eigene Augmented Reality Karte."
                finishButtonClick={this.handleSubmit}
                email={this.state.email}
                name={this.state.nameCustomer}
                history={this.props.history}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
      <div className={classes.contentMobile}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <Wizard
                validate
                steps={[
                  { stepName: "Erklärung", stepComponent: Step1Explanation, stepId: "erklaerung" },
                  { stepName: "Bild / Video", stepComponent: Step2ImageVideo, stepId: "bildvideo" },
                  { stepName: "Kartentext", stepComponent: Step3CreateCard, stepId: "kartentext" },
                  { stepName: "Adressen", stepComponent: Step4Contacts, stepId: "adressen" },
                  { stepName: "Checkout", stepComponent: Step6Checkout, stepId: "checkout" }
                ]}
                title="Interaktive Weihnachtskarte"
                subtitle="Erstellen Sie Ihre eigene Augmented Reality Karte."
                finishButtonClick={this.handleSubmit}
                email={this.state.email}
                name={this.state.nameCustomer}
                history={this.props.history}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      
      </MediaQuery>
      </div>
    );
  }
}

WizardView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(wizardPageStyle)(WizardView);