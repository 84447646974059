import {
    colors,
    defaultFont
  } from "../views/material-dashboard-pro-react.jsx";
  
  const basicCustomInputStyle = {
    disabled: {
      "&:before": {
        borderColor: "transparent !important"
      }
    },
    underline: {
      "&:hover:not($disabled):before,&:before": {
        borderColor: "#D2D2D2 !important",
        borderWidth: "1px !important"
      },
      "&:after": {
        borderColor: colors.primaryColor
      }
    },
    underlineError: {
      "&:after": {
        borderColor: colors.dangerColor
      }
    },
    underlineSuccess: {
      "&:after": {
        borderColor: colors.successColor
      }
    },
    labelRoot: {
      ...defaultFont,
      color: "#AAAAAA !important",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.42857",
      paddingLeft: "12px",
      // top: "10px",
      "& + $underline": {
        marginTop: "0px"
      }
    },
    labelRootError: {
      color: colors.dangerColor + " !important"
    },
    labelRootSuccess: {
      color: colors.successColor + " !important"
    },
    feedback: {
      position: "absolute",
      bottom: "3px",
      right: "0",
      zIndex: "2",
      display: "block",
      width: "1em",
      height: "1em",
      textAlign: "center",
      pointerEvents: "none"
    },
    feedbackRight: {
      marginRight: "22px"
    },
    formControl: {
      margin: "0 0 17px 0",
      // paddingTop: "27px",
      position: "relative",
      "& svg,& .fab,& .far,& .fal,& .fas": {
        color: "#495057"
      }
    },
    whiteUnderline: {
      "&:hover:not($disabled):before,&:before": {
        backgroundColor: "#FFFFFF"
      },
      "&:after": {
        backgroundColor: "#FFFFFF"
      }
    },
    input: {
      boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
      border: "#cccccc 1px solid",
      borderRadius: "4px",
      padding: "0.429em 0.857em",
      "&:focus": {
        // boxShadow: "inset 0 1px 1px rgba(0,0,0,.0,75), 0 0 8px rgba(102, 175, 233, 0.6)",
        borderColor: "#66afe9",
      
      },
      "&,&::placeholder": {
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400",
        lineHeight: "1.42857",
        opacity: "1"
      },
      "&::placeholder": {
        color: "#AAAAAA"
      }
    },
    whiteInput: {
      "&,&::placeholder": {
        color: "#FFFFFF",
        opacity: "1"
      }
    }
  };
  
  export default basicCustomInputStyle;
  