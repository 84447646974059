import React from "react";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import postkarte from '../../../assets/image/card/Weihnachtskarte_hinten_blank.jpg';
import anleitung from '../../../assets/image/card/123-Beschreibung.jpg'
import anleitungWhite from '../../../assets/image/card/123-BeschreibungWhite.jpg'

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

const imagesPath = {
  normal: anleitung,
  white: anleitungWhite
}

class Step3CreateCard extends React.Component {
  constructor(props) {
    super(props);
    this.anleitung= anleitung

    this.state = {
      visible: false,

      maxLength: 150,             // Maximal length of text on card
      anleitung: anleitung,       // How to CLIPTHIS manual on card
      textHeight: "100px",
      greetingBottom: "25%",

      checkedAnleitung: this.props.content.checkedAnleitung,
      checkedText: this.props.content.checkedText,
      checkedAdressen: this.props.content.checkedAdressen,
      fontSize: this.props.content.fontSize,
      kartenText: this.props.content.kartenText,
      textPlaceholder: "Schreiben Sie hier den Text ihrer Karten.",
      
      adressPlaceholder1: "Strasse 10",
      adressPlaceholder2: "12345 Stadt",
      adressPlaceholder3: "Empfänger Name",
      headingPlaceholder: "Sehr geehrte/r Kunde/in",
      greetingPlaceholder1: "Mit freundlichen Grüssen,",
      greetingPlaceholder2: "Ihr CLIPTHIS Team",

      imageName: "normal"
    };
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.state.checkedAnleitung){
      this.setState({
        anleitung: anleitung,
        greetingBottom: "25%",
        textHeight: "100px"})
    }else{
      this.setState({
        anleitung: null,
        greetingBottom: "15%",
        maxLength: 250,
        textHeight: "120px"
      });
    };

    if(this.state.checkedText){
      this.setState({
        textPlaceholder: "Schreiben Sie hier den Text für Ihre Karten."
      })
    }else{
      this.setState({
        textPlaceholder: ""
      })
    };

    if(this.state.checkedAdressen){
      this.setState({
        adressPlaceholder1: "Strasse 10",
        adressPlaceholder2: "12345 Stadt",
        adressPlaceholder3: "Empfänger Name",
        headingPlaceholder: "Sehr geehrte/r Kunde/in",
        greetingPlaceholder1: "Mit freundlichen Grüssen,",
        greetingPlaceholder2: "Ihr CLIPTHIS Team"
      });
    }else{
      this.setState({
        adressPlaceholder1: "",
        adressPlaceholder2: "",
        adressPlaceholder3: "",
        headingPlaceholder: "",
        greetingPlaceholder1: "",
        greetingPlaceholder2: ""
      })
    };
  }

  isValidated() {
    if(this.state.kartenText !=="Der Weihnachtstisch ist öd und leer, die Kinder schauen blöd daher"){
      return true;
    }
    else{
      alert("Bitte füllen Sie den Text aus.")
      return false;
    }
  }

  // General function to handle change of certain fields
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  
  handleTextChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value })

    if(value==true){
      this.setState({
        textPlaceholder: "Schreiben Sie hier den Text für Ihre Karten."
      })
    }
    else{
      this.setState({
        textPlaceholder: ""
      })
    }
  }

  handleAdressenChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value })

    if(value==true){
      this.setState({
        adressPlaceholder1: "Strasse 10",
        adressPlaceholder2: "12345 Stadt",
        adressPlaceholder3: "Empfänger Name",
        headingPlaceholder: "Sehr geehrte/r Kunde/in",
        greetingPlaceholder1: "Mit freundlichen Grüssen,",
        greetingPlaceholder2: "Ihr CLIPTHIS Team"
      });
      this.props.handleTextChange(this.state.fontSize, this.state.kartenText,  this.state.handleAnleitungsChoice, this.state.checkedText, true);
    }
    else{
      this.setState({
        adressPlaceholder1: "",
        adressPlaceholder2: "",
        adressPlaceholder3: "",
        headingPlaceholder: "",
        greetingPlaceholder1: "",
        greetingPlaceholder2: ""
      });
      this.props.handleTextChange(this.state.fontSize, this.state.kartenText,  this.state.handleAnleitungsChoice, this.state.checkedText, false);
    }
  }

  handleAnleitungsChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });

    if(value===true){
      this.setState({
          imageName: "normal",
          greetingBottom: "25%",
          textHeight: "100px"
      });
      this.props.handleTextChange(this.state.fontSize, this.state.kartenText, true, this.state.checkedText, this.state.checkedAdressen);

    }
    else{
      this.setState({
        imageName: "white",
        greetingBottom: "15%",
        maxLength: 250,
        textHeight: "120px"
      });
      this.props.handleTextChange(this.state.fontSize, this.state.kartenText, false, this.state.checkedText, this.state.checkedAdressen);
    };
  };

  handleTextChange = event =>{
    var text = event.target.value;
    this.setState({
      kartenText:text
    });
    this.props.handleTextChange(this.state.fontSize, text, this.state.checkedAnleitung, true, this.state.checkedAdressen);
  };

  handleFontChange = event =>{
    this.setState({fontSize: event.target.value})
    this.props.handleTextChange(event.target.value, this.state.kartenText, this.state.checkedAnleitung, this.state.checkedText, this.state.checkedAdressen);
  };

  fillOutText = event =>{
    this.setState({
      visible: true
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <h4 className={classes.infoText}>Möchten Sie den Text ihrer Karten ausfüllen oder möchten Sie leere Karten bestellen?</h4>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.fillOutText}
              className={classes.button}
            >
              Karten Text ausfüllen
            </Button>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.props.handleSkip}
                className={classes.button}
              >
                Leere Karten Bestellen
             </Button>
          </GridItem>
          <div className={this.state.visible ? '' : 'hidden'}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedText}
                  onChange={this.handleTextChoice('checkedText')}
                  value="checkedText"
                  color="default"
                  classes={{root: classes.primaryButtonColor}}
                />
              }
              label="Text ausfüllen"
            />
            <FormControlLabel
              control={
                <Select
                  onChange={this.handleFontChange}
                  value={this.state.fontSize}
                  color="primary"
                  disabled={!this.state.checkedText}
                >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                </Select>
              }
              label="Schriftgröße"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedAnleitung}
                  onChange={this.handleAnleitungsChoice('checkedAnleitung')}
                  value="checkedAnleitung"
                  color="default"
                  classes={{root: classes.primaryButtonColor}}
                />
              }
              label="Anleitung aktivieren"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedAdressen}
                  onChange={this.handleAdressenChoice('checkedAdressen')}
                  value="checkedAdressen"
                  color="default"
                  classes={{root: classes.primaryButtonColor}}
                />
              }
              label="Adressen ausfüllen"
            />
            </FormGroup>
            </div>
        </GridContainer>
        <GridContainer justify="center">
        <div className={this.state.visible ? '' : 'hidden'}>
          <GridItem xs={10}>
            <div className={classes.anleitungThumbnail}>
              <img src={postkarte} alt="" className={classes.postcard}/>
              <img src={imagesPath[this.state.imageName]} alt="" className={classes.anleitung}/>
              <div className={classes.anleitungCaption}>
                  <p>{this.state.headingPlaceholder}</p>
              </div>
              <div className={classes.anleitungBodytext}>
                <textarea 
                  disabled={!this.state.checkedText}
                  ref="kartenText"
                  style={{backgroundColor: "white", resize:"none", width:"65%", height: this.state.textHeight, fontSize: this.state.fontSize, border: 0}}
                  placeholder={this.state.textPlaceholder}
                  data-limit-rows={true}
                  rows={2}
                  maxrows={2}
                  onChange={this.handleTextChange}
                  />
              </div>
              <div className={classes.anleitungEndtext} style={{bottom: this.state.greetingBottom}}>
                  <p>{this.state.greetingPlaceholder1}</p>
                  <p>{this.state.greetingPlaceholder2}</p>
              </div>
              <div className={classes.anleitungAdresse}>
                  <p>{this.state.adressPlaceholder1}</p>
                  <p>{this.state.adressPlaceholder2}</p>
                  <p>{this.state.adressPlaceholder3}</p>
              </div>
            </div>
          </GridItem>
          </div>
        </GridContainer>
    </MediaQuery>
    <MediaQuery maxWidth={1224}>
      <h4 className={classes.infoTextMobile}>Karte beschriften</h4>
      <div className={classes.anleitungThumbnail}>
        <img src={postkarte} alt="" className={classes.postcard}/>
        <img src={this.state.anleitung} alt="" className={classes.anleitung}/>
        <div className={classes.anleitungBodytext}>
          <p>Tragen Sie ihren Wunschtext unten im</p>
          <p>Textfeld ein. Anrede, Gruss und Adressen</p>
          <p>können Sie im nächsten Schritt auswählen.</p>
        </div>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.checkedAnleitung}
            onChange={this.handleAnleitungsChoice('checkedAnleitung')}
            value="checkedAnleitung"
            color="default"
            classes={{root: classes.primaryButtonColor}}
          />
        }
        label="Anleitung aktivieren"
      />
    <p>Leer lassen um eine leere Karte zu kaufen.</p>
    <textarea 
      disabled={false}
      ref="kartenText"
      style={{resize:"none", width:"100%", height: this.state.textHeight, fontSize: this.state.fontSize}}
      placeholder={this.state.textPlaceholder}
      rows={2}
      maxrows={2}
      onChange={this.handleTextChange}
      />
    </MediaQuery>
    </div>
    );
  }
}

export default withStyles(productCyclePageStyle)(Step3CreateCard);
