import gql from 'graphql-tag'

export default gql`
  query listClipThisItemsPerUser($userId: String!) {
    listClipThisItemsPerUser(filter: {
      userId: {
        eq: $userId
      }
    })  {
      items {
        clipThisId
        userId
        displayName
        maxViews
        views
        analytics
        actionData
        actionType
        actionName
        premium
        active
        email
      }
    }
  }
  `