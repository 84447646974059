import React from "react";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {Image, FormGroup} from "react-bootstrap";
import { Player } from 'video-react';
import {Rnd} from "react-rnd";  // Move image libary
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import {FormControl as ReactFormControl} from "react-bootstrap";

// To lock scroll while selecting qr code
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import qrcode from '../../../assets/image/qrcode.png'
import config from "../../../config";

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

class Step2ImageVideo extends React.Component {
  constructor(props) {
    super(props);

    this.fileVideo = this.props.content.fileVideo;
    this.fileImage = this.props.content.fileImage;
    this.targetElement = null;
    this.state = {
      // General paramters
      isLoading: false,           // Showing rotating loading spinner
      imageWidth: 1604,
      imageHeight: 902,
      qrposx: 0,
      qrposy: 0,
      videoWidth: 852,
      videoHeight:480,
      videoPoster: null,    // Image in video player

      // Database entries
      videoFile: this.props.content.fileVideo, 
      imageFile: this.props.content.fileImage, 
      videoChanged: false,
      imageChanged: false,
      qrposxpercentage: 0,
      qrposypercentage: 0,
    };

    this.isValidated = this.isValidated.bind(this); 
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.targetElement = document.getElementById('qrcodeselector');

  }

  isValidated() {
    // Somthing changed, so it everything could be correct
    this.props.setDisabled(false)

    if (this.state.videoChanged){
      // Check video size
      if (this.fileVideo && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Die Größe ihres Videos sollte ${config.MAX_ATTACHMENT_SIZE/1000000} MB nicht überschreiten.`);
        this.props.setDisabled(true)
      }
    }
    
    if (this.state.imageChanged){

      var aspectRatioImage = Math.round(this.state.imageWidth / this.state.imageHeight * 10) / 10;

      if (aspectRatioImage < 1.6 || aspectRatioImage > 1.9) {
        alert(`Das Seitenverhältniss ihres Bildes entspricht nicht 16:9.`);
        this.props.setDisabled(true)
      }
    }

    if (this.state.imageChanged && this.state.videoChanged){

      var aspectRatioVideo = Math.round(this.state.imageWidth / this.state.imageHeight * 10) / 10;
      if (aspectRatioImage - aspectRatioVideo > 0.3 || aspectRatioImage - aspectRatioVideo < -0.3){
        alert(`Das Seitenverhältniss ihres Bildes entspricht nicht dem Seitenverhältniss ihres Videos.`);
        this.props.setDisabled(true)
      }
    }
  }


  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("VIDEO");
    
    video.onloadeddata = function(e) {

      var h264 = "" !== ( video.canPlayType( 'video/mp4; codecs="avc1.42E01E"' )
        || video.canPlayType( 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' ) );
      
      if(h264){
        var canvas = document.createElement('canvas');
        var w = video.videoWidth;
        var h = video.videoHeight;
        canvas.height = h;
        canvas.width = w;
        callback({width: w, height: h, image: null, error: e});
      }
      else{
        callback({error: true});}
    };

    video.onerror = function(e) {
      callback({error: true});
    };

    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }
  
  // Called when user chooses video. Sets new video, triggers getting of width and height.
  handleVideoFileChange = event => {
    this.props.setDisabled(false);
    this.fileVideo = event.target.files[0];

    let url = URL.createObjectURL(this.fileVideo);
    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null
    });
  
    // callback function after video is changed
    this.getVideoInfos(url, (result) => {
      if (result.error !== true){
        this.setState({videoWidth : result.width});
        this.setState({videoHeight : result.height});
        this.setState({videoWasChanged: true})
        this.props.funcVideoChanged()
        this.props.changeVideo(this.fileVideo, url);
        this.isValidated();}
      else{
        alert(`Please pick an video with the format .mp4 or mov.`);
        this.props.setDisabled(true);
        return;
      }
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {

    var img = document.createElement("img");
    
    img.onload = function() {
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };
  
  // Called when user changes new image. Triggers width, height extraction
  // Triggers quality check of image
  handleImageFileChange = event => {
    this.props.setDisabled(false)
    this.fileImage = event.target.files[0];
    if (this.fileImage.type !== "image/jpeg" && this.fileImage.type !== "image/png") {
      alert(`Bitte wählen sie eines der folgenden Formate: .png oder .jpeg.`);
      this.props.setDisabled(true)
    }

    let url = URL.createObjectURL(this.fileImage);
    this.setState({
      imageFile: url,
      imageChanged: true,
    });

    // Check Image Quality
    //this.f(this.fileImage).then(alert('Done'))

    // Callback function after Image is changed
    this.getImageWidthHeight(url, (result) => {
      this.setState({imageWidth : result.width});
      this.setState({imageHeight : result.height});
      this.props.funcImageChanged()
      this.props.changeImage(this.fileImage, url);
      this.isValidated();
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <FormGroup>
              <Player  
                src={this.state.videoFile}
                fluid={false}
                height={250}
                poster={this.state.videoPoster}/>
            </FormGroup> 
          </GridItem>
          <GridItem xs={12} sm={5}>
            <h2 className={classes.infoText}>Wählen Sie ein Video Ihrer Wahl</h2>
            <FormGroup>
              <ReactFormControl onChange={this.handleVideoFileChange} type="file" />
            </FormGroup>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormGroup>
              <Image className={"Image"} src={this.state.imageFile}/>
                <Rnd default={{x: 0, y: 0, width: 50, height: 50}} 
                    bounds={".Image"} 
                    onDragStop={(e, d) => { 
                      if (!this.state.imageChanged){
                        this.setState({ 
                                      qrposx: d.x, 
                                      qrposy: d.y ,
                                      qrposxpercentage: 100*(d.x / 422),
                                      qrposypercentage: 100*(d.y / 200)
                                                })
                        this.props.setqrPosStates(this.state.qrposxpercentage, this.state.qrposypercentage);
                                              }
                      else{
                        this.setState({
                          qrposx: d.x,
                          qrposy: d.y,
                          qrposxpercentage: 100*(d.x / (250 / this.state.imageHeight * this.state.imageWidth -50)), // Relative width of image
                          qrposypercentage: 100*(d.y / 200)
                        })
                        this.props.setqrPosStates(this.state.qrposxpercentage, this.state.qrposypercentage);
                      }
                     }}>
                    <img  src={qrcode} alt="QR Code Position"  title="QR Code Position"  className={classes.dragme}/>
                </Rnd>
              </FormGroup>      
          </GridItem>    
          <GridItem xs={12} sm={5}>
            <h2 className={classes.infoText}>Wählen Sie ein Bild Ihrer Wahl und platzieren Sie den QR Code</h2>
            <FormGroup>
              <ReactFormControl onChange={this.handleImageFileChange} type="file" className={classes.selectButton}/>
            </FormGroup> 
          </GridItem>      
        </GridContainer>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
          <GridContainer justify="center">
            <GridItem xs={10} sm={10}>
            <h4 className={classes.infoTextMobile}>Video auswählen{this.props.numberOfCards}</h4>
              <FormGroup>
                <Player  
                  src={this.state.videoFile}
                  fluid={false}
                  height={125}
                  poster={this.state.videoPoster}/>
              </FormGroup>
              <FormGroup>
                <ReactFormControl onChange={this.handleVideoFileChange} type="file" />
              </FormGroup>
            </GridItem>

            <GridItem xs={10} sm={10}>
            <h4 className={classes.infoTextMobile}>Bild auswählen und QR Code platzieren</h4>
              <FormGroup>
                <Image className={"ImageMobile"} src={this.state.imageFile}/>
                  <Rnd
                    default={{x: 0, y: 0, width: 25, height: 25}} 
                    enableResizing={false}
                    bounds={".ImageMobile"} 

                    onDragStart={() => {
                      disableBodyScroll(this.targetElement);
                    }}

                    onDragStop={(e, d) => { 
                      enableBodyScroll(this.targetElement);
                      if (!this.state.imageChanged){
                        this.setState({ 
                          qrposx: d.x, 
                          qrposy: d.y ,
                          qrposxpercentage: 100*(d.x / 422),
                          qrposypercentage: 100*(d.y / 200)})
                        this.props.setqrPosStates(this.state.qrposxpercentage, this.state.qrposypercentage);
                      }else{
                        this.setState({
                          qrposx: d.x,
                          qrposy: d.y,
                          qrposxpercentage: 100*(d.x / (250 / this.state.imageHeight * this.state.imageWidth -50)), // Relative width of image
                          qrposypercentage: 100*(d.y / 200)
                          })
                        this.props.setqrPosStates(this.state.qrposxpercentage, this.state.qrposypercentage);
                        }
                    }}>
                    
                    <img src={qrcode} id="qrcodeselector" alt="QR Code Position"  title="QR Code Position"  className={classes.dragmeMobile}/>
                  </Rnd>
                </FormGroup>      
                <FormGroup>
                  <ReactFormControl onChange={this.handleImageFileChange} type="file" className={classes.selectButton}/>
                </FormGroup> 
            </GridItem>          
          </GridContainer>
        </MediaQuery>
      </div>
    );
  }
}

export default withStyles(productCyclePageStyle)(Step2ImageVideo);
