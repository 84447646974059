// ##############################
// // // Sidebar styles
// #############################


import bgImage from "./image/lock.jpeg"

import {
    transition,
    boxShadow,
    defaultFont,
    sideBarWidth,

  } from "../assets/views/material-dashboard-pro-react.jsx";
  

  const sidebarStyle = theme => ({
    sideNav: {
      height: "100%",
      width: `${sideBarWidth}px`,
      position: "absolute",
      zIndex: "1",
      top: "0",
      left: "0",
      //background: "linear-gradient(to bottom,#3f51b5,#ffa500)",
      backgroundImage: `url(${bgImage})`,
      backgroundSize: "cover",
      overflowX: "hidden",
      [theme.breakpoints.down("1224")]: {
        width: "100%",
        height: "auto",
        position: "relative",
        paddingTop: "0"
      },
      [theme.breakpoints.down("sm")]: {
      },
      "&:before,&:after": {
      }
    },
    sideNavOverlay: {
      backgroundColor: "rgba(0,0,0,0.7)",
      paddingTop: "20px",
      paddingBottom: "0px",
      height: "100%"
    },
    list: {
      marginTop: "70px",
      paddingLeft: "0",
      paddingTop: "0",
      paddingBottom: "0",
      marginBottom: "0",
      listStyle: "none",
      color: "inherit",
      [theme.breakpoints.down("1224")]: {
        margin: "0",
        padding: "0 40px"
      },
      "&:before,&:after": {
        display: "table",
        content: '" "'
      },
      "&:after": {
        clear: "both"
      }
    },
    item: {
      color: "inherit",
      position: "relative",
      display: "block",
      textDecoration: "none",
      margin: "0",
      padding: "0",
      [theme.breakpoints.down("1224")]: {
        width: "auto",
        float: "left"
      },
      [theme.breakpoints.down("450")]: {
        textAlign: "center",
        float: "none"
      },
    },
    itemLink: {
      fontSize: "15px",
      fontWeight: "400 important!",
      color: "#FFFFFF",
      paddingLeft: "10px",
      paddingRight: "10px",
      transition: "all 300ms linear",
      margin: "10px 15px 0",
      borderRadius: "3px",
      position: "relative",
      display: "block",
      padding: "10px 15px",
      backgroundColor: "transparent",
      ...defaultFont,
      width: "auto",
      [theme.breakpoints.down("1224")]: {
        margin: "10px",
        padding: "5px 15px"
      },
      "&:hover, &:focus ": {
        color: "#FFFFFF",
        // boxShadow: "0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)",
        // backgroundColor: "transparent",
        backgroundColor: "rgba(200, 200, 200, 0.2)",
        textDecoration: "none",
        boxShadow: "none"
      }
    },
    listitemtext: {

      root:{
        primary:{
          color: "#ffffff"
        }
      },
    }

  });
  
  export default sidebarStyle;
  