
export function calculatePrices(shipping, amount){

  var priceSingle = 0;
  var price = 0;

  // Switzerland prices
  if(window.location.hostname.includes(".ch")){

    // Shipping included
    if(shipping === "PrintAtHome"){
      if(0<amount && amount<10){
        priceSingle = 6;
      }else if(10<amount && amount<50){
        priceSingle = 5;
      }else if(50<amount && amount<500){
        priceSingle = 4;
      }else if(500<amount){
        priceSingle = 2;
      }
      price = priceSingle * amount;
    }
    // Shipping one time
    else if(shipping === "SendHome"){
      if(0<amount && amount<10){
        priceSingle = 8;
      }else if(10<amount && amount<50){
        priceSingle = 7;
      }else if(50<amount && amount<500){
        priceSingle = 6;
      }else if(500<amount){
        priceSingle = 4;
      }
      price = priceSingle * amount + 10

    // Shipping print all and send out
    }else if(shipping === "PrintAndSend"){
      if(0<amount && amount<10){
        priceSingle = 10;
      }else if(10<amount && amount<50){
        priceSingle = 7;
      }else if(50<amount && amount<500){
        priceSingle = 5;
      }else if(500<amount){
        priceSingle = 4;
      }
      price = priceSingle * amount
    }
  }
  // German Prices
  else{
    // Shipping included
    if(shipping === "PrintAtHome"){
      if(0<amount && amount<10){
        priceSingle = 5;
      }else if(10<amount && amount<50){
        priceSingle = 4;
      }else if(50<amount && amount<500){
        priceSingle = 2;
      }else if(500<amount){
        priceSingle = 1.5;
      }
      price = priceSingle * amount;
    }
    // Shipping one time
    else if(shipping === "SendHome"){
      if(0<amount && amount<10){
        priceSingle = 6;
      }else if(10<amount && amount<50){
        priceSingle = 5;
      }else if(50<amount && amount<500){
        priceSingle = 3;
      }else if(500<amount){
        priceSingle = 2;
      }
      price = priceSingle * amount + 10

    // Shipping print all and send out
    }else if(shipping === "PrintAndSend"){
      if(0<amount && amount<10){
        priceSingle = 6;
      }else if(10<amount && amount<50){
        priceSingle = 5;
      }else if(50<amount && amount<500){
        priceSingle = 3;
      }else if(500<amount){
        priceSingle = 2;
      }
      price = priceSingle * amount
    }
  }

  return price;
  }