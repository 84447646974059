import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
//import LoaderButton from "../../../components/LoaderButton"
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import FacebookButton from "../../../components/Facebook/FacebookButton.jsx";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import loginPageStyle from "../../../assets/views/loginPageStyle.jsx";

class LoginInFormPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      isLoading: false,
      email: "",
      password: "",
      showPassword: false,
      step: this.props.location.state.step,
      redirect: false,
      content: this.props.location.state.content,
    };
    this.nextPath = this.nextPath.bind(this);
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.nextPath("/pages/productcycle", 5, this.state.content)

    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }
      
  handlePasswordVisibility = () => {
    this.setState({ 
      showPassword: !this.state.showPassword 
    });
  }

  handleFbLogin = () => {
    this.props.userHasAuthenticated(true);
  };  

  nextPath(path, detail, content) {

    this.props.history.push({
      pathname: path,
      state: {
        detail: detail,
        content: content
      }
      });
  }
  
  render() {
    const { classes } = this.props;
    if (this.state.redirect){
        this.nextPath("/pages/productcycle", 5, this.state.content)
    };
    return (
      <div> 
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                  <form onSubmit={this.handleSubmit}>
                    <Card login className={classes[this.state.cardAnimaton]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="primary"
                      >
                        <h4 className={classes.cardTitle}>Log in</h4>
                        <h4 className={classes.socialLine}>
                          CLIPTHIS - Augmented Reality selber machen!
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <FacebookButton
                          onLogin={this.handleFbLogin}/>
                        <hr />
                        <CustomInput
                          labelText="Email..."
                          id="email"
                          autoFocus="true"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          type="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handlePasswordVisibility}
                                >
                                  {this.state.showPassword ?  <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                <LockOutline
                                    className={classes.inputAdornmentIcon}
                                  />
                              </InputAdornment>
                            ),
                            type: this.state.showPassword ? 'text' : 'password'
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                      <Button 
                        round 
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={!this.validateForm()}>
                        Anmelden</Button>
                      </CardFooter>
                      <h4 className={classes.registerText} onClick={() => this.nextPath('/pages/registerinform', 5, this.state.content)}>Noch nicht Kunde? Jetzt registrieren.</h4>
                    </Card>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <form onSubmit={this.handleSubmit}>
                    <h2 className={classes.TitleMobile}>Log in</h2>
                    <div className={classes.socialLineMobile}>
                      CLIPTHIS - Augmented Reality selber machen!
                    </div>
                    <br/>
                    <FacebookButton onLogin={this.handleFbLogin}/>
                    <hr />
                    <CustomInput
                          labelText="Email"
                          id="email"
                          autoFocus="true"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          type="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handlePasswordVisibility}
                                >
                                  {this.state.showPassword ?  <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                <LockOutline
                                    className={classes.inputAdornmentIcon}
                                  />
                              </InputAdornment>
                            ),
                            type: this.state.showPassword ? 'text' : 'password'
                          }}
                        />
                      <Button 
                        fullWidth
                        round 
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={!this.validateForm()}>
                        Anmelden</Button>
                      <h4 className={classes.registerText} onClick={() => this.nextPath('/pages/registerinform', 5, this.state.content)}>Noch nicht Kunde? Jetzt registrieren.</h4>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

LoginInFormPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginInFormPage);
