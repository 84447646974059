// ##############################
// // // RegisterPage view styles
// #############################

import {
    container,
    cardTitle,
    colors    
  } from "./material-dashboard-pro-react.jsx";
  
  const confirmationPageStyle = {
    container,
    content: {
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    },
    cardTitle: {
      ...cardTitle,
      textAlign: "center"
    },
    cardSignup: {
      borderRadius: "6px",
      boxShadow:
        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
      marginBottom: "100px",
      padding: "40px 0px",
      marginTop: "15vh"
    },
    infoArea: {
      maxWidth: "360px",
      margin: "0 auto",
      padding: "0px"
    },
    iconWrapper: {
      float: "left",
      marginTop: "24px",
      marginRight: "10px"
    },
    center: {
      textAlign: "center"
    },
    right: {
      textAlign: "right"
    },
    left: {
      textAlign: "left"
    },
    form: {
      padding: "0 20px",
      position: "relative"
    },
    socialTitle: {
      fontSize: "18px"
    },
    inputAdornment: {
      marginRight: "18px",
      position: "relative"
    },
    inputAdornmentIcon: {
      color: "#555"
    },
    customFormControlClasses: {
      margin: "0 12px"
    },
    checkboxLabelControl: {
      margin: "0"
    },
    checkboxLabel: {
      marginLeft: "6px",
      color: "rgba(0, 0, 0, 0.26)"
    },
    primary: {
      color: colors.primaryColor
    },
    warning: {
      color: colors.warningColor
    },
    danger: {
      color: colors.dangerColor
    },
    success: {
      color: colors.successColor
    },
    info: {
      color: colors.infoColor
    },
    rose: {
      color: colors.roseColor
    },
    gray: {
      color: colors.grayColor
    },
    icon: {
      width: "36px",
      height: "36px"
    },
    descriptionWrapper: {
      color: colors.grayColor,
      overflow: "hidden"
    },
    title: {
      color: "#3C4858",
      margin: "30px 0 15px",
      textDecoration: "none",
      fontSize: "18px"
    },
    description: {
      color: colors.grayColor,
      overflow: "hidden",
      marginTop: "0px",
      fontSize: "14px"
    }
  };
  
  export default confirmationPageStyle;
  