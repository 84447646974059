import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import React from "react";

import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";

import AddIcon from "@material-ui/icons/ControlPoint"

import listExercisesPageStyle from "../../../assets/views/listExercisesPageStyle.jsx";

import image1 from "../../../assets/physio/BridgingBeineAnheben.jpg"
import image2 from "../../../assets/physio/BridgingStatisch.jpg"
import image3 from "../../../assets/physio/DoubleLegRaise.jpg"
import image4 from "../../../assets/physio/ExtensionImLiegen.jpg"
import image5 from "../../../assets/physio/Plank.jpg"
import image6 from "../../../assets/physio/SchwimmenAufPezziBall1.jpg"

class Exercise extends React.Component {

    constructor(props) {
      super(props);
      
      this.state={
        name: this.props.name,
        item: this.props.item,
        image: this.props.image,
        bullets: this.props.bullets,
        images: [image1, image2, image3, image4, image5, image6]
      }
    }
  
    render() {
      const { classes } = this.props;
      return (
        <Card className={classes.exerciseCard}>
          <CardBody>
            <GridContainer>
              <GridItem xs={10}>
                <h4>{this.state.name}</h4>
                <div>{this.state.item}</div>
              </GridItem>
              <GridItem xs={2}>
                <Button justIcon round simple onClick={() => {
                // Go to edit item page and feed obj data
                this.props.history.push({
                  pathname: "/physio/feedbackforpatient",
                  })
                }}
                color="info"
                className="like"
                >
                <AddIcon />
                </Button>
              </GridItem>
            </GridContainer>
            <img src={this.state.images[this.state.image-1]} style={{width:"100%", marginBottom:"20px"}}/>
            <div>{this.state.bullets}</div>
          </CardBody>
        </Card>
      );
    }
  };

  Exercise.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listExercisesPageStyle)(Exercise);
