// ##############################
// // // LoginPage view styles
// #############################

import {
    container,
    largeScreenSideBar,
    mediumScreenSideBar,
  } from "../views/material-dashboard-pro-react.jsx";
  
  const listItemsPageStyle = theme => ({
    container: {
      ...container,
      [theme.breakpoints.up("1224")]: {
        marginRight: `calc((100vw - ${mediumScreenSideBar}px)/2)`, // align content center on remaining of page
        width: "970px"
      },
      [theme.breakpoints.up("1390")]: {
        marginRight: `calc((100vw - ${largeScreenSideBar}px)/2)`, // align content center on remaining of page
        width: "1170px"
      },
    },
    listItemContainer: {
      backgroundColor: "#fff",
      padding: "5px 7px"
    },
    content: {
      backgroundColor: "#eee",
      paddingTop: "18vh",
      paddingBottom: "9vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4",
      [theme.breakpoints.down("1224")]: {
        paddingTop: "0"
      },
    },
    spinner: {
      display: "block",
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: "5",
      margin: "0 auto"
    },
    table:{
      textAlign: "center"
    }
  });
  
  export default listItemsPageStyle;
  