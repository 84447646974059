import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { ClipLoader } from "react-spinners";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";

import { Storage } from "aws-amplify";
import config from "./../../../config.js";

import { dailySalesChart } from "../../../variables/charts";
import dashboardStyle from "../../../assets/views/dashboardStyle";

import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

var Chartist = require("chartist");

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      clipThisId: this.props.location.state.clipThisId,
      displayName: this.props.location.state.displayName,
      analytics: this.props.location.state.analytics,

      appsDataAvailable: null,
      webARDataAvailable: null,
      showingAppsData: null,
      appsData: null,
      webARData: null,

      averageLengthWatched: null,
      averageStartsPerUser: null,
      numberOfUsersWatchedTillEnd: null,
      totalActionSuccessTimes: null,
      totalActionTriggerTimes: null,
      totalNumberOfFullViews: null,
      totalNumberOfUsers: null,
      totalNumberOfViews: null,

      actionSuccessTimesMillisec: null,
      actionSuccessWithTime: null,
      actionTriggerTimesMillisec: null,
      actionTriggersWithTime: null,

      usersWithTime: null,
      videoStopTimesMillisec: null,
      viewsWithTime: null,

      numberDays: 7,
      usersWithTimeChart: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [[]]
      },
      viewsWithTimeChart: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [[]]
      },
      dailyAction7Chart: null,
      dailyViews30Chart: null,
      dailyAction30Chart: null,
      dailyViews90Chart: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [[]]
      },
      dailyAction90Chart: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const filename = this.state.clipThisId + ".json";
    this.downloadAppsAnalytics(
      filename, 
      config.s3.ANALYTICS.APPS, 
      "appsData", 
      "appsDataAvailable"
    );
    this.downloadAppsAnalytics(
      filename,
      config.s3.ANALYTICS.WEBAR,
      "webARData",
      "webARDataAvailable"
    );
    
    // Track user visiting this page
    const email = this.props.email;
    const clipThisId = this.props.location.state.clipThisId;
    this.props.historyTracker({
      userEmail: email,
      details: "clipThisId: " + clipThisId,
      path: window.location.pathname
    })
  }

  async downloadAppsAnalytics(filename, bucket, dataKey, dataStateKey) {
    try {
      let result = await Storage.get(filename, {
        bucket: bucket,
        download: true
      });
      const bodyString = result.Body.toString("utf8");
      this.setState({ 
        [dataKey]: JSON.parse(bodyString),
        [dataStateKey]: true 
      });
      this.finishLoading();
    } catch (err) {
      console.log(err);
      // 404 error is treated as no data
      this.setState({ 
        [dataStateKey]: false 
      });
      this.finishLoading();
    }
  }

  finishLoading() {
    // Loading is completed when we know states of both datas.
    if (this.state.appsDataAvailable !== null
      && this.state.webARDataAvailable !== null) {

      // Dismiss loader and show data. 
      // Display apps data by default if it's available.
      if (this.state.appsData) {
        this.displayAnalytics(this.state.appsData);
        this.setState({
          showingAppsData: true,
          isLoading: false
        })
      } else if (this.state.webARData) {
        this.displayAnalytics(this.state.webARData);
        this.setState({
          showingAppsData: false,
          isLoading: false
        })
      } else {
        this.setState({
          isLoading: false
        })
      }
    }
  }

  displayAnalytics(obj) {
    this.setState({
      averageLengthWatched:
        Math.round(100 * (obj["averageLengthWatched"] / 1000)) / 100,
      averageStartsPerUser: obj["averageStartsPerUser"],
      numberOfUsersWatchedTillEnd: obj["numberOfUsersWatchedTillEnd"],
      totalActionSuccessTimes: obj["totalActionSuccessTimes"],
      totalActionTriggerTimes: obj["totalActionTriggerTimes"],
      totalNumberOfFullViews: obj["totalNumberOfFullViews"],
      totalNumberOfUsers: obj["totalNumberOfUsers"],
      totalNumberOfViews: obj["totalNumberOfViews"],

      actionSuccessTimesMillisec: obj["actionSuccessTimesMillisec"],
      actionSuccessWithTime: obj["actionSuccessWithTime"],
      actionTriggerTimesMillisec: obj["actionTriggerTimesMillisec"],
      actionTriggersWithTime: obj["actionTriggersWithTime"],

      usersWithTime: obj["usersWithTime"],
      videoStopTimesMillisec: obj["videoStopTimesMillisec"],
      viewsWithTime: obj["viewsWithTime"]
    });

    var usersWithTimeDates = [];
    var usersWithTimeValues = [];
    var viewsWithTimeDates = [];
    var viewsWithTimeValues = [];
    let keys = Object.keys(obj["usersWithTime"]);

    for (var i = 0; i < keys.length; i++) {
      let key = keys[i];
      usersWithTimeDates.push(new Date(key));
      usersWithTimeValues.push(obj["usersWithTime"][key]);
    }
    let viewsKeys = Object.keys(obj["viewsWithTime"]);
    for (i = 0; i < viewsKeys.length; i++) {
      let viewKey = viewsKeys[i];
      viewsWithTimeDates.push(new Date(viewKey));
      viewsWithTimeValues.push(obj["viewsWithTime"][viewKey]);
    }
    
    this.setState({
      usersWithTimeChart: {
        labels: usersWithTimeDates,
        series: [usersWithTimeValues]
      },
      viewsWithTimeChart: {
        labels: viewsWithTimeDates,
        series: [viewsWithTimeValues]
      }
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  countOccurences(arr_dates, arr_actions, value) {
    var countDates = 0;
    var countActions = 0;

    for (var i = 0; i < arr_dates.length; i++) {
      if (
        arr_dates[i].getDate() === value.getDate() &&
        arr_dates[i].getMonth() === value.getMonth()
      ) {
        countDates++;
        if (arr_actions[i]) {
          countActions++;
        }
      }
    }
    var list = [countDates, countActions];
    return list;
  }

  SWITCH_APPS_KEY = "apps";
  SWITCH_WEBAR_KEY = "webar";

  handleFilterSwitch(type) {
    // If the selected data type is not avaiable, show an alert.
    if ( (type === this.SWITCH_APPS_KEY && !this.state.appsDataAvailable)
      || (type === this.SWITCH_WEBAR_KEY && !this.state.webARDataAvailable) ) {
        const typeMsg = type === this.SWITCH_APPS_KEY ? "Apps" : "WebAR"
        const msg = "Diese Daten sind nicht verfügbar oder nicht aktiviert. Bitte überprüfen Sie die Einstellungen des Inhalts, um sicherzustellen, dass der Inhalt in " + typeMsg + " veröffentlicht ist."
        alert(msg)
      return
    }

    this.setState({
      showingAppsData: type === this.SWITCH_APPS_KEY
    });
    if (type === this.SWITCH_APPS_KEY) {
      this.displayAnalytics(this.state.appsData);
    } else if (type === this.SWITCH_WEBAR_KEY) {
      this.displayAnalytics(this.state.webARData);
    }
  }

  hasNoData() { 
    return this.state.appsDataAvailable === false 
      && this.state.webARDataAvailable === false;
  }
  
  analyticsFilterOrNull() {
    if (this.hasNoData())
      return(null);

    return (
      <GridContainer justify="center">
         <GridItem xs={2}>
          <Button
            simple={this.state.showingAppsData === false}
            color="success"
            onClick={()=>{this.handleFilterSwitch(this.SWITCH_APPS_KEY)}}>
            Apps Analyse
          </Button>
        </GridItem>
        <GridItem xs={2}>
          <Button
            simple={this.state.showingAppsData === true}
            color="success"
            onClick={()=>{this.handleFilterSwitch(this.SWITCH_WEBAR_KEY)}}> 
            WebAR Analyse
          </Button>
        </GridItem>
      </GridContainer>
    )
  }

  spinnerAndSidebar(classes) {
    return(
      <div style={{ backgroundColor: "#333333" }}>
        <div className={classes.content}>
          <GridContainer>
            <Sidebar />
          </GridContainer>
          <div className={classes.container}>
            <div className={classes.spinner}>
              <ClipLoader
                className={classes.spinner}
                sizeUnit={"px"}
                size={150}
                color={"#123abc"}
                loading={this.state.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  chartOptions(chartData) {
    const maxXLabelCount = 10;
    const numXPoints = chartData.labels.length;
    // If number of labels is < max, show them all.
    // For this case we use 1 as minumim fator.
    const xFactor = Math.max(1, Math.ceil(numXPoints / maxXLabelCount));
    const maxY = chartData.series[0].reduce(function(a, b) {
      return Math.max(a, b);
    });

    return {
      lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
      color: "#000",
      low: 0,
      // it is recommended to set the high sa the biggest value 
      // + something for a better look
      high: maxY * 1.05,
      chartPadding: { top: 0, right: 20, bottom: 0, left: 0 },
      axisX: {
        // show only maxXLabelCount labels
        labelInterpolationFnc: function(value, index) {
          // toLocaleDateString to show human-readable date
          return index % xFactor === 0 ? value.toLocaleDateString(): null;
        },
      }
    };
  }

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) {
      return this.spinnerAndSidebar(classes);
    }

    const usersChartOptions = this.hasNoData() ? {} : this.chartOptions(this.state.usersWithTimeChart)
    const viewsChartOptions = this.hasNoData() ? {} : this.chartOptions(this.state.viewsWithTimeChart)
    const dataDescription = this.hasNoData() ? "Noch keine Daten verfügbar. " : "";

    return (
      <div style={{ backgroundColor: "#333333" }}>
        <div className={classes.content}>
          <GridContainer>
            <Sidebar />
          </GridContainer>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <h2 className={classes.title}>{this.state.displayName}</h2>
              </GridItem>
              <GridItem>
                <h5> {dataDescription} Wird täglich um 2.00 in der Nacht geupdated.</h5>
              </GridItem>
            </GridContainer>
            { this.analyticsFilterOrNull() }
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <p className={classes.cardCategory}>Total Anzahl User</p>
                    <h3 className={classes.cardTitle}>
                      {this.state.totalNumberOfUsers}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <p className={classes.cardCategory}>Total Anzahl Views</p>
                    <h3 className={classes.cardTitle}>
                      {this.state.totalNumberOfViews}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <p className={classes.cardCategory}>
                      Total Anzahl Aktionen
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.totalActionSuccessTimes}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <p className={classes.cardCategory}>
                      Total Action Trigger Times
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.totalActionTriggerTimes}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <p className={classes.cardCategory}>Ø Video Abspiellänge</p>
                    <h3 className={classes.cardTitle}>
                      {this.state.averageLengthWatched} Sek.
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <p className={classes.cardCategory}>
                      Ø Videostarts pro User
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.averageStartsPerUser}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <p className={classes.cardCategory}>
                      # User mit vollständigen Views
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.numberOfUsersWatchedTillEnd}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <p className={classes.cardCategory}>Vollständige Views</p>
                    <h3 className={classes.cardTitle}>
                      {this.state.totalNumberOfFullViews}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader className={classes.cardHeaderHover}>
                    <ChartistGraph
                      data={this.state.usersWithTimeChart}
                      type="Line"
                      options={usersChartOptions}
                      listener={dailySalesChart.animation}
                      className={classes.graphStyle}
                    />
                  </CardHeader>
                  <CardBody>
                    {/* <div className={classes.cardHoverUnder}>
                      <Tooltip
                        id="tooltip-top"
                        title="Refresh"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                        >
                        <Button simple color="info" justIcon>
                          <Refresh className={classes.underChartIcons} />
                        </Button>
                      </Tooltip>
                    </div> */}
                    <h4 className={classes.cardTitle}>Tägliche User Zahlen</h4>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <Card chart className={classes.cardHover}>
                  <CardHeader className={classes.cardHeaderHover}>
                    <ChartistGraph
                      data={this.state.viewsWithTimeChart}
                      type="Line"
                      options={viewsChartOptions}
                      listener={dailySalesChart.animation}
                      className={classes.graphStyle}
                    />
                  </CardHeader>
                  <CardBody>
                    {/* <div className={classes.cardHoverUnder}>
                      <Tooltip
                        id="tooltip-top"
                        title="Refresh"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button simple color="info" justIcon>
                          <Refresh className={classes.underChartIcons} />
                        </Button>
                      </Tooltip>
                    </div> */}
                    <h4 className={classes.cardTitle}>Tägliche Views</h4>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> Gesamter Zeitraum
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
