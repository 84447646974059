import React from "react";
import PropTypes from "prop-types";
import CSVReader from 'react-csv-reader'
//import Datetime from "react-datetime";
import { CSVLink} from "react-csv";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import {Rnd} from "react-rnd";  // Move image libary
import {Image, FormGroup} from "react-bootstrap";
import {FormControl as ReactFormControl} from "react-bootstrap";

import { ClipLoader } from 'react-spinners';

// aws immport
import { API } from "aws-amplify";
import { s3Upload } from "../../../libs/awsLib";

// Payment with stripe
import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentForm from '../../../components/CheckoutForm/PaymentForm.jsx';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.jsx";
import Switch from "@material-ui/core/Switch";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons
import ImageIcon from "@material-ui/icons/Image";
import VideoIcon from "@material-ui/icons/PlayCircleFilled";
import GiftCardIcon from "@material-ui/icons/CardGiftcard";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import InfoIcon from "@material-ui/icons/Info";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import SubjectIcon from "@material-ui/icons/Subject";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PaymentIcon from "@material-ui/icons/Payment";
import EmailIcon from "@material-ui/icons/Email";
import FaceIcon from "@material-ui/icons/Face";


// core components
import Table from "../../../components/Table/Table.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
//import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import DatePicker from "../../../components/DatePicker/DatePicker.jsx"
//import 'react-dates/lib/css/_datepicker.css';
//import { SingleDatePicker} from 'react-dates';

import extendedFormsStyle from "../../../assets/views/extendedFormsStyle.jsx";

import postkarte from '../../../assets/image/card/Weihnachtskarte_hinten_blank.jpg';
import firstImage from '../../../assets/image/card/christmascard.jpg'
import anleitung from '../../../assets/image/card/123-Beschreibung.jpg'
import firstVideo from '../../../assets/image/card/christmascard.mp4'
import qrcode from '../../../assets/image/qrcode.png'

import { Player } from 'video-react';
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import config from "../../../config";


class ExtendedForms extends React.Component {
  constructor(props) {
    super(props);

    this.fileVideo = firstVideo;
    this.fileImage = firstImage;
    this.maxRowsInCardText = 12;
    this.packete = ["Packet1", "Packet2", "Packet3"];
    this.preise = ["Preise1", "Preise2", "Preise3"];

    this.state = {
      
      // General paramters
      isLoading: false,           // Showing rotating loading spinner
      amount: 10000,                  // Price of how much to pay
      imageWidth: 1604,
      imageHeight: 902,
      qrposx: 0,
      qrposy: 0,
      videoWidth: 852,
      videoHeight:480,
      videoPoster: firstImage,    // Image in video player
      videoChanged: false,
      imageChanged: false,

      maxLength: 150,             // Maximal length of text on card

      anleitung: anleitung,       // How to CLIPTHIS manual on card

      empfaengeranrede: "Lieber Kunde,",
      empfaengergruss1: "Wir wünschen Ihnen eine besinnliche Weihnachtszeit,",
      empfaengergruss2: "Ihr CLIPTHIS Team",
      empfaengerName: "Sven Beck",
      empfaengerStrasse: "St. KarliStrasse 13c",
      empfaengerPlzStadt: "6004 Luzern",

      contactName:"",             // Inputs in contact field
      contactStrasse:"",
      contactPostleitzahl:"",
      contactStadt:"",
      contactAndrede:"",
      contactGruss:"",
      contactGrussname:"",

      checked:false,

      fontSizeHeading: 26,
      fontSizeGreeting: 18,
      greetingTop: "55%",

      amountCards:0,              // Number of cards to calculate price
      priceTotal: 0,              // Calculated Price
      priceSingle: 0,             // Price Single  
      nameCustomer: this.props.email,
      product: "PrintAndSend",

      // Database entries
      videoFile: firstVideo,
      imageFile: firstImage,
      qrposxpercentage: 0,
      qrposypercentage: 0,
      checkedAnleitung: true,
      kartenText: "Der Weihnachtstisch ist öd und leer, die Kinder schauen blöd daher.\nDa lässt der Vater einen krachen, die Kinder fangen an zu lachen.\nSo kann man auch mit kleinen Sachen, den Kindern eine Freude machen.",
      fontSize: 14,

      tableData: [],
      date: "not specified",
      wunschLink: "Ihr Link",
      email: this.props.email,
    };

    // Bind functions
    this.handleAnleitungsChoice = this.handleAnleitungsChoice.bind(this);
    this.handleKartenTextChange = this.handleKartenTextChange.bind(this);
    this.handleContactFormSubmit = this.handleContactFormSubmit.bind(this);
    this.nextPath = this.nextPath.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("VIDEO");

    video.onloadeddata = function(e) {
      var canvas = document.createElement('canvas');
      var w = video.videoWidth;
      var h = video.videoHeight;
      canvas.height = h;
      canvas.width = w;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback({width: w, height: h, image: img, error: e});
    };
    video.onerror = function(e) {
      callback.call(undefined, undefined, undefined, e);
    };
    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }
  
  // Called when user chooses video. Sets new video, triggers getting of width and height.
  handleVideoFileChange = event => {
    this.fileVideo = event.target.files[0];
    if (this.fileVideo.type !== "video/mp4") {
      alert(`Please pick an video with the format .mp4.`);
      return;
    }
    let url = URL.createObjectURL(this.fileVideo);
    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null
    });
  
    // callback function after video is changed
    this.getVideoInfos(url, (result) => {
      this.setState({videoWidth : result.width});
      this.setState({videoHeight : result.height});
      //this.setState({videoPoster: result.image.path});
      this.setState({videoWasChanged: true})
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };
  
  // Called when user changes new image. Triggers width, height extraction
  // Triggers quality check of image
  handleImageFileChange = event => {
    this.fileImage = event.target.files[0];
    let url = URL.createObjectURL(this.fileImage);
    this.setState({
      imageFile: url,
      imageChanged: true,
    });

    // Check Image Quality
    //this.f(this.fileImage).then(alert('Done'))

    // Callback function after Image is changed
    this.getImageWidthHeight(url, (result) => {
      this.setState({imageWidth : result.width});
      this.setState({imageHeight : result.height});
    });
  };

  // General function to handle change of certain fields
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Check if user wants to integrate manual image on card and adopt max text length which is allowed
  handleAnleitungsChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });

    if(value===true){
      this.setState({
          anleitung: anleitung,
          greetingTop: "55%",
          maxLenth: 150
      });
    }
    else{
      this.setState({
        anleitung: null,
        greetingTop: "70%",
        maxLength: 250
      });
    };
  };

  
  // Handle the change of the text in the card
  handleKartenTextChange = event => {
    var text = event.target.value;
    if(text !== ""){                                   // Check that text is not empty
      text = text.split('\n').map((item, i) => <span key={i}>{item}<br/></span>)} // Add the enter action
      if(text.length < this.maxRowsInCardText){   // Only allow certain number of rows in input
        this.setState({
            kartenText: text
        });
      }
  };

  handleTextChange = event =>{
    var text = event.target.value;
    this.setState({
      kartenText:text
    });
  }

  calculatePrices(amount){

    var priceSingle = 0;

    if(0<amount && amount<10){
      priceSingle = 10;
    }else if(9<amount && amount<50){
      priceSingle = 9;
    }else if(49<amount && amount<100){
      priceSingle = 8;
    }else if(99<amount && amount<200){
      priceSingle = 7;
    }else if(199<amount && amount<500){
      priceSingle = 6;
    }else if(499<amount && amount<1000){
      priceSingle = 5;
    }else if(999<amount && amount<2000){
      priceSingle = 4;
    }else{
      priceSingle = 3;
    }
    var price = priceSingle * amount;

    return [price, priceSingle]
  }

  // Upload of .csv file filled with contact data
  handleCSV = data => {
    const header = ["Name",	"Strasse",  "Postleitzahl", "Stadt",	"Anrede", "Gruss", "Ihr Name"];
    for(var i = header.length; i--;){
      if(header[i] !== data[0][i]){   // Compare the header of the original and the uploaded file, have to match
        alert("Die Kopfzeile der hochgeladenen Excel Datei entspricht nicht der des Templates.");
        return;
      };
    };
    var prices = this.calculatePrices(data.length-1)
    this.setState({
      tableData: data.slice(1,data.length),    // Slice to only add data, not header
      amountCards: data.length - 1,
      priceTotal: prices[0],
      priceSingle: prices[1],
      empfaengerName: data[1][0],
      empfaengerStrasse: data[1][1],
      empfaengerPlzStadt: data[1][2] + " " + data[1][3],
      empfaengeranrede: data[1][4],
      empfaengergruss1: data[1][5],
      empfaengergruss2: data[1][6]
    });
  };

  // Adds a new row to the concat table data
  handleContactFormSubmit = event => {
    event.preventDefault();
    var thisList = this.state.tableData;
    thisList[thisList.length] = [this.state.contactName, 
                                this.state.contactStrasse, 
                                this.state.contactPostleitzahl, 
                                this.state.contactStadt, 
                                this.state.contactAndrede,
                                this.state.contactGruss,
                                this.state.contactGrussname];
    this.setState({
      tableData: thisList,
      amountCards: thisList.length,
      priceTotal: this.calculatePrices(thisList.length)[0],
      priceSingle: this.calculatePrices(thisList.length)[1],
      empfaengerName: this.state.contactName,
      empfaengerStrasse: this.state.contactStrasse,
      empfaengerPlzStadt: this.state.contactPostleitzahl + " " + this.state.contactStadt,
      empfaengeranrede: this.state.contactAndrede,
      empfaengergruss1: this.state.contactGruss,
      empfaengergruss2: this.state.contactGrussname,
      contactName:"",             // Inputs in contact field
      contactStrasse:"",
      contactPostleitzahl:"",
      contactStadt:"",
      contactAndrede:"",
      contactGruss:"",
      contactGrussname:""
    })
  };
  
  // Check that all contact fields are filled out
  validateContactForm() {return (
    this.state.contactName.length > 0,
    this.state.contactStrasse.length >0,
    this.state.contactPostleitzahl.length >0, 
    this.state.contactStadt.length >0, 
    this.state.contactAndrede.length >0,
    this.state.contactGruss.length >0,
    this.state.contactGrussname.length >0
    );
  };

  // Updates date of datepicker
  handleDate = date =>{
    this.setState({
      date: date}) 
  };

  // API post to create new item
  createItem(item) {    
    return API.post("clpthis", "/clpthis", {
      body: item
    });
  } 

  // API to pay
  billUser(details) {
    return API.post("clpthis", "/billing", {
      body: details
    });
  }

  handleFormSubmit = async ({ token, error }) => {
  }

  nextPath(path, product, preis, amount, preistotal) {

    this.props.history.push({
      pathname: path,
      state: {
        product: product,
        preis: preis,
        amount: amount,
        preistotal
      }
      });
  }

  // Triggered on page submit
  // Checks certain criteria for the video and image
  // Uploads video and image to S3
  // Creates new database entry and redirects to checkout
  handlePageSubmit = async ({ token, error }) => {
    if (error) {
      alert(error);
      return;
    }

    if (this.state.videoChanged){
      // Make sure video is mp4
      if (this.fileVideo.type !== "video/mp4") {
        alert(`Bitte wählen Sie ein Video mit dem Format .mp4.`);
        return;
      }
      // Check video size
      if (this.fileVideo && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Die Größe ihres Videos sollte ${config.MAX_ATTACHMENT_SIZE/1000000} MB nicht überschreiten.`);
        return;
      }
    }
    
    if (this.state.imageChanged){
      // Check image for format
      if (this.fileImage.type !== "image/jpeg" && this.fileImage.type !== "image/png") {
        alert(`Bitte wählen sie eines der folgenden Formate: .png oder .jpeg.`);
        return;
      }

      var aspectRatioImage = Math.round(this.state.imageWidth / this.state.imageHeight * 10) / 10;

      if (aspectRatioImage < 1.6 || aspectRatioImage > 1.9) {
        alert(`Das Seitenverhältniss ihres Bildes entspricht nicht 16:9.`);
        return;
      }

      if (this.state.tableData.length === 0){
        alert(`Gebe Sie bitte mindestens einen Wunschkontakt ein.`)
        return;
      }
    }

    // Get aspect ratios of image and video an compare with certain tolerance
    let tollerance = 0.2;
    let aspectRatioVideo = Math.round(this.state.videoWidth / this.state.videoHeight * 10) / 10;
    if(aspectRatioImage - aspectRatioVideo>tollerance || aspectRatioVideo - aspectRatioImage>tollerance){
      alert("Aspect Ratios do not match. Image:" +  aspectRatioImage + " vs. Video: " + aspectRatioVideo);
      return;
    }

    // Activate loading spinner
    this.setState({ isLoading: true });
  
    try {
      
      var attachment = "original";
      if (this.state.videoChanged){
        // Wait until video is uploaded to S3
        attachment = this.fileVideo
          ? await s3Upload(this.fileVideo)
          : null;
      }
      var imageAttachment = "original";
      if (this.state.imageChanged){
        // Wait until image is uploaded to S3
        imageAttachment = this.fileImage
          ? await s3Upload(this.fileImage)
          : null;
      }
    
      await this.billUser({
        amount: this.state.priceTotal*100,
        source: token.id,
      });  

      console.log("Get to prePay");
        
      // Wait until new database entry is created successfully
      await this.createItem({
        attachment: attachment,
        imageAttachment: imageAttachment,
        imageChanged: this.state.imageChanged,
        qrposxpercentage: this.state.qrposxpercentage,
        qrposypercentage: this.state.qrposypercentage,
        checkedAnleitung: this.state.checkedAnleitung,
        kartenText: this.state.kartenText,
        fontSize: this.state.fontSize,
        tableData: this.state.tableData,
        date: this.state.date,
        wunschLink: this.state.wunschLink,
        email: this.state.email,
        product: this.state.product,
        priceSingle: this.state.priceSingle,
        numberCards: this.state.amountCards,
        nameCustomer: this.state.nameCustomer
      });
      
      // Redirect to homepage
      //this.props.history.push({
       // pathname: "/",
      //});
      this.nextPath("/pages/confirmation", this.state.product, this.state.priceSingle, this.state.amountCards, this.state.priceTotal);
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  validateForm() {
    return (
      this.state.checked === true
    );
  }

  render() {
    const {classes} = this.props;
    const csvData = [
			["Name", "Strasse", "Postleitzahl", "Stadt", "Anrede", "Gruss", "Ihr Name"],
			["Beck", "Europaalee 7", "8004", "Zuerich","Sehr geehrter Herr Beck", "Mit freundlichen Grüssen", "ihr CLIPTHIS Team"],
			["Radelli", "Raemistrasse 101", "8006", "Zuerich", "Lieber Herr Radelli", "Liebe Grüsse", "Toni"],
			["Marx", "Minengasse 13b", "6004", "Luzern", "Liebe Familie Marx", "Eine schöne Weihnachtszeit,", "Kilian Maile"]
    ];

    if (!this.props.isAuthenticated) {
      return;
    }
    console.log(this.props.name);

    return (
      
      <div className={classes.content}>
      <div className={classes.spinner}>
        <ClipLoader
          className={classes.spinner}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.isLoading}
        /> </div>
        <div className={classes.container}>
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Erstellen Sie ihre eigenen Augmented Reality Inhalte.</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <InfoIcon/>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardIconTitle}>Lieber Kunde,</h4>
              <br/>
            <h4 className={classes.cardIconTitle}>wir freuen uns, dass Sie dieses Jahr ganz besondere Weihnachtskarten versenden möchten.
            </h4>
            <br/>
            <h4 className={classes.cardIconTitle}>Um Ihnen den <span style={{fontWeight: "bold"}}>Bestellprozess möglichst einfach und fehlerfrei</span> zu garantieren, würden wir Sie bitten, sich kurz die Beschreibung zu den vier erforderlichen Schritten durchzulesen.</h4>
            <br/>
            <h4 className={classes.cardIconTitle}>Besten Dank und frohe Weihnachten</h4>
            <h4 className={classes.cardIconTitle}><span style={{fontWeight: "bold"}}>Ihr Team von WowPost</span></h4>
            <br/>
         </CardBody>
          </Card>
        </GridItem>
        </GridContainer>

        <GridContainer>
        <GridItem xs={12} sm={3} md={3}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <AddAPhotoIcon/>
              </CardIcon>
              <div className={classes.cardIconTitle}>Bild und Video</div>
              <h4 className={classes.cardIconTitle}>Vorderseite</h4>
            </CardHeader>
            <CardBody>
            <p className={classes.cardDescription}>Hier haben Sie die Wahl Ihre eigene Karte zu kreiieren. 
              Laden Sie dazu Ihr Video und Ihr Bild in den <span style={{fontWeight: "bold"}}>Abmassen 16:9</span> hoch. 
              Alternativ dürfen Sie auch den tanzenden Santa aus der Vorauswahl übernehmen.
            </p>
         </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <SubjectIcon />
              </CardIcon>
              <div className={classes.cardIconTitle}>Weihnachtsgruss</div>
              <h4 className={classes.cardIconTitle}>Rückseite</h4>
            </CardHeader>
            <CardBody>
            <p className={classes.cardDescription}>In diesem Bereich erstellen Sie die <span style={{fontWeight: "bold"}}>Nachricht, die auf alle Ihre Karten kommt.</span>
              Die persönliche Anrede und Gruß für jeden Empfänger folgt im nächsten Schritt. 
              Zusätzlich können Sie entscheiden, ob Sie die Schritt-für-Schritt Anleitung auf der Karte anzeigen möchten.
            </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
        <div style={{height:"100%"}}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <RecentActorsIcon />
              </CardIcon>
              <div className={classes.cardIconTitle}>Adressen ihrer</div>
              <h4 className={classes.cardIconTitle}>Kontakte</h4>
            </CardHeader>
            <CardBody>
            <p className={classes.cardDescription}>Geben Sie an, an wen die Karten geschickt werden sollen. 
              Bei vielen Empfängern steht Ihnen ein <span style={{fontWeight: "bold"}}>Excel zum Download</span> zur Verfügung. 
              Zusätzlich haben Sie hier in drei Feldern die Möglichkeit, 
              Ihre Anrede, Ihren Gruß und Ihren Namen für jeden Empfänger zu personalisieren. </p>
              {/*<p>Beispiel:</p>
              <div>Lieber Heinz [Anrede] </div>
              <div>Deine [Grußform]</div>
              <div>Vanessa [Ihr Name]</div>*/}
            </CardBody>
          </Card>
          </div>
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
        <div style={{height:"100%"}}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <PaymentIcon />
              </CardIcon>
              <div className={classes.cardIconTitle}>Wunschliefertermin und</div>
              <h4 className={classes.cardIconTitle}>Bezahlung</h4>
            </CardHeader>
            <CardBody>
            <p className={classes.cardDescription}>Geben Sie einen <span style={{fontWeight: "bold"}}>Wunschliefertermin</span> für Ihre Karten an.
               Wir garantieren die Lieferung innerhalb einer Arbeitswoche. 
               Anschliessend bezahlen Sie bequem mit Kreditkarte</p>
            </CardBody>
          </Card>
          </div>
        </GridItem>
      </GridContainer> 
      <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Vorderseite</h2>
            </GridItem>
          </GridContainer>
        <GridContainer>
        <GridItem xs={12} sm={4} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <VideoIcon/>
              </CardIcon>
              <br/>
              <h4 className={classes.cardIconTitle}>Video Auswahl</h4>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Player  
                    src={this.state.videoFile}
                    fluid={false}
                    height={250}
                    poster={this.state.videoPoster}/>
              </FormGroup>
              <FormGroup>
                <ReactFormControl onChange={this.handleVideoFileChange} type="file" />
              </FormGroup>
         </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <ImageIcon />
              </CardIcon>
              <br/>
              <h4 className={classes.cardIconTitle}>Bild Auswahl</h4>
            </CardHeader>
            <CardBody>
              <FormGroup>
              <Image className={"Image"} src={this.state.imageFile}/>
                <Rnd default={{x: 0, y: 0, width: 50, height: 50}} 
                    bounds={".Image"} 
                    onDragStop={(e, d) => { 
                      if (!this.state.imageChanged){
                        this.setState({ 
                                      qrposx: d.x, 
                                      qrposy: d.y ,
                                      qrposxpercentage: 100*(d.x / 422),
                                      qrposypercentage: 100*(d.y / 200)
                                                })}
                      else{
                      console.log("Image change");
                        this.setState({
                          qrposx: d.x,
                          qrposy: d.y,
                          qrposxpercentage: 100*(d.x / (250 / this.state.imageHeight * this.state.imageWidth -50)), // Relative width of image
                          qrposypercentage: 100*(d.y / 200)
                        })
                      }
                     }}>
                    <img  src={qrcode} alt="QR Code Position"  title="QR Code Position"  className={classes.dragme}/>
                </Rnd>
              </FormGroup>      
              <FormGroup>
                <ReactFormControl onChange={this.handleImageFileChange} type="file" className={classes.selectButton}/>
              </FormGroup> 
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>  
      <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Rückseite</h2>
            </GridItem>
          </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <GiftCardIcon/>
              </CardIcon>
              <br/>
              <h4 className={classes.cardIconTitle}>Karte gestalten</h4>
              </CardHeader>
              <CardBody>
                <br />
                <br />
                <GridContainer>
                  <GridItem xs={8}>
                    <div className={classes.anleitungThumbnail}>
                      <img src={postkarte} alt="" className={classes.postcard}/>
                      <img src={this.state.anleitung} alt="" className={classes.anleitung}/>
                      <div style={{fontSize: this.state.fontSizeHeading }} className={classes.anleitungCaption}>
                          <p>{this.state.empfaengeranrede}</p>
                      </div>
                      <div style={{fontSize: this.state.fontSize, whiteSpace: "pre-line"}} className={classes.anleitungBodytext}>
                          <p>{this.state.kartenText}</p>
                      </div>
                      <div style={{fontSize: this.state.fontSizeGreeting, top: this.state.greetingTop }} className={classes.anleitungEndtext}>
                          <p>{this.state.empfaengergruss1}</p>
                          <p>{this.state.empfaengergruss2}</p>
                      </div>
                      <div className={classes.anleitungAdresse}>
                          <p>{this.state.empfaengerName}</p>
                          <p>{this.state.empfaengerStrasse}</p>
                          <p>{this.state.empfaengerPlzStadt}</p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={3}>
                  <div className={classes.block}>
                  <p className={classes.cardDescription} style={{fontWeight:"bold"}}>Anleitung aktivieren</p>
                  <br/>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.checkedAnleitung}
                            onChange={this.handleAnleitungsChoice("checkedAnleitung")}
                            value="checkedAnleitung"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                      />
                    </div>
                    <div className={classes.block}>
                    <p className={classes.cardDescription} style={{fontWeight:"bold"}}>Schriftgrösse Text</p>
                    <br/>
                    <FormControl className={classes.formControl}>
                      <Select
                        value={this.state.fontSize}
                        onChange={this.handleChange("fontSize")}
                        inputProps={{
                          name: 'Schriftgrösse ',
                          id: 'fontsize-simple',
                        }}>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                      </Select>
                       </FormControl>
                    </div>
                  </GridItem>
                </GridContainer> 
                <br/>  
                <br/>  
                <GridContainer>
                <GridItem xs={12}>
                <p className={classes.cardDescription} style={{fontWeight:"bold"}}>Text ändern</p>
                <br />
                  <TextField
                  type = "text"
                  fullWidth
                  placeholder="Der Weihnachtstisch ist öd und leer, die Kinder schauen blöd daher. Da lässt der Vater einen krachen, die Kinder fangen an zu lachen. So kann man auch mit kleinen Sachen, den Kindern eine Freude machen."
                  multiline={true}
                  rows = "10"
                  rowsMax= "11"
                  onChange={this.handleTextChange}
                  value={this.kartenText}>
                  </TextField>
                 </GridItem>
                </GridContainer>
                </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    
         <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <GroupAddIcon/>
                </CardIcon>
                <br/>
                <h4 className={classes.cardIconTitle}>Kontakte auswählen</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.cardDescription}>Wählen Sie eine der unten stehenden Varianten aus und tragen Sie ihre Kontaktadressen nach folgendem Beispiel ein.</div>
                  <br/>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Name Empfänger:</span> Der Name des Empfängers der Grusskarte. Z.B. Kundenname, Name eines Freundes oder Familienmitglieds.</div>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Strasse:</span> Die Strasse des Empfängers.</div>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Postleitzahl:</span> Die Postleitzahl des Empfängers.</div>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Stadt:</span> Der Stadtname des Empfängers.</div>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Andrede:</span> Die Andrede auf der Karte, individuell auf den Empfänger zugeschnitten. Bsp. oben auf der Karte "Liebe[r] [Kundenname],"</div>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Gruss:</span> Die Grussformel auf der Karte. Bsp. oben auf der Karte "Eine besinnliche Weihnachtszeit wünscht Ihnen ihr,".</div>
                  <div className={classes.cardExplanation}><span style={{fontWeight:"bold"}}>Name Versender:</span> Der Name des Versender, meisstens Ihr eigener Name. Oben auf der Karte "ihr CLIPTHIS Team".</div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  </GridItem>
                </GridContainer>
                <GridContainer>      
                  <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.cardDescription} style={{fontWeight:"bold"}}> Variante 1: (für wenige Kontaktadressen)</p>
                  <p className={classes.cardDescription}>Füllen Sie folgende Felder aus um einzelne Kontaktadressen hinzuzufügen.</p>
                    <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleContactFormSubmit}>
                      <TextField
                        required
                        id="contact-name"
                        label="Name Empfänger"
                        className={classes.textField}
                        value={this.state.contactName}
                        onChange={this.handleChange('contactName')}
                        margin="normal"
                      />
                      <TextField
                        required
                        id="contact-strasse"
                        label="Strasse"
                        className={classes.textField}
                        value={this.state.contactStrasse}
                        onChange={this.handleChange('contactStrasse')}
                        margin="normal"
                      />
                      <TextField
                        required
                        id="standard-required"
                        label="Postleitzahl"
                        className={classes.textField}
                        value={this.state.contactPostleitzahl}
                        onChange={this.handleChange('contactPostleitzahl')}
                        margin="normal"
                      />
                      <TextField
                        required
                        id="standard-stadt"
                        label="Stadt"
                        className={classes.textField}
                        value={this.state.contactStadt}
                        onChange={this.handleChange('contactStadt')}
                        margin="normal"
                      />
                      <TextField
                        required
                        id="standard-anrede"
                        label="Anrede"
                        className={classes.textField}
                        value={this.state.contactAndrede}
                        onChange={this.handleChange('contactAndrede')}
                        margin="normal"
                      />
                      <TextField
                        required
                        id="standard-anrede"
                        label="Gruss"
                        className={classes.textField}
                        value={this.state.contactGruss}
                        onChange={this.handleChange('contactGruss')}
                        margin="normal"
                      />
                      <TextField
                        required
                        id="standard-anrede"
                        label="Ihr Name"
                        className={classes.textField}
                        value={this.state.contactGrussname}
                        onChange={this.handleChange('contactGrussname')}
                        margin="normal"
                      />
                      <Button 
                        round 
                        color="rose" 
                        onClick={() => this.handleContactFormSubmit}
                        disabled={!this.validateContactForm()}>
                        Eintrag hinzufügen</Button>
                    </form>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.cardDescription} style={{fontWeight:"bold"}}>Variante 2: (für viele Kontaktadressen)</p>
                  <p className={classes.cardDescription}>Laden Sie sich das Excel Template herunter, befüllen Sie es mit ihren Kontaktadressen und laden Sie es wieder hoch. Achten Sie darauf die erste Zeile nicht zu verändern.</p>
                  <p style={{fontWeight:"bold"}}>Name | Strasse | Postleitzahl | Stadt | Anrede | Gruss | Ihr Name </p>
                    <CSVLink 
                      uFEFF={false}
                      data={csvData}
                      separator={";"}
                      filename={"kontakte-template-clip-this.csv"}>
                      <Button 
                        round 
                        color="rose">
                        Template Download</Button>
                    </CSVLink>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <CSVReader
                      cssClass="csv-input"
                      onFileLoaded={this.handleCSV}/>
                  </GridItem>
                  </GridContainer>
                  <p style={{ height: "50px", overflow: "auto" }} ></p>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.cardDescription} style={{fontWeight:"bold"}}>Ausgewählte Kontakte</p>
                    </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Table
                      tableData={this.state.tableData} tableHead={["Name","Strasse","Postleitzahl","Stadt","Anrede", "Gruss", "Ihr Name"]}/>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Generelle Informationen und Abwicklung</h2>
            </GridItem>
          </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>  
                <Card>
                  <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                      <CalendarIcon/>
                    </CardIcon>
                    <br/>
                    <h4 className={classes.cardIconTitle}>Wunsch Lieferungstermin</h4>
                  </CardHeader>
                  <CardBody>
                  <div>Der Liefertermin kann vom Wunschtermin abweichen.</div>
                    <br />
                    <FormControl fullWidth>
                      <DatePicker
                        onChange={this.handleChange("date")}
                      />
                    </FormControl>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}> 
                <Card>
                  <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                      <EmailIcon/>
                    </CardIcon>
                    <br/>
                    <h4 className={classes.cardIconTitle}>Email</h4>
                  </CardHeader>
                  <CardBody> 
                    <div> Email Adresse für Bestätigungsemail und ihre Druckdatei.</div>
                    <TextField
                      required
                      id="email"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={this.handleChange('email')}
                      margin="normal"
                            />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}> 
                <Card>
                  <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                      <FaceIcon/>
                    </CardIcon>
                    <br/>
                    <h4 className={classes.cardIconTitle}>Name</h4>
                  </CardHeader>
                  <CardBody> 
                    <div>Name für die Bestellbestätigung.</div>
                    <TextField
                      required
                      id="name"
                      className={classes.textField}
                      value={this.state.nameCustomer}
                      onChange={this.handleChange('nameCustomer')}
                      margin="normal"
                            />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <PaymentIcon/>
                </CardIcon>
                <br/>
                <h4 className={classes.cardIconTitle}>Checkout</h4>
              </CardHeader>
              <CardBody> 
                <GridContainer>
                  <GridItem xs={12} sm={4} md={3}>
                    <h4 className={classes.cardIconTitle}>Kartenanzahl: {this.state.amountCards}</h4>
                    <h4 className={classes.cardIconTitle}>Preis / Karte: {this.state.priceSingle} CHF</h4>
                    <h4 className={classes.cardIconTitle}>Preis: {this.state.priceTotal} CHF</h4>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                      <StripeProvider apiKey="pk_test_UMGfFUYWBhT4jHDIN1xsNjmk">
                        <Elements>
                          <PaymentForm
                            loading={this.state.isLoading}
                            onSubmit={this.handlePageSubmit}
                          />
                        </Elements>
                      </StripeProvider>
                  </GridItem>
            </GridContainer> 
          </CardBody>
        </Card>
      </div>
      </div>
    );
  }
}


ExtendedForms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(extendedFormsStyle)(ExtendedForms);
