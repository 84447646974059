// ##############################
// // // RegularForms view styles
// #############################

import { cardTitle,
         container,
         largeScreenSideBar,
         mediumScreenSideBar } from "./material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "../customCheckboxRadioSwitch.jsx";

const regularFormsStyle = theme => ({
  container: {
    ...container,
    [theme.breakpoints.up("1224")]: {
      marginRight: `calc((100vw - ${mediumScreenSideBar}px)/2)`, // align content center on remaining of page
      width: "970px"
    },
    [theme.breakpoints.up("1390")]: {
      marginRight: `calc((100vw - ${largeScreenSideBar}px)/2)`, // align content center on remaining of page
      width: "1170px"
    },
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF"
  },
  titleMobile: {
    ...cardTitle,
    textAlign: "center"
  },
  loaderButtonMobile: {
    marginTop: "10px"
  },
  textCenter: {
    textAlign: "center"
  },
  listItemContainer: {
    //position: "flex",
    // display: "webkit-box",
    // display: "ms-flexbox",
    //flexDirection: "column",
    backgroundColor: "#fff",
    padding: "5px 7px"
  },
  content: {
    paddingTop: "18vh",
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4"
  },
  contentMobile: {
    paddingTop: "5vh",
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4"
  },
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  labelHorizontalMobile: {
    ...customCheckboxRadioSwitch.labelHorizontal,
    paddingTop: "20px",
    color: "#000"
  },
  formControlMobile: {
    margin: "0",
    padding: "0",
    border: "0.5px solid #ccc",
    borderRadius: "5px",
    paddingLeft: "7px",
    paddingRight: "7px",
    "&:hover": {
      boxShadow: "0 0 5px #08f",
    }
  },
  formControlInputMobile: {
    "&:before,&:after": {
      border: "0px"
    }
  }
});

export default regularFormsStyle;
