import React from "react";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {calculatePrices} from "../../../libs/prices"
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

class Versand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.content.product,
      checkedAdressen: this.props.content.checkedAdressen,
      numberOfCards: this.props.content.numberOfCards,
      price: 0,

      checkedPrintAtHome: false,
      checkedSendAllHome: false,
      checkedPrintAll: false
    };
    this.isValidated = this.isValidated.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
    console.log("component did mount")

    if(this.state.product ==="PrintAtHome"){
        this.setState({
            checkedPrintAtHome: true
        })
    };
    if(this.state.product ==="SendHome"){
        this.setState({
            checkedSendAllHome: true
        })
    };
    if(this.state.product ==="PrintAndSend"){
        this.setState({
            checkedPrintAll: true
        })
    };

    };

  isValidated(){
    if(this.state.checkedPrintAll === false && this.state.checkedSendAllHome === false && this.state.checkedPrintAtHome === false){
      this.props.setDisabled(true);
    }
  }

  // General function to handle change of certain fields
  handlePrintAtHome =  event => {
    this.props.setDisabled(false);
    var value = event.target.checked;

    if(value === true){
        this.setState({
          checkedPrintAtHome:true,
          checkedPrintAll: false,
          checkedSendAllHome: false,
          product: "PrintAtHome",
          price: calculatePrices("PrintAtHome", this.state.numberOfCards)
        });
        console.log("Preis ", this.state.price)
        this.props.changeProduct("PrintAtHome");
        this.props.handleAmountChange(this.state.numberOfCards, this.state.price);
    }else{
      this.setState({
        checkedPrintAtHome: false
      })
      if(this.state.checkedPrintAll===false && this.state.checkedSendAllHome === false){
        this.props.setDisabled(true)
      }
    }
  };

  // General function to handle change of certain fields
  handleSendHome =  event => {
    this.props.setDisabled(false);
    var value = event.target.checked;
    
    if(value ===true){
        this.setState({
          checkedPrintAtHome: false,
          checkedSendAllHome: true,
          checkedPrintAll: false,
          product: "SendHome",
          price: calculatePrices("SendHome", this.state.numberOfCards)
        });
        console.log("Preis ", this.state.price)
        this.props.changeProduct("SendHome");
        this.props.handleAmountChange(this.state.numberOfCards, this.state.price);
    }
    else{
      this.setState({
        checkedSendAllHome: false
        })
      if(this.state.checkedPrintAll===false && this.state.checkedPrintAtHome === false){
        this.props.setDisabled(true)
        }
      }
  };

  handlePrintAll =  event => {
    this.props.setDisabled(false);
    var value = event.target.checked;
    if(value === true){
        this.setState({
            checkedSendAllHome: false,
            checkedPrintAtHome: false,
            checkedPrintAll: true,
            product: "PrintAndSend",
            price: calculatePrices("PrintAndSend", this.state.numberOfCards)
        });
        console.log("Preis ", this.state.price)
        this.props.changeProduct("PrintAndSend");
        this.props.handleAmountChange(this.state.numberOfCards, this.state.price);
    }else{
      this.setState({
        checkedPrintAll: false
      })
      if(this.state.checkedPrintAtHome===false && this.state.checkedSendAllHome === false){
        this.props.setDisabled(true)
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <GridContainer justify="center">
          <GridItem xs={10} sm={10}>
            <h4 className={classes.infoText}>Wie möchten Sie die Karten versenden?</h4>
          </GridItem>
          <GridItem xs={10} sm={10}>
          <FormGroup row>
            <FormControlLabel
              classes={{label: classes.checkboxTextMobile}}
              control={
                <Checkbox
                  checked={this.state.checkedPrintAtHome}
                  onChange={this.handlePrintAtHome}
                  value="checkedPrintAtHome"
                  color="primary"
                />
              }
              
              label="Sie möchten alle Karten selber zu Hause drucken. Wir senden Ihnen eine Druckdatei per Email."
            />
            <FormControlLabel
              classes={{label: classes.checkboxTextMobile}} 
              control={
                <Checkbox
                  checked={this.state.checkedSendAllHome}
                  onChange={this.handleSendHome}
                  value="checkedSendAllHome"
                  color="primary"
                />
              }
              label="Sie möchten die Karten ausgedruckt nach Hause geliefert bekommen."
            />
            <FormControlLabel
              classes={{label: classes.checkboxTextMobile}}
              control={
                <Checkbox
                  checked={this.state.checkedPrintAll}
                  onChange={this.handlePrintAll}
                  value="checkedPrintAll"
                  color="primary"
                />
              }
              label="Sie möchten, dass die gedruckten Karten direkt an die Adressen, die sie im vorherigen Schritt eingegeben haben, gesendet werden."
            />
            </FormGroup>
            </GridItem>
        </GridContainer>
    </MediaQuery>
    <MediaQuery maxWidth={1224}>
    <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <h4 className={classes.infoTextMobile}>Wie möchten Sie die Karten versenden?</h4>
          </GridItem>
          <GridItem xs={12} sm={11}>
          <FormGroup 
            row
            >
            <FormControlLabel
              classes={{label: classes.checkboxTextMobile}}
              control={
                <Checkbox
                  checked={this.state.checkedPrintAtHome}
                  onChange={this.handlePrintAtHome}
                  value="checkedPrintAtHome"
                  color="primary"
                />
              }
              label="Sie möchten alle Karten selber zu Hause drucken. Wir senden Ihnen eine Druckdatei per Email."
            />
            <FormControlLabel
              classes={{label: classes.checkboxTextMobile}}
              control={
                <Checkbox
                  checked={this.state.checkedSendAllHome}
                  onChange={this.handleSendHome}
                  value="checkedSendAllHome"
                  color="primary"
                />
              }
              label="Sie möchten die Karten ausgedruckt nach Hause geliefert bekommen."
            />
            <FormControlLabel
              classes={{label: classes.checkboxTextMobile}}
              control={
                <Checkbox
                  checked={this.state.checkedPrintAll}
                  onChange={this.handlePrintAll}
                  value="checkedPrintAll"
                  color="primary"
                  
                />
              }
              label="Sie möchten, dass die gedruckten Karten direkt an die Adressen, die sie im vorherigen Schritt eingegeben haben, gesendet werden."
            />
            </FormGroup>
            </GridItem>
        </GridContainer>

    </MediaQuery>
    </div>
    );
  }
}

export default withStyles(productCyclePageStyle)(Versand);
