// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle
} from "./material-dashboard-pro-react.jsx";

const loginPageStyle = {
  container,
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF"
  },
  titleMobile: {
    ...cardTitle,
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  content: {
    paddingTop: "18vh",
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF"
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputField:{
    color:"#000"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  socialLineMobile: {
    textAlign: "center"
  },
  subButton:{
    fontSize: "10px",
    color: "#000"
  },
  registerText: {
    cursor: "pointer",
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "300",
    marginTop: "30px",
    marginBottom: "25px",
    marginLeft: "15px",
    minHeight: "32px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
    color: "#777",
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1"
    }
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "left"
  },
  summaryText:{
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "left"
  },
  spinner:{
    display: "block",
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "5",
    margin: "0 auto"
  }
};

export default loginPageStyle;
