import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import MediaQuery from 'react-responsive';

import {CardElement, injectStripe} from 'react-stripe-elements';
import { FormGroup, FormControl} from "react-bootstrap";
import Button from "../CustomButtons/Button.jsx"

import productCyclePageStyle from "../../assets/views/productCycleStyle.jsx";

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      isProcessing: false,
      isCardComplete: false,
    };
  }

  validateForm() {
    return (
      this.state.name !== "" &&
      this.state.isCardComplete
    );
  };

  handleFieldChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCardFieldChange = event => {
    this.setState({
      isCardComplete: event.complete
    });
  };

  handleSubmitClick = async event => {
    event.preventDefault();
    const { name } = this.state;
    this.setState({ isProcessing: true });

    const { token, error } = await this.props.stripe.createToken({ name });
    this.setState({ isProcessing: false });
    this.props.handleSubmit({ token, error });
  };

  render() {
    const { classes } = this.props;
    //const loading = this.state.isProcessing || this.props.loading;
    return (
      <form className="BillingForm" onSubmit={this.handleSubmitClick}>
        <FormGroup bsSize="large" controlId="name">
        <h4 className={classes.infoTextSmall}>Name Kreditkarteninhaber</h4>
          <FormControl
            type="text"
            value={this.state.name}
            onChange={this.handleFieldChange}
            placeholder=""
          />
        </FormGroup>
        <h4 className={classes.infoTextSmall}>Kreditkarteninformationen</h4>
        <CardElement
          className="card-field"
          onChange={this.handleCardFieldChange}
          hidePostalCode={true}
          style={{
            base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif' }
          }}
        />
        <br/>
        <br/>
        <br/>
        <Button 
          round 
          color="primary" 
          onClick={this.handleSubmitClick}
          disabled={!this.validateForm()}>
          Bezahlen</Button>
      </form>
    );
  }
}

PaymentForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(productCyclePageStyle)(injectStripe(PaymentForm));