import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import React from "react";


import listItemsPageStyle from "../../../assets/views/feedbackforpatientPageStyle.jsx";


class MessageList extends React.Component {

    constructor(props) {
      super(props);
  
    }
  
    render() {
      const { classes } = this.props;
      return (
        <ul className={classes.messageList}>                 
          {this.props.messages.map(message => {
            return (
              <li key={message.id}>
                <div className={classes.messageChild1}>{message.senderId}</div>
                <div className={classes.messageChild2}>{message.text}</div>
                <div> <br/></div>
              </li>
            )
          })}
        </ul>
      )
    }
  };

  MessageList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(MessageList);
