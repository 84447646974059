import React, { Component } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/PlayCircleFilled';
import Dashboard from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import ExerciseIcon from "@material-ui/icons/AccessibilityNew";
import AddAlertIcon from "@material-ui/icons/AddAlert";

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

// AWS App Sync
import { graphql, compose } from 'react-apollo'
import DeleteItem from "../../../mutations/DeleteItem"
import ListItemsPerUser from '../../../queries/ListItemsPerUser'

import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import * as queries from "../../../graphql/queries";
import { Auth } from "aws-amplify";

// Style
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
},
});


class ListPatients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",
      visibility: "private",

      isLoading: true,
      items: [],
      data: [],
      isModalOpen: false,
      clipThisItems: null,
    };
  }

  async componentDidMount() {
  
    window.scrollTo(0, 0)
    this.setState({ isLoading: false });

    const patientenData = [{
        clipThisId: 1,
        name: 'Test Patient 1',
        behandlung: "Nackenschmerzen",
        woche: "Herr ... hat seit eingiger Zeit Nackenschmerzen. Die Behandlung läuft gut an.",
        overview: "-großer Fortschritt WaW",
        exercises: [],
        analytics: {}
        },
        {
        clipThisId: 2,
        name: 'Test Patient 2',
        behandlung: "Handgelenkschmerzen",
        woche: "Frau... hat sich beim Sport am Handgelenk verletzt.",
        overview: "-Rückschritt da zu viel bewegt",
        exercises: [],
        analytics: {}
        },]


    const data = patientenData.map((prop, key) => {
      return {
        id: key,
        clipThisId: prop.clipThisId,
        behandlung: prop.behandlung,
        name: prop.name,
        week: prop.woche,
        overview: prop.overview,
        exercises: prop.exercises,
        analytics: prop.analytics,
        actions: (
          // custom button actions
          <div className="actions-right">
            <Button justIcon round simple onClick={() => {
              // Go to edit item page and feed obj data
              this.props.history.push({
                pathname: "/physio/feedbackforpatient",
                state:{
                  displayName: prop.displayName,
                  analytics: prop.analytics}
                })
              }}
              color="danger"
              className="like"
              >
            <AddAlertIcon />
            </Button>
            {" "}
            <Button justIcon round simple onClick={() => {
              // Go to edit item page and feed obj data
              this.props.history.push({
                pathname: "/physio/listexercises",
                state:{
                  displayName: prop.displayName,
                  analytics: prop.analytics}
                })
              }}
              color="info"
              className="like"
              >
              <ExerciseIcon />
            </Button>{" "}
            {/* use this button to add a dashboard kind of action */}
            <Button justIcon round simple onClick={() => {
              // Go to edit item page and feed obj data
              this.props.history.push({
                pathname: "/physio/statsofpatient",
                state:{
                  displayName: prop.displayName,
                  analytics: prop.analytics}
                })
              }}
              color="info"
              className="like"
              >
              <Dashboard />
            </Button>{" "}
            {/* use this button to add a edit kind of action */}
            <Button justIcon round simple onClick={() => {
                // Go to edit item page and feed obj data
                this.props.history.push({
                  pathname: "/edittest",
                  state:{
                    clipThisId: prop.clipThisId,
                    maxViews: prop.maxViews,
                    views: prop.views,
                    analytics: prop.analytics,
                    actionData: prop.actionData,
                    actionType: prop.link,
                    actionName: prop.actionName,
                    displayName: prop.displayName,
                    active: prop.active,
                    premium: prop.premium,
                    email: prop.email,
                    qrposx: prop.qrposx,
                    qrposy: prop.qrposy,
                    createdAt: prop.createdAt,
                    description: prop.description
                    }
                  })
                }}
              color="warning"
              className="edit"
              >
              <Edit />
            </Button>{" "}
            {/* use this button to remove the data row */}
          </div>
        )
      };
    })

    this.setState({ data });
  }

  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  deleteItem(clipThisId) {
    this.props.onDelete({clipThisId})

    const bucket = this.state.bucket;
    const region = this.state.region;
    const level = this.state.visibility;
    const imageKey = clipThisId + ".jpg";
    const videoKey = clipThisId + ".mp4";
    try{
      Storage.vault.remove(imageKey, { bucket, region, level });
    }catch{
    };
    try{
      Storage.vault.remove(videoKey, { bucket, region, level });
    }catch{};
  };

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
       <div className={classes.container}>
          <GridContainer className={classes.listItemContainer}>
            <div style={{textAlign: "right"}}>
              {/*<Button href="/pages/listcampaigns" color="primary">  
                <span style={{fontSize: "15px"}}> My Campaigns </span>
              </Button>
              <Button href="/pages/listitems" color="primary">  
                <span style={{fontSize: "15px"}}> All Items </span>
              </Button>*/}
              <Button href="/physio/newpatient" color="primary">  
                <span style={{fontSize: "15px"}}> Patient anlegen </span>
              </Button>
              {/* <ItemModal show={this.state.isModalOpen}
                onClose={this.toggleModal}
                title={"Add New Item"}
                body={<NewItem />}
              /> */}
            </div>
          <div style={{textAlign: "left"}}>
              <h2>Patienten</h2>
              {/* <ItemModal show={this.state.isModalOpen}
                onClose={this.toggleModal}
                title={"Add New Item"}
                body={<NewItem />}
              /> */}
            </div>

                <ReactTable
                  data={this.state.data}
                  noDataText={"No items found"}
                  columns={[
                    {
                      Header: "Vorname/Name",
                      accessor: "name"
                    },
                    {
                      Header: "Behandlung",
                      accessor: "behandlung"
                    },
                    {
                      Header: "Kurzbeschreibung",
                      accessor: "week"
                    },
                    {
                      Header: "Notizen",
                      accessor: "overview"
                    },
                    {
                      Header: "Aktion",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={20}
                  showPaginationBottom={false}
                  className={classes.table}
                />
          </GridContainer>
          </div>
          </div>
      );
  }
}

const SimpleModalWrapped = withStyles(styles)(ListPatients);

ListPatients.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(
  compose(
    graphql(ListItemsPerUser, {
      options: (props) => ({
        fetchPolicy: 'cache-and-network',
        variables: { userId: props.userName }
      }),
      props: props => ({
        clipThisItems: props.data.listClipThisItemsPerUser? props.data.listClipThisItemsPerUser.items : [],
        })
    }),
    graphql(DeleteItem, {
      props: props => ({
        onDelete: clipThisItem => props.mutate({
          variables: clipThisItem,
          optimisticResponse: {
            __typename: 'Mutation',
            deleteItem: { ...clipThisItem,  __typename: 'clipThisItem' }
          },
        })
      })
    })
  )(ListPatients)
)
