import React from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import CardMobile from "../../../components/Card/CardMobile.jsx"

// AWS immport
import { API } from "aws-amplify";
import { s3Upload } from "../../../libs/awsLib";
import { randomString } from "../../../libs/randomString";

// Import steps
import Step2ImageVideo from "../FormSteps/Step2ImageVideo.jsx";
import Checkout from "../FormSteps/Checkout.jsx";
import Step3CreateCard from "../FormSteps/Step3CreateCard.jsx";
import Step4Contacts from "../FormSteps/Step4Contacts.jsx"
import Overview from "../FormSteps/Overview.jsx";
import Versand from "../FormSteps/Versand.jsx"

import firstImage from '../../../assets/image/card/christmascard.jpg'
import firstVideo from '../../../assets/image/card/christmascard.mp4'

// Mobile
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

function getSteps() {
  return ['Bild und Video', "Kartentext ausfüllen", "Adressen eintragen", "Versand", "Übersicht", "Checkout"];
}
function getMobileSteps() {
  return ['Bild/Video' , 'Text', 'Adressen' , 'Versand' , 'Übersicht', 'Checkout'];
}

class ProductCycle extends React.Component {

  constructor(props) {
    super(props);

    this.fileImage = firstImage;
    this.fileVideo = firstVideo;

    this.state = {
      isAuthenticated: this.props.isAuthenticated,
      disbaled: false,
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),

      videoFile: firstVideo,
      imageFile: firstImage,
      qrposxpercentage: 0,
      qrposypercentage: 0,
      imageChanged: false,
      videoChanged: false,

      fontSize: 12,
      checkedAnleitung: true,
      checkedText: true,
      checkedAdressen: true,
      kartenText: "empty",

      tableData: [],
      numberOfCards: 0,
      priceTotal: 0,
      priceSingle: 0,
      currency: "CHF",

      name: "empty",
      email: "empty",
      date: "empty",
      product: "PrintAtHome",
      link: "empty",

      zipcode: "empty",
      city: "empty",
      street: "empty",
    };

    this.handleSkip = this.handleSkip.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeVideo = this.changeVideo.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.setqrPosStates = this.setqrPosStates.bind(this);
    this.funcImageChanged = this.funcImageChanged.bind(this);
    this.funcVideoChanged = this.funcVideoChanged.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleTableDataChange = this.handleTableDataChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.changeProduct = this.changeProduct.bind(this);
    this.nextPath = this.nextPath.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePaypalSubmit = this.handlePaypalSubmit.bind(this);
    this.setDisabled = this.setDisabled.bind(this);
  };

  setDisabled(disabled){
    this.setState({
      disabled: disabled
    })
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.location.state !== undefined){
      
      if (this.props.location.state.detail !== undefined){
        this.setState({activeStep: this.props.location.state.detail})}

      if(this.props.location.state.content !== undefined){
        this.handleLoginData(this.props.location.state.content)}
    };

    if(window.location.hostname.includes(".ch")){
      this.setState({
        currency: 'CHF'
      })
    }else{
      this.setState({
        currency: "EUR"
      })
    };
  };

  handleLoginData(content){

    this.setState({
      name: content.name,
      email: content.email,
      adress: content.adress,
      plz: content.plz,
      city: content.city,
      numberOfCards: content.numberOfCards,
      priceTotal: content.priceTotal,

      videoFile: content.videoFile,
      imageFile: content.imageFile,
      qrposxpercentage: content.qrposxpercentage,
      qrposypercentage: content.qrposypercentage,
      imageChanged: content.imageChanged,
      videoChanged: content.videoChanged,

      fontSize: content.fontSize,
      checkedAnleitung: content.checkedAnleitung,
      checkedText: content.checkedText,
      checkedAdressen: content.checkedAdressen,
      kartenText: content.kartenText,

      tableData: content.tableData,
      date: content.date,
      product: content.product,
    })

  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  changeImage(image, url){
    this.fileImage = image;
    this.setState({
      imageFile: url
    })
  };

  changeVideo(video, url){
    this.fileVideo = video
    this.setState({
      videoFile: url
    })
  };

  // Callback function to set qrpositions from child component
  setqrPosStates(qrposx, qrposy) {
    this.setState({
      qrposxpercentage: qrposx,
      qrposypercentage: qrposy
    });
  };

  // Callback functions to indicate that image or video was changed
  funcImageChanged(){
    this.setState({imageChanged: true})
  };

  funcVideoChanged(){
    this.setState({videoChanged: true})
  };

  handleTextChange(fontSize, kartenText, checkedAnleitung, checkedText, checkedAdressen){
    this.setState({
      kartenText: kartenText,
      fontSize: fontSize,
      checkedAnleitung: checkedAnleitung,
      checkedText: checkedText,
      checkedAdressen: checkedAdressen,
    })
  };

  handleTableDataChange(tableData, numberOfCards){
    this.setState({
      tableData: tableData,
      numberOfCards: numberOfCards
    })
  };

  handleAmountChange(numberOfCards, priceTotal){
    this.setState({
      numberOfCards: numberOfCards,
      priceTotal: priceTotal
    })
  };

  changeProduct(product){
    this.setState({
      product: product
    });
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return <Step2ImageVideo
                  setDisabled={this.setDisabled}
                  content={{
                    fileImage: this.state.imageFile,
                    fileVideo: this.state.videoFile,
                  }}
                  changeImage={this.changeImage}
                  changeVideo={this.changeVideo}
                  funcVideoChanged= {this.funcVideoChanged}
                  funcImageChanged= {this.funcImageChanged}
                  setqrPosStates = {this.setqrPosStates}
                  handleChange={this.handleChange}
                />;
      case 1:
        return <Step3CreateCard   
                content={{
                  kartenText: this.state.kartenText,
                  checkedAnleitung: this.state.checkedAnleitung,
                  checkedText: this.state.checkedText,
                  checkedAdressen: this.state.checkedAdressen,
                  fontSize: this.state.fontSize
                }}
                handleSkip={this.handleSkip}
                handleTextChange={this.handleTextChange}
                handleChange={this.handleChange}
                />;
      case 2:
      return <Step4Contacts
              handleTableDataChange={this.handleTableDataChange}
              handleSkip={this.handleSkip}
              />;
      case 3:
      return <Versand
              setDisabled={this.setDisabled}
              content={{
                product: this.state.product,
                checkedAdressen: this.state.checkedAdressen,
                numberOfCards: this.state.numberOfCards,
               }}
               changeProduct={this.changeProduct}
               handleAmountChange={this.handleAmountChange}
               />;
               
      case 4:
      return <Overview 
              content={{imageFile: this.state.imageFile, 
                        videoFile: this.state.videoFile,
                        kartenText: this.state.kartenText,
                        checkedAnleitung: this.state.checkedAnleitung,
                        tableData: this.state.tableData,
                        numberOfCards: this.state.numberOfCards,
                        priceTotal: this.state.priceTotal,
                        product: this.state.product,
                        currency: this.state.currency
                        }}
              />;
      case 5:
      return <Checkout
              isAuthenticated={this.state.isAuthenticated}
              content={{
                name: this.state.name,
                email: this.state.email,
                adress: this.state.adress,
                zipcode: this.state.zipcode,
                street: this.state.street,
                city: this.state.city,
                numberOfCards: this.state.numberOfCards,
                priceTotal: this.state.priceTotal,

                videoFile: this.state.videoFile,
                imageFile: this.state.imageFile,
                qrposxpercentage: this.state.qrposxpercentage,
                qrposypercentage: this.state.qrposypercentage,
                imageChanged: this.state.imageChanged,
                videoChanged: this.state.videoChanged,

                fontSize: this.state.fontSize,
                checkedAnleitung: this.state.checkedAnleitung,
                checkedText: this.state.checkedText,
                checkedAdressen: this.state.checkedAdressen,
                kartenText: this.state.kartenText,

                tableData: this.state.tableData,
                date: this.state.date,
                product: this.state.product,
                currency: this.state.currency
              }}
              handleLoginData={this.handleLoginData}
              handleAmountChange={this.handleAmountChange}
              handleChange={this.handleChange}
              handlePaypalSubmit = {this.handlePaypalSubmit}
              handleSubmit={this.handleSubmit}
              />;          
      default:
        return 'Unknown step';
    }
  };

  totalSteps = () => {
    return getSteps().length;
  };

  isStepOptional = step => {
    return (step === 1 || step === 2);
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = getSteps();
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStep = step => () => {
    console.log(step)
    // If current step is add adresses, but addresses is not activated, skip
    if (step===2 && !this.state.checkedAdressen){
      this.setState({
        activeStep: step+1,
      });}
    // Else add adresses
    else{
      this.setState({
      activeStep: step,
    });}
  };

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.completed);
    completed.add(this.state.activeStep);
    this.setState({
      completed,
    });

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps() - this.skippedSteps()) {
      this.handleNext();
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
    });
  };

  skippedSteps() {
    return this.state.skipped.size;
  }

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  // API post to create new item
  createItem(item) {    
    return API.post("clpthis", "/clpthis", {
      body: item
    });
  } 

  // API to pay
  billUser(details) {
    return API.post("clpthis", "/billing", {
      body: details
    });
  }

  nextPath(path) {

    this.props.history.push({
      pathname: path
      });
  }

  handleSubmit = async ({ token, error }) => {
    if (error) {
      alert(error);
      return;
    }
    
    // Activate loading spinner
    this.setState({ isLoading: true});

    let videoFileName = ""
    let imageFileName = ""

    if(this.state.link !== "empty"){
      let name = randomString(7);
      videoFileName = "Link_" + name + ".mp4";
      imageFileName = "Link_" + name + ".jpg";
    }
    else{
      let name = randomString(7);
      videoFileName = name + ".mp4";
      imageFileName = name + ".jpg";
    }

    try {
      var attachment = "original";
      if (this.state.videoChanged){
        // Wait until video is uploaded to S3
        attachment = this.state.videoFile
          ? await s3Upload(this.fileVideo, videoFileName, "video/mp4")
          : null;
           
      }
      var imageAttachment = "original";
      if (this.fileImage){
        // Wait until image is uploaded to S3
        imageAttachment = this.fileImage
          ? await s3Upload(this.fileImage, imageFileName, "image/jpg")
          : null;
      }
      
      await this.billUser({
        amount: this.state.priceTotal*100,
        source: token.id,
      });
  
      // Wait until new database entry is created successfully
      await this.createItem({
        attachment: attachment,
        imageAttachment: imageAttachment,
        imageChanged: this.state.imageChanged,
        qrposxpercentage: this.state.qrposxpercentage,
        qrposypercentage: this.state.qrposypercentage,
        checkedAnleitung: this.state.checkedAnleitung,
        kartenText: this.state.kartenText,
        fontSize: this.state.fontSize,
        tableData: this.state.tableData,
        date: this.state.date,
        wunschLink: this.state.link,
        email: this.state.email,
        product: this.state.product,
        priceSingle: this.state.priceTotal,
        numberCards: this.state.numberOfCards,
        nameCustomer: this.state.name
      });
      
      // Go to Summary
      this.nextPath("/pages/confirmation");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  async handlePaypalSubmit() {
    
    // Activate loading spinner
    this.setState({ isLoading: true});

    let videoFileName = ""
    let imageFileName = ""

    if(this.state.link !== "empty"){
      let name = randomString(7);
      videoFileName = "Link_" + name + ".mp4";
      imageFileName = "Link_" + name + ".jpg";
    }
    else{
      let name = randomString(7);
      videoFileName = name + ".mp4";
      imageFileName = name + ".jpg";
    }

    try {
      var attachment = "original";
      if (this.state.videoChanged){
        // Wait until video is uploaded to S3
        attachment = this.state.videoFile
          ? await s3Upload(this.fileVideo, videoFileName, "video/mp4")
          : null;
           
      }
      var imageAttachment = "original";
      if (this.fileImage){
        // Wait until image is uploaded to S3
        imageAttachment = this.fileImage
          ? await s3Upload(this.fileImage, imageFileName, "image/jpg")
          : null;
      }
  
      // Wait until new database entry is created successfully
      await this.createItem({
        attachment: attachment,
        imageAttachment: imageAttachment,
        imageChanged: this.state.imageChanged,
        qrposxpercentage: this.state.qrposxpercentage,
        qrposypercentage: this.state.qrposypercentage,
        checkedAnleitung: this.state.checkedAnleitung,
        kartenText: this.state.kartenText,
        fontSize: this.state.fontSize,
        tableData: this.state.tableData,
        date: this.state.date,
        wunschLink: this.state.link,
        email: this.state.email,
        product: this.state.product,
        priceSingle: this.state.priceTotal,
        numberCards: this.state.numberOfCards,
        nameCustomer: this.state.name
      });
      
      // Go to Summary
      this.nextPath("/pages/confirmation");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes, theme } = this.props;
    const steps = getSteps();
    //const mobileSteps = getMobileSteps();
    const { activeStep } = this.state;

    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
        <div className={classes.container}>
          <Stepper 
            alternativeLabel 
            nonLinear activeStep={activeStep}
            classes={{root: classes.stepperMobile}}
            >
            {steps.map((label, index) => {
              const props = {};
              const buttonProps = {};
              if (this.isStepOptional(index)) {
                buttonProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (this.isStepSkipped(index)) {
                props.completed = false;
              }

              return (
                <Step key={label} {...props}>
                  <StepButton
                    onClick={this.handleStep(index)}
                    completed={this.isStepComplete(index)}
                    {...buttonProps}
                    classes={{root: classes.stepButton}}
                    >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
            <div>
              {this.allStepsCompleted() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={this.handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Card>
                    <CardBody>
                      {this.getStepContent(activeStep)}
                    </CardBody>

                    <CardFooter>
                    <div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Zurück
                    </Button>
                    { activeStep !== this.totalSteps()-1
                    ?<Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={this.state.disabled}
                    >
                      Weiter
                    </Button>
                    :<div></div>
                    }
                  </div>
                    </CardFooter>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </div>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
          <div className={classes.contentMobile}>
          <MobileStepper
            variant="dots"
            steps={this.totalSteps()}
            position="static"
            activeStep={activeStep}
            className={classes.rootMobileStepper}
            nextButton={
              <Button size="medium" onClick={this.handleNext} disabled={this.state.disabled} style={{fontSize: "15px"}}>
                Weiter
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="medium" onClick={this.handleBack} disabled={this.state.activeStep === 0} style={{fontSize: "15px"}}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Zurück
              </Button>
            }
            />
            <CardMobile>
              <CardBody>
                {this.getStepContent(activeStep)}
              </CardBody>
            </CardMobile>
        </div>
        </MediaQuery>
      </div>
    );
  }
}

ProductCycle.propTypes = {
  classes: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  fileImage: PropTypes.object,
  fileVideo: PropTypes.object,
  changeVideo: PropTypes.func,
  changeImage: PropTypes.func,
  numberOfCards: PropTypes.number,
  handleChange: PropTypes.func,
  setqrPosStates: PropTypes.func,
  handleSkip: PropTypes.func,
  funcImageChanged: PropTypes.func,
  funcVideoChanged: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleTableDataChange: PropTypes.func,
  handleAmountChange: PropTypes.func,
  handleLoginData: PropTypes.func,
  changeProduct: PropTypes.func,
  content: PropTypes.func,
  setDisabled: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePaypalSubmit: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

export default withStyles(productCyclePageStyle, { withTheme: true })(ProductCycle);