import React from "react";
import PropTypes from "prop-types";

// aws immport
import { randomString } from "../../../libs/randomString";
//import s3Upload from "../../libs/awsLib"
import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {Image, FormGroup, FormControl} from "react-bootstrap";
import { Player } from 'video-react';
import {Rnd} from "react-rnd";  // Move image libary
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import Button from "../../../components/CustomButtons/Button.jsx";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";

import qrcode from '../../../assets/image/qrcode.png'
import config from "../../../config";

import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";
import { Card } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import { ClipLoader } from 'react-spinners';

import awsmobile from '../../../aws-exports';
import AWSAppSyncClient from "aws-appsync";
import gql from 'graphql-tag'
import * as mutations from "../../../graphql/mutations"

class NewPatient extends React.Component {
  constructor(props) {
    super(props);

    this.fileVideo = null;
    this.fileImage = null;

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",

      // General paramters
      isLoading: false,           // Showing rotating loading spinner

      email: this.props.email,
      phone: "",
      clipThisId: "",
      userId: this.props.userName,
      name: "",
      comments: "",
      behandlung: "",
      createdAt: Date.now(),
      updatedAt: null
    };

    this.nextPath = this.nextPath.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });
  };

  handleAmountChange = name => event => {
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  validateForm() {
    let valid = true;

    return valid;
  }

  nextPath(path) {
    this.props.history.push({
    pathname: path
    });
  }

  handleSubmit = async ({ token, error }) => {

    // Activate loading spinner
    this.setState({ isLoading: true });
    let clipThisId = ""

    if(this.state.link !== "empty"){
      clipThisId = "Link_" + randomString(7);      
    }
    else{
      clipThisId = randomString(7);
    }

    this.setState({clipThisId: clipThisId});
  
    try { 
      
      //// Add new item to database
      // Get item details
      const { email, maxViews, views, actionData, actionType, actionName, displayName, premium, active, qrposxpercentage, qrposypercentage, description, createdAt } = this.state;
      const qrposx = qrposxpercentage;
      const qrposy = qrposypercentage;
      const analytics = null;

      let now = Date.now().toString();
      const changeList = ["actionData", "actionName", "actionType", "active", "displayName", "file", "videoFile", "description"];
      let updatedAt = {};
      updatedAt[now] = changeList
      updatedAt = JSON.stringify(updatedAt);
      // File Upload
      const { bucket, region } = this.state;
      const visibility = 'private';
      const selectedFile = this.fileImage;
      const selectedVideo = this.fileVideo;
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;

      let file;
      let videoFile;

      if (selectedFile) {
        const { name: fileName, type: mimeType } = selectedFile;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        
        file = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedFile
        };
      }

      if (selectedVideo) {
        const { name: fileName, type: mimeType } = selectedVideo;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        
        videoFile = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedVideo
        };
      };
      
      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey,
        },
        complexObjectsCredentials: () => Auth.currentCredentials(),
      });

      const result = await client.mutate({
        mutation: gql(mutations.createClipThisItem),
        variables: {
          input: {
            clipThisId,
            userId,
            email,
            maxViews,
            views,
            analytics,
            actionData,
            actionType,
            actionName,
            displayName,
            premium,
            active,
            qrposx,
            qrposy,
            file: file,
            videoFile: videoFile,
            description: description,
            createdAt: createdAt,
            updatedAt: updatedAt
          }
        }
      });
            
      // Redirect to homepage
      this.nextPath("/");
      // alert("Item was added successfully!");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
      <div className={classes.spinner}>
        <ClipLoader
          className={classes.spinner}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.isLoading}
        /> </div>
        <div className={classes.container}>
        <Card>
        <CardBody>
        <GridContainer justify="left">
          <GridItem xs={6}><h4 className={classes.infoText}>Name / Vorname</h4></GridItem>
          <GridItem xs ={6}>
            <BasicCustomInput
              labelText={this.state.name}
              id="name"
              formControlProps={{
                fullWidth: true
              }}
              onChange={this.handleChange("name")}
              value={this.state.name}
            />
          </GridItem>
          <GridItem xs={6}><h4 className={classes.infoText}>Email Adresse</h4></GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.email}
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("email")}
          value={this.state.email}
        />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Telefonnummer</h4>
        </GridItem>
        <GridItem xs={6}>
          <BasicCustomInput
            labelText={this.state.phone}
            id="phone"
            formControlProps={{
              fullWidth: true
            }}
            onChange={this.handleChange("link")}
            value={this.state.email}
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Behandlung</h4>
        </GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.behandlung}
          id="behandlung"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("behandlung")}
          value={this.state.behandlung}
        />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Kommentar</h4>
        </GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.kommentar}
          id="kommentar"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("kommentar")}
          value={this.state.kommentar}
        />
        </GridItem>
        <GridItem>
          <Button 
            round 
            color="primary" 
            onClick={this.handleSubmit}
            disabled={!this.validateForm}>
            Erstellen
          </Button>
        </GridItem>
      </GridContainer>

        </CardBody>
        </Card>
      </div>
      </div>
    );
  }
}

NewPatient.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(privateUploadPageStyle)(NewPatient)