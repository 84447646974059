import React from "react";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';

// Extra libaries for this section
import CSVReader from 'react-csv-reader'
import { CSVLink} from "react-csv";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Table from "../../../components/Table/Table.jsx";

import CustomInput from "../../../components/CustomInput/CustomInput.jsx";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

const csvData = [
  ["Name", "Strasse", "Postleitzahl", "Stadt", "Anrede", "Gruss", "Ihr Name"],
  ["Beck", "Europaalee 7", "8004", "Zuerich","Sehr geehrter Herr Beck", "Mit freundlichen Grüssen", "ihr CLIPTHIS Team"],
  ["Radelli", "Raemistrasse 101", "8006", "Zuerich", "Lieber Herr Radelli", "Liebe Grüsse", "Toni"],
  ["Marx", "Minengasse 13b", "6004", "Luzern", "Liebe Familie Marx", "Eine schöne Weihnachtszeit,", "Kilian Maile"]
];

class Step4Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      contactName:"",             // Inputs in contact field
      contactStrasse:"",
      contactPostleitzahl:"",
      contactStadt:"",
      contactAndrede:"",
      contactGruss:"",
      contactGrussname:"",
      tableData: [],
      amountCards: 0,       // Number of cards to calculate price
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  // General function to handle change of certain fields
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Upload of .csv file filled with contact data
  handleCSV = data => {
    const header = ["Name",	"Strasse",  "Postleitzahl", "Stadt",	"Anrede", "Gruss", "Ihr Name"];
    for(var i = header.length; i--;){
      if(header[i] !== data[0][i]){   // Compare the header of the original and the uploaded file, have to match
        alert("Die Kopfzeile der hochgeladenen Excel Datei entspricht nicht der des Templates.");
        return;
      };
    };
    this.setState({
      tableData: data.slice(1,data.length),    // Slice to only add data, not header
      amountCards: data.length - 1,
    });
    this.props.handleTableDataChange(data.slice(1,data.length), data.length-1);
  };

  // Adds a new row to the concat table data
  handleContactFormSubmit = event => {
    event.preventDefault();
    console.log(this.props.kartenText);
    var thisList = this.state.tableData;
    thisList[thisList.length] = [this.state.contactName, 
                                this.state.contactStrasse, 
                                this.state.contactPostleitzahl, 
                                this.state.contactStadt, 
                                this.state.contactAndrede,
                                this.state.contactGruss,
                                this.state.contactGrussname];

    this.setState({
      tableData: thisList,
      amountCards: thisList.length,
      contactName:"",             // Inputs in contact field
      contactStrasse:"",
      contactPostleitzahl:"",
      contactStadt:"",
      contactAndrede:"",
      contactGruss:"",
      contactGrussname:""
    })
    this.props.handleTableDataChange(thisList, thisList.length);
  };

  // Check that all contact fields are filled out
  validateContactForm() {return (
    this.state.contactName.length > 0,
    this.state.contactStrasse.length >0,
    this.state.contactPostleitzahl.length >0, 
    this.state.contactStadt.length >0, 
    this.state.contactAndrede.length >0,
    this.state.contactGruss.length >0,
    this.state.contactGrussname.length >0
    );
  };

  isValidated() {
    if(this.state.tableData !== []){
      return true;
    }
    else{
      alert("Bitte füllen Sie mindestens eine Kontaktadresse aus.")
      return false;
    }
  }

  fillOutAdresses = event =>{
    this.setState({
      visible: true
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <GridContainer justify="center">
      <GridContainer justify="center">
        <GridItem xs={12} sm={11}>
          <h4 className={classes.infoText}>Möchten Sie ihre Karten mit Adressen beschriften?</h4>
          <p className={classes.infoText}>{this.props.kartenText}</p>
        </GridItem>
        <GridItem xs={10} sm={5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.fillOutAdresses}
              className={classes.button}
            >
              Adressen für Karten eingeben
            </Button>
          </GridItem>
          <GridItem xs={10} sm={5}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.props.handleSkip}
                className={classes.button}
              >
                Karten ohne Adressen bestellen
             </Button>
          </GridItem>
        <GridItem xs={12} sm={11}>
        <div className={this.state.visible ? '' : 'hidden'}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h5 className={classes.descripText}>Beispiel Einträge:</h5>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer xs={11}>
                <GridItem xs={11} ><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Name Empfänger:</span></div></GridItem>
                <GridItem xs={11} ><div className={classes.descripText}>Der Name des Empfängers der Grusskarte. Z.B. Kundenname, Name eines Freundes oder Familienmitglieds.</div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Strasse:</span></div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Strasse des Empfängers.</div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Postleitzahl:</span></div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Postleitzahl des Empfängers.</div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Stadt:</span></div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}>Der Stadtname des Empfängers.</div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Andrede:</span></div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Andrede auf der Karte, individuell auf den Empfänger zugeschnitten. Beispiele: "Hallo Herr Schmidt", "Hallo Lisa", "Sehr geehrte Frau Birzele", ...</div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Gruss:</span></div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Grussformel auf der Karte. Beispiele: "Eine besinnliche Weihnachtszeit wünscht Ihnen ihr,", "Mit freundlichen Grüssen,", "Dein,", ...</div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Name Versender:</span></div></GridItem>
                <GridItem xs={11} sm={11}><div className={classes.descripText}>Der Name des Versender, meisstens Ihr eigener Name. Beispiele: "ihr CLIPTHIS Team", "dein Schatz", "dein Thomas", ...</div></GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </div>
        </GridItem>
        <GridItem xs={12} sm={11}>
        <div className={this.state.visible ? '' : 'hidden'}>
          <br/>
          <br/>
          <h4 className={classes.infoTextSmall}>Ausgewählte Kontakte</h4>
          <Table tableData={this.state.tableData} tableHead={["Name","Strasse","Postleitzahl","Stadt","Anrede", "Gruss", "Ihr Name"]}/>
          </div>
          </GridItem>
        <GridItem xs={12} sm={11}>
        <div className={this.state.visible ? '' : 'hidden'}>
          <br/>
          <br/>
          <br/>
          <br/>
          <h4 className={classes.infoTextSmall}> Variante 1: (für wenige Kontaktadressen)</h4>
          <h5 className={classes.infoTextSmall}>Füllen Sie folgende Felder aus und klicken Sie auf "Eintrag hinzufügen" um einzelne Kontaktadressen hinzuzufügen.</h5>
            <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleContactFormSubmit}>
              <TextField
                required
                id="contact-name"
                label="Name Empfänger"
                className={classes.textField}
                value={this.state.contactName}
                onChange={this.handleChange('contactName')}
                margin="normal"
              />
              <TextField
                required
                id="contact-strasse"
                label="Strasse"
                className={classes.textField}
                value={this.state.contactStrasse}
                onChange={this.handleChange('contactStrasse')}
                margin="normal"
              />
              <TextField
                required
                id="standard-required"
                label="Postleitzahl"
                className={classes.textField}
                value={this.state.contactPostleitzahl}
                onChange={this.handleChange('contactPostleitzahl')}
                margin="normal"
              />
              <TextField
                required
                id="standard-stadt"
                label="Stadt"
                className={classes.textField}
                value={this.state.contactStadt}
                onChange={this.handleChange('contactStadt')}
                margin="normal"
              />
              <TextField
                required
                id="standard-anrede"
                label="Anrede"
                className={classes.textField}
                value={this.state.contactAndrede}
                onChange={this.handleChange('contactAndrede')}
                margin="normal"
              />
              <TextField
                required
                id="standard-anrede"
                label="Gruss"
                className={classes.textField}
                value={this.state.contactGruss}
                onChange={this.handleChange('contactGruss')}
                margin="normal"
              />
              <TextField
                required
                id="standard-anrede"
                label="Ihr Name"
                className={classes.textField}
                value={this.state.contactGrussname}
                onChange={this.handleChange('contactGrussname')}
                margin="normal"
              />
            </form>
            <Button 
              round 
              color="primary" 
              onClick={this.handleContactFormSubmit}
              disabled={!this.validateContactForm()}>
              Eintrag hinzufügen
            </Button>
            <br/>
            <br/>
            <br/>
            <br/>
            </div>
          </GridItem>
          <GridItem xs={12} sm={11}>
          <div className={this.state.visible ? '' : 'hidden'}>
          <h4 className={classes.infoTextSmall}>Variante 2: (für viele Kontaktadressen)</h4>
          <h5 className={classes.infoTextSmall}>Laden Sie sich das Excel Template herunter, befüllen Sie es mit ihren Kontaktadressen und laden Sie es wieder hoch. Achten Sie darauf die erste Zeile nicht zu verändern.</h5>
            <br/>
            <CSVLink 
              uFEFF={false}
              data={csvData}
              separator={";"}
              filename={"kontakte-template-clip-this.csv"}>
              <Button
                round 
                color="primary">
                Template Download</Button>
            </CSVLink>
            <br/>
            <br/>
            </div>
            </GridItem>
            <div className={this.state.visible ? '' : 'hidden'}>
            <GridItem xs={12} sm={11}>
              <CSVReader
                cssClass="csv-input"
                onFileLoaded={this.handleCSV}>
              </CSVReader>
            </GridItem>
            </div>
          </GridContainer>          
      </GridContainer>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={11}>
          <h4 className={classes.infoTextMobile}>Adressen auswählen</h4>
          <h5 className={classes.overviewTextMobile}>Eingetragene Adressen werden auf direkt auf die Karten geschrieben.</h5>
          <br/>
        </GridItem>
        <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.ExpansionPanel}>
          <h5 className={classes.overviewTextMobile}>Beispiel Einträge:</h5>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GridContainer xs={11}>
          <GridItem xs={11} ><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Name Empfänger:</span></div></GridItem>
          <GridItem xs={11} ><div className={classes.descripText}>Der Name des Empfängers der Grusskarte. Z.B. Kundenname, Name eines Freundes oder Familienmitglieds.</div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Strasse:</span></div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Strasse des Empfängers.</div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Postleitzahl:</span></div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Postleitzahl des Empfängers.</div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Stadt:</span></div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}>Der Stadtname des Empfängers.</div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Andrede:</span></div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Andrede auf der Karte, individuell auf den Empfänger zugeschnitten. Beispiele: "Hallo Herr Schmidt", "Hallo Lisa", "Sehr geehrte Frau Birzele", ...</div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Gruss:</span></div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}>Die Grussformel auf der Karte. Beispiele: "Eine besinnliche Weihnachtszeit wünscht Ihnen ihr,", "Mit freundlichen Grüssen,", "Dein,", ...</div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}><span style={{fontWeight:"bold"}}>Name Versender:</span></div></GridItem>
          <GridItem xs={11} sm={11}><div className={classes.descripText}>Der Name des Versender, meisstens Ihr eigener Name. Beispiele: "ihr CLIPTHIS Team", "dein Schatz", "dein Thomas", ...</div></GridItem>
          </GridContainer>
        </ExpansionPanelDetails>
        </ExpansionPanel>

          <GridItem xs={12} sm={11}>
          <br/>
            <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleContactFormSubmit}>
            <CustomInput
              placeholder="Name Empfänger"
              id="contact-name"
              autoFocus="true"
              type="text"
              value={this.state.contactName}
              onChange={this.handleChange('contactName')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />

            <CustomInput
              placeholder="Strasse Empfänger"
              id="contact-strasse"
              autoFocus="true"
              type="email"
              value={this.state.contactStrasse}
              onChange={this.handleChange('contactStrasse')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />
            <CustomInput
              placeholder="Postleitzahl Empfänger"
              id="contact-plz"
              autoFocus="true"
              type="text"
              value={this.state.contactPostleitzahl}
              onChange={this.handleChange('contactPostleitzahl')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />
            <CustomInput
              placeholder="Stadt Empfänger"
              id="contact-stadt"
              autoFocus="true"
              type="text"
              value={this.state.contactStadt}
              onChange={this.handleChange('contactStadt')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />
            <CustomInput
              placeholder="Anrede auf Karte"
              id="contact-anrede"
              autoFocus="true"
              type="text"
              value={this.state.contactAndrede}
              onChange={this.handleChange('contactAndrede')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />
            <CustomInput
              placeholder="Grussformel"
              id="contact-gruss"
              autoFocus="true"
              type="text"
              value={this.state.contactGruss}
              onChange={this.handleChange('contactGruss')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />
            <CustomInput
              placeholder="Name Absender"
              id="contact-grussname"
              autoFocus="true"
              type="text"
              value={this.state.contactGrussname}
              onChange={this.handleChange('contactGrussname')}
              formControlProps={{
                fullWidth: true,
                className: classes.formControlMobile
              }}
              inputProps={{
                type: "text",
                className: classes.formControlInputMobile
              }}
            />
            </form>
            <Button 
              fullWidth
              round 
              color="primary" 
              onClick={this.handleContactFormSubmit}
              disabled={!this.validateContactForm()}>
              Eintrag hinzufügen
            </Button>
          </GridItem>
          <GridItem xs={11}>
          <h4 className={classes.infoTextMobile}>Ausgewählte Kontakte</h4>
          <Table
            tableData={this.state.tableData} tableHead={["Name","Strasse","Postleitzahl","Stadt","Anrede", "Gruss", "Ihr Name"]}/>
          </GridItem>
        </GridContainer>

      </MediaQuery>
      </div>
            
    );
  }
}

export default withStyles(productCyclePageStyle)(Step4Contacts);
