// ##############################
// // // LoginPage view styles
// #############################

import {
    container,
    cardTitle,
    colors
  } from "./material-dashboard-pro-react.jsx";

import customSelectStyle from "../customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../customCheckboxRadioSwitch.jsx";
  
  const productCyclePageStyle = theme =>  ({
    container,
    cardTitle: {
      ...cardTitle,
      color: "#FFFFFF"
    },
    textCenter: {
      textAlign: "center"
    },
    content: {
      paddingTop: "18vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    },
    contentMobile: {
      paddingTop: "0.5vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    },
    primaryButtonColor: {
      color: colors.primaryButtonColor
    },
    justifyContentCenter: {
      justifyContent: "center !important"
    },
    customButtonClass: {
      "&,&:focus,&:hover": {
        color: "#FFFFFF"
      },
      marginLeft: "5px",
      marginRight: "5px"
    },
    inputAdornment: {
      marginRight: "18px"
    },
    inputAdornmentIcon: {
      color: "#555"
    },
    cardHidden: {
      opacity: "0",
      transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
      marginBottom: "20px"
    },
    socialLine: {
      padding: "0.9375rem 0"
    },
    registerText: {
      cursor: "pointer",
      color: "#3C4858",
      textDecoration: "none",
      fontWeight: "300",
      marginTop: "30px",
      marginBottom: "25px",
      marginLeft: "15px",
      minHeight: "32px",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
      }
    },
    infoText: {
      fontWeight: "300",
      margin: "10px 0 30px",
      textAlign: "left"
    },
    infoTextSmall: {
      fontWeight: "300",
      margin: "0px 0 10px",
      textAlign: "left"
    },
    infoTextSmallBold: {
      fontWeight: "500",
      margin: "0px 0 10px",
      textAlign: "left"
    },
    infoTextMobile: {
      fontWeight: "300",
      margin: "5px 0 5px",
      textAlign: "center"
    },
    overviewTextMobile: {
      fontWeight: "300",
      margin: "5px 0 5px",
      textAlign: "left"
    },
    overviewTextMobileBold: {
      fontWeight: "500",
      margin: "5px 0 5px",
      textAlign: "left"
    },
    checkboxTextMobile: {
      fontWeight: "300",
      margin: "0px 0 5px",
      textAlign: "left",
      fontSize: "14px",
    },
    summaryText:{
      fontWeight: "300",
      margin: "10px 0 30px",
      textAlign: "left"
    },
    spinner:{
      display: "block",
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: "5",
      margin: "0 auto"
    },
    stepperMobile:{
      backgroundColor: "#fff",
    },
    stepButton:{
      color: "#fff",
      label:{
        fontSize: "100px",
      },
      fontSize: "20px",
      buttonBase: {
        backgroundColor: "#000"
      }
    },
    root: {
      width: '90%',
      backgroundColor: "#fff",
      iconColor: '#fff',
      colorPrimary: "#fff"
    },
    rootMobileStepper: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing.unit,
      backgroundColor: colors.primaryButtonColor,
      "&:hover": {
        backgroundColor: colors.primaryButtonColorHover
      },
    },
    
    backButton: {
      marginRight: theme.spacing.unit,
    },
    
    buttonMobile: {
      width: "50%"
    },

    buttonMobileSkip: {
      width: "100%"
    },

    completed: {
      display: 'inline-block',
    },
    
    instructions: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    choiche: {
      textAlign: "center",
      cursor: "pointer",
      marginTop: "20px"
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    dragme: {
      position: "absolute",
      width: "50px",
      height: "50px",
      cursor: "move",
    },
    dragmeMobile: {
      position: "absolute",
      width: "25px",
      height: "25px",
      cursor: "move",
    },

    buttonText:{
      fontWeight: "200",
      margin: "5px 0 10px",
      textAlign: "left"
    },
    anleitungThumbnail: {
      position: "relative"
    },
    anleitungCaption: {
      position: "absolute",
      top: "10%",
      left: "5%",
      width: "60%",
      textAlign: "left", 
      fontSize: "22px"
    },
  
    anleitungBodytext: {
      position: "absolute",
      top: "25%",
      left: "5%",
      width: "100%",
      textAlign: "left",
      font:"arial"
    },
  
    anleitungEndtext: {
      position: "absolute",
      bottom: "25%",
      left: "5%",
      width: "60%",
      textAlign: "left",
      fontSize: "14px",
    },
    anleitungAdresse: {
      position: "absolute",
      top: "25%",
      left: "72%",
      width: "20%",
      textAlign: "left",
      fontSize: "14px",
      lineHeight: "1",
    },
    postcard: {
      position: "relative",
      width: "100%",
      boxShadow: "5px 5px 5px grey"
    },
    anleitung: {
      position: "absolute",
      bottom: "0%",
      left: "0%",
      width: "70%"
    },
    ExpansionPanel: {
      height: "30px",
      minHeight: "10px"
    },
    labelHorizontalMobile: {
      ...customCheckboxRadioSwitch.labelHorizontal,
      paddingTop: "20px"
    },
    formControlMobile: {
      margin: "0",
      padding: "0",
      border: "0.5px solid #ccc",
      borderRadius: "5px",
      marginBottom: "5px",
      "&:hover": {
        boxShadow: "0 0 5px #08f",
      }
    },
    formControlInputMobile: {
      "&:before,&:after": {
        border: "0px"
      }
    },
    hidden: {
      display: "none"
    }

  });
  
  export default productCyclePageStyle;
  