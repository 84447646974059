// ##############################
// // // Info component styles
// #############################

import {
  colors
} from "../views/material-dashboard-pro-react.jsx";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  primary: {
    color: colors.primaryColor
  },
  warning: {
    color: colors.warningColor
  },
  danger: {
    color: colors.dangerColor
  },
  success: {
    color: colors.successColor
  },
  info: {
    color: colors.infoColor
  },
  rose: {
    color: colors.roseColor
  },
  gray: {
    color: colors.grayColor
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  descriptionWrapper: {
    color: colors.grayColor,
    overflow: "hidden"
  },
  title: {
    color: "#3C4858",
    margin: "30px 0 15px",
    textDecoration: "none",
    fontSize: "18px"
  },
  description: {
    color: colors.grayColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px"
  }
};

export default infoStyle;
