import React from "react";
import MediaQuery from 'react-responsive';
import { Redirect } from "react-router-dom";
import { API } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import PaypalExpressBtn from 'react-paypal-express-checkout';

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import { FormGroup, FormControl} from "react-bootstrap";
import Button from "../../../components/CustomButtons/Button.jsx"

// Payment with stripe
import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentForm from '../../../components/CheckoutForm/PaymentForm.jsx';
import config from '../../../config.js'

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.isLoading,
      name: this.props.name,
      email: this.props.email,
      zipcode: this.props.content.zipcode,
      city: this.props.content.city,
      street: this.props.content.street,
      numberOfCards: this.props.content.numberOfCards,
      checkedAdressen: this.props.content.checkedAdressen,
      coupon: "",
      price: this.props.content.priceTotal,
      couponUsed: false,
    };
    this.validateCoupon = this.validateCoupon.bind(this);
    this.handleCoupon = this.handleCoupon.bind(this);
  }

  isValidated() {
    return true;
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    if(this.props.location !== undefined){
      if(this.props.location.state.content !== undefined){
        this.props.handleLoginData(this.props.location.state.content)
      }
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAmountChange = event => {
    this.setState({
      numberOfCards: event.target.value
    });
    this.props.handleAmountChange(event.target.value, this.calculatePrices(event.target.value)[0]);
  }

  calculatePrices(amount){

    var priceSingle = 0;

    if(0<amount && amount<10){
      priceSingle = 10;
    }else if(9<amount && amount<50){
      priceSingle = 9;
    }else if(49<amount && amount<100){
      priceSingle = 8;
    }else if(99<amount && amount<200){
      priceSingle = 7;
    }else if(199<amount && amount<500){
      priceSingle = 6;
    }else if(499<amount && amount<1000){
      priceSingle = 5;
    }else if(999<amount && amount<2000){
      priceSingle = 4;
    }else{
      priceSingle = 3;
    }
    var price = priceSingle * amount;

    return [price, priceSingle]
  };

  validateCoupon = event => {
    this.setState({
      coupon: event.target.value
    })
  }

  async handleCoupon(){
    if (this.state.couponUsed){
      alert("Sie können maximal einen Gutschein verwenden.")
      return;
    }
    try{
      const item = await API.get("clpthis", `/clpthis-coupon/${this.state.coupon}`);
      console.log(item.active);
      console.log(item.value);
      console.log(item.couponId);
      if(item.active === true){
        const value = item.value

        var price = this.state.price
        const newPrice = (100-parseInt(value.substr(0, value.length-1)))*0.01 * price
        this.setState({
          price: newPrice,
          couponUsed: true
        });
      };
    }
    catch(e){
      alert(e);
    }
  }

  render() {
    const { classes } = this.props;
    const onSuccess = (payment) => {
      console.log("Payment successfull ", payment);
      this.props.handlePaypalSubmit();
    }
      

    const onError = (error) =>
      console.log('Erroneous payment OR failed to load script!', error);

    const onCancel = (data) =>
      console.log('Cancelled payment!', data);

    let env = 'production'; // you can set here to 'production' for production

    const client = {
      sandbox: 'AcrA4wkyRdcrEWOhztTQ9drro59MdG_-aFkfeTt5HYVE7Ydf8lQpTB-iQX1sD1PV1HWQRdwGGLu4z1ay',
      production: 'AdH9Yka9oqkbDwEGuhIZV5luPLBJQO9cluo0PAU5T6NyRR69Otwv17PGFQmJ09tngbDSo3zTem56EEEA',
    }

    return (
      this.props.isAuthenticated
      ? 
        <div>
        <GridContainer justify="center">
          <GridItem xs={10} sm={10}>
            <h4 className={classes.infoTextSmallBold}>Checkout</h4>
          </GridItem>
          {this.state.checkedAdressen === "false" || this.props.content.product === "SendHome"
            ?
            <GridItem xs={10} sm={10}>
            <FormGroup bsSize="large" controlId="name">
            <h4 className={classes.infoText}>Anzahl Karten</h4>
              <FormControl
                type="number"
                value={this.state.numberOfCards}
                onChange={this.handleAmountChange}
                placeholder=""
              />
            </FormGroup>
            <FormGroup bsSize="large" controlId="name">
            <h4 className={classes.infoText}>Vorname / Name</h4>
              <FormControl
                type="text"
                value={this.state.name}
                onChange={this.props.handleChange("name")}
                placeholder=""
              />
            </FormGroup>
            <FormGroup bsSize="large" controlId="street">
            <h4 className={classes.infoText}>Strasse / Hausnummer</h4>
              <FormControl
                type="text"
                value={this.state.street}
                onChange={this.props.handleChange("street")}
                placeholder=""
              />
            </FormGroup>
            <FormGroup bsSize="large" controlId="zipcode">
            <h4 className={classes.infoText}>Postleitzahl</h4>
              <FormControl
                type="text"
                value={this.state.zipcode}
                onChange={this.props.handleChange("zipcode")}
                placeholder=""
              />
            </FormGroup>
            <FormGroup bsSize="large" controlId="street">
            <h4 className={classes.infoText}>Stadt</h4>
              <FormControl
                type="text"
                value={this.state.city}
                onChange={this.props.handleChange("city")}
                placeholder=""
              />
            </FormGroup>
            </GridItem>
            : <div></div>}
          </GridContainer>
           
          <GridContainer justify="center">
            <GridItem xs={10} sm={5}>
              <h4 className={classes.infoText}>Anzahl Karten: {this.props.content.numberOfCards}</h4>
              <h4 className={classes.infoText}>Preis: {this.state.price} {this.props.content.currency}</h4>
            </GridItem>
            <GridItem xs={10} sm={1}></GridItem>
            <GridItem xs={10} sm={4}>
              <h4 className={classes.infoText}>Gutschein einlösen</h4>
              <FormGroup bsSize="large" controlId="coupon">
              <FormControl
                type="text"
                value={this.state.coupon}
                onChange={this.validateCoupon}
                placeholder="Gutscheincode"
              />
            </FormGroup>
            <Button 
              round 
              color="primary" 
              onClick={this.handleCoupon}
              disabled={this.state.coupon === ""}>
              Einlösen</Button>

            <br/>
            <br/>
            </GridItem>
          </GridContainer>  
          <GridContainer justify="center">
            <GridItem xs={10} sm={5}>
              <h4 className={classes.infoTextSmallBold}>Kreditkarte</h4>
              <StripeProvider apiKey={config.STRIPE_KEY}>
                <Elements>
                  <PaymentForm handleSubmit={this.props.handleSubmit}/>
                </Elements>
              </StripeProvider>
            </GridItem>
            <GridItem xs={10} sm={1}></GridItem>
            <GridItem xs={10} sm={4}>
              <h4 className={classes.infoTextSmallBold}>Paypal</h4>
              <h4 className={classes.infoTextSmall}>Mit Paypal bezahlen:</h4>
              <PaypalExpressBtn 
                env={env} 
                client={client} 
                currency={this.props.content.currency} 
                total={this.state.price} 
                onError={onError} 
                onSuccess={onSuccess} 
                onCancel={onCancel}
                style={{
                  size: 'large',
                  color: 'gold',
                  shape: 'rect',
                  label: 'pay',
                  tagline: 'true'}}
                 />
            </GridItem>
          </GridContainer>
        
        </div>
      : <Redirect to={{ pathname: '/pages/logininform', state: { step: 3, content: this.props.content } }}/>
    );
  }
}

export default withStyles(productCyclePageStyle)(Checkout);
