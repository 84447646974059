import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
//import LoaderButton from "../../../components/LoaderButton"
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import FacebookButton from "../../../components/Facebook/FacebookButton.jsx";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import loginPageStyle from "../../../assets/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      isLoading: false,
      email: "",
      password: "",
      showPassword: false,
      newPassword: false,
      user: null,
    };
    this.nextPath = this.nextPath.bind(this);
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
    
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED'){
        
        this.setState({
          newPassword:true,
          user: user
        })

      }
      else{
        this.userLogin()
      }

    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        alert("User not confirmed.")
      } else if (err.code === 'PasswordResetRequiredException') {
        alert("Passwort expired. Please Reset.")
      } else if (err.code === 'NotAuthorizedException') {
        alert("User not authorized")
      } else if (err.code === 'UserNotFoundException') {
        alert("User does not exist.")
      } else {
        alert("Error logging in.")
      }
    }
  }

  userLogin = async event => {
    try{
      await Auth.currentAuthenticatedUser().then(user => 
        this.props.userHasAuthenticated(true, user),
        this.nextPath("/pages/newItem"));
    } catch (err) {
      alert("User is not authenticated")
      alert(err)
      this.setState({ isLoading: false });
    }
  }

  handleNewPasswordSubmit = async event => {
    const user = this.state.user
    const challengeParam = user.challengeParam;
    const email = challengeParam.userAttributes.email
    const password = this.state.password

    if(this.state.password.length < 8){
      alert("Passwort muss mindestens 8 Ziffern haben.");
      return
    }
    if(!(/\d/.test(this.state.password))){
      alert("Passwort muss eine Zahl beinhalten.");
      return
    }
    if(this.state.password === this.state.password.toLowerCase()){
      alert("Passwort muss Groß und Kleinbuchstaben enthalten.");
      return
    }

    try{
      await Auth.completeNewPassword(
        user,               // the Cognito User Object
        this.state.password,       // the new password
        // OPTIONAL, the required attributes
        {email}
        ).then(user =>
          this.props.userHasAuthenticated(true, user),
          this.nextPath("/pages/newItem"));
      } catch (err) {
        alert("Passwort Änderung fehlgeschlagen")
        alert(err)
        this.setState({ isLoading: false });
      }

  }
      
  handlePasswordVisibility = () => {
    this.setState({ 
      showPassword: !this.state.showPassword 
    });
  }



  nextPath(path, userData) {

    this.props.history.push({
      pathname: path,
      state: { user: userData }
      });
  }

  renderNewPassword(){
    const { classes } = this.props;
    return (
    <div className={classes.content}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8} md={5}>
                    <form className={classes.form} onSubmit={this.handleNewPasswordSubmit}>
                      <CustomInput
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <LockOutline className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "New Password"
                        }}
                      />
                      <Button 
                        round 
                        color="primary" 
                        onClick={this.handleNewPasswordSubmit}
                        disabled={!this.validateForm()}>
                          Neues Passwort wählen</Button>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    );
  }
  
  renderLogin() {
    const { classes } = this.props;
    return (
      <div> 
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                  <form onSubmit={this.handleSubmit}>
                    <Card login className={classes[this.state.cardAnimaton]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="primary"
                      >
                        <h4 className={classes.cardTitle}>Log in</h4>
                        <h4 className={classes.socialLine}>
                          CLIPTHIS - Augmented Reality selber machen!
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {/*<FacebookButton
                          onLogin={this.handleFbLogin}/>
                        <hr />*/}
                        <CustomInput
                          labelText="Email"
                          id="email"
                          autoFocus="true"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange('email')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          className={classes.inputField}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          value={this.state.password}
                          onChange={this.handleChange('password')}
                          type="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handlePasswordVisibility}
                                >
                                  {this.state.showPassword ?  <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                <LockOutline
                                    className={classes.inputAdornmentIcon}
                                  />
                              </InputAdornment>
                            ),
                            type: this.state.showPassword ? 'text' : 'password'
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                        <GridContainer justify="center">
                          <GridItem>
                            <Button 
                              round 
                              color="primary" 
                              onClick={this.handleSubmit}
                              disabled={!this.validateForm()}>
                              Anmelden</Button>
                          </GridItem>
                        </GridContainer>
                      </CardFooter>
                      <GridContainer justify="center">
                        <GridItem>
                          <Button 
                            round 
                            color="info" 
                            onClick={() => this.nextPath('/pages/forgotpassword')}
                            className={classes.subButton}>
                            Passwort vergessen
                          </Button>
                          {/*<Button 
                            round 
                            color="info" 
                            onClick={() => this.nextPath('/pages/register')}
                            className={classes.subButton}>
                            Jetzt registrieren
                          </Button>*/}
                        </GridItem>
                      </GridContainer>
                    </Card>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <form onSubmit={this.handleSubmit}>
                    <h2 className={classes.titleMobile}>Log in</h2>
                    <div className={classes.socialLineMobile}>
                      CLIPTHIS - Augmented Reality selber machen!
                    </div>
                    {/*<br/>
                    <FacebookButton onLogin={this.handleFbLogin}/>
                    <hr />*/}
                    <CustomInput
                          labelText="Email"
                          id="email"
                          autoFocus="true"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange('email')}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          value={this.state.password}
                          onChange={this.handleChange('password')}
                          type="password"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={this.handlePasswordVisibility}
                                >
                                  {this.state.showPassword ?  <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                <LockOutline
                                    className={classes.inputAdornmentIcon}
                                  />
                              </InputAdornment>
                            ),
                            type: this.state.showPassword ? 'text' : 'password'
                          }}
                        />
                      <Button 
                        fullWidth
                        round 
                        color="primary" 
                        onClick={this.handleSubmit}
                        disabled={!this.validateForm()}>
                        Anmelden</Button>
                      <h4 className={classes.registerText} onClick={() => this.nextPath('/pages/register')}>Noch nicht Kunde? Jetzt registrieren.</h4>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.newPassword === false
          ? this.renderLogin()
          : this.renderNewPassword()}
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
