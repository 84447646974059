import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-table/react-table.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoIcon from "@material-ui/icons/PlayCircleFilled";
import DownIcon from "@material-ui/icons/ArrowDownward";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import { ClipLoader } from "react-spinners";
import GridItem from "../../../components/Grid/GridItem.jsx";
import MediaQuery from "react-responsive";

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Modal from "@material-ui/core/Modal";
import CardBody from "../../../components/Card/CardBody";
import { Card } from "@material-ui/core";
import { Player } from "video-react";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";
import ItemsTable from "./ItemsTable.jsx";

// AWS App Sync
import { graphql, compose } from "react-apollo";
import DeleteItem from "../../../mutations/DeleteItem";
import ListItemsPerUser from "../../../queries/ListItemsPerUser";

import AWSAppSyncClient from "aws-appsync";
import awsmobile from "../../../aws-exports";
import gql from "graphql-tag";
import { Storage, Auth } from "aws-amplify";
import { getItems } from "../../../graphql/queries";

// Style
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

import userRights from "./user_rights.json";
import config from "./../../../config.js";
import {getWebarStateName, getAppsStateName} from "../../../utils/helpers.js";

class ClipThisItems extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      items: [],
      data: [],
      isImageModalOpen: false,
      isVideoModalOpen: false,
      clipThisItems: null,
      mobileOpen: false,
      miniActive: false,
      imageUrl: null,
      videoUrl: null,
      videoFile: null,
      image: null,
      imageHeight: 200,
      imageWidth: 200,
      videoHeight: 200,
      videoWidth: 200
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    this.loadItems();
  }

  refresh() {
    this.setState({ isLoading: true });
    this.loadItems();
  }
  
  async loadItems() {
    // Query items
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    let userEmail = this.props.email

    try {
      this.setState({
        isLoading: true
      });
      client
        .query({
          query: gql(getItems),
          variables: { userId: this.props.userName }
        })
        .then(({ data: { getItems } }) => {
          const displayData = getItems.items.map((prop, key) => {
            return {
              id: key,
              clipThisId: prop.clipThisId,
              userId: prop.userId,
              name: prop.displayName,
              views: prop.views + ' / ' + prop.maxViews,
              premium: prop.premium,
              webarStatus: getWebarStateName(prop),
              appsStatus: getAppsStateName(prop),
              campaign: prop.campaign,
              actions: (
                // custom button actions
                <div className="actions-right">
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      this.handleQRDownload(prop.clipThisId);
                    }}
                    color="info"
                    className="edit">
                    <DownIcon />
                  </Button>{" "}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      this.handleVideoModal(prop.videoFileLocation);
                    }}
                    color="info"
                    className="edit">
                    <VideoIcon />
                  </Button>{" "}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      this.handleImageModal(prop.imageFileLocation);
                    }}
                    color="info"
                    className="edit">
                    <ImageIcon />
                  </Button>{" "}
                  {/* use this button to add a dashboard kind of action */}
                  {userRights["show_analytics"].indexOf(userEmail) >= 0 ? (
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        // Go to edit item page and feed obj data
                        this.props.history.push({
                          pathname: "/pages/itemdashboard",
                          state: {
                            clipThisId: prop.clipThisId,
                            displayName: prop.displayName,
                            analytics: prop.analytics
                          }
                        });
                      }}
                      color="info"
                      className="like">
                      <DashboardIcon />
                    </Button>
                  ) : null }
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      // Go to edit item page and feed obj data
                      this.props.history.push({
                        pathname: "/edititem",
                        state: {
                          clipThisId: prop.clipThisId,
                          maxViews: prop.maxViews,
                          views: prop.views,
                          analytics: prop.analytics,
                          actionData: prop.actionData,
                          actionType: prop.link,
                          actionName: prop.actionName,
                          displayName: prop.displayName,
                          active: prop.active,
                          premium: prop.premium,
                          qrposx: prop.qrposx,
                          qrposy: prop.qrposy,
                          createdAt: prop.createdAt,
                          description: prop.description,
                          webarStatus: prop.webarStatus,
                          appsStatus: prop.appsStatus,
                          updatedAt: prop.updatedAt
                        }
                      });
                    }}
                    color="warning"
                    className="edit">
                    <Edit />
                  </Button>{" "}
                  {/* use this button to remove the data row */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      this.handleDelete(prop.clipThisId)
                    }}
                    color="danger"
                    className="remove">
                    <DeleteIcon />
                  </Button>{" "}
                </div>
              )
            };
          });
          this.setState({
            data: displayData,
            isLoading: false
          });
        });
    } catch (e) {
      console.log("Error ", e);
    }
  }

  handleQRDownload = async clipThisId => {
    const bucket = config.s3.QR_CODES.BUCKET;
    const region = config.s3.REGION;
    const level = config.s3.QR_CODES.LEVEL;
    const qrUrl = await Storage.get(clipThisId + ".jpg", {
      bucket,
      region,
      level
    });

    setTimeout(() => {
      const response = {
        file: qrUrl
      };
      // now, let's download:
      window.open(response.file);
      // window.location.href = response.file;
    }, 100);
  };

  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      var w = img.naturalWidth || img.width;
      var h = img.naturalHeight || img.height;
      callback({ width: w, height: h });
    };
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  }

  handleImageModal = async imageLocation => {
    var infos = JSON.parse(imageLocation);

    // File Upload paramteres
    const bucket = config.s3.ATTACHMENTS.BUCKET;
    const region = config.s3.REGION;
    const level = config.s3.ATTACHMENTS.LEVEL;

    let imageKey = infos["key"];
    imageKey = imageKey.split("/")[1];

    try {
      const imageUrl = await Storage.get(imageKey, { bucket, region, level });

      this.setState({
        image: imageUrl
      });

      this.getImageWidthHeight(imageUrl, result => {
        var width = 0;
        var height = 0;
        // Height > Width
        if (result.width <= result.height) {
          // Check if height is bigger than screen
          if (result.height > 600) {
            height = 600;
            width = (height / result.height) * result.width;
          } else {
            width = result.width;
            height = result.height;
          }
        }
        // Width > Height
        else {
          // Check if width is bigger than screen
          if (result.width > 600) {
            width = 600;
            height = (width / result.width) * result.height;
          } else {
            width = result.width;
            height = result.height;
          }
        }
        this.setState({
          imageWidth: width,
          imageHeight: height
        });
      });
    } catch {
      console.log("Error");
    }

    this.handleImageOpen();
  };

  handleVideoModal = async videoLocation => {
    var infos = JSON.parse(videoLocation);

    const bucket = config.s3.ATTACHMENTS.BUCKET;
    const region = config.s3.REGION;
    const level = config.s3.ATTACHMENTS.LEVEL;
    const videoKey = infos["key"];

    try {
      const videoUrl = await Storage.vault.get(videoKey, {
        bucket,
        region,
        level
      });
      this.setState({
        videoFile: videoUrl
      });
      // callback function after Video is changed
      this.getVideoWidthHeight(videoUrl, result => {
        this.setState({ videoWidth: result.width });
        this.setState({ videoHeight: result.height });
      });
    } catch {
      console.log("Error");
    }

    this.handleVideoOpen();
  };

  getVideoWidthHeight(url, callback) {
    var vid = document.createElement("VIDEO");
    vid.onloadedmetadata = function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w = vid.videoWidth;
      var h = vid.videoHeight;
      callback({ width: w, height: h });
    };
    // Setting the source makes it start downloading and eventually call `onload`
    vid.src = url;
  }

  handleImageOpen = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleVideoOpen = () => {
    this.setState({ isVideoModalOpen: true });
  };

  handleClose = () => {
    this.setState({
      isImageModalOpen: false,
      isVideoModalOpen: false
    });
  };

  async downloadAnalyticsFiles(clipThisId) {
    const filename = clipThisId + '.json';
    const apps = await this.fetchFile(filename, config.s3.ANALYTICS.APPS);
    const webar = await this.fetchFile(filename, config.s3.ANALYTICS.WEBAR);

    return {
      appsAnalytics: apps,
      webarAnalytics: webar
    };
  }

  async fetchFile(filename, bucket) {
    try {
      const result = await Storage.get(filename, {
        bucket: bucket,
        download: true
      })
      const bodyString = result.Body.toString("utf8");
      return new Blob([bodyString], {
        type: 'text/plain'
      });
    } catch (err) {
      console.log(err);
      // 404 error is treated as no data
      return null;
    }
  }

  handleAnalyticsSave(appsAnalytics, webarAnalytics) {
    if (appsAnalytics != null) {
      this.saveFileLocally(appsAnalytics, 'apps-analytics.json');
    }
    if (webarAnalytics != null) {
      this.saveFileLocally(webarAnalytics, 'webar-analytics.json');
    }
  }

  saveFileLocally(obj, filename) {
    const url = window.URL.createObjectURL(obj);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename; // the filename
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  handleDelete = async (clipThisIdToDelete) => {
    // Confirm
    const confirmed = window.confirm(
      "Sind Sie sich sicher, dass Sie diesen Inhalt löschen möchten?"
    );
    if (!confirmed) {
      return;
    }

    // Check campaign
    var data = this.state.data;
    let item = data.find(({ clipThisId }) => clipThisId === clipThisIdToDelete); 
    if (item.campaign !== "{}" && item.campaign != null) {
      alert(
        "Inhalt ist einer Kampagne zugeordnet. Bitte erst aus Kampagne löschen."
      );
      return;
    }

    // Offer to save analytics if it's available and the user has corresponding rights.
    this.setState({ isLoading: true });
    const { appsAnalytics, webarAnalytics } = await this.downloadAnalyticsFiles(clipThisIdToDelete);
    this.setState({ isLoading: false });
    const hasAnalytics = appsAnalytics != null || webarAnalytics != null;
    const userEmail = this.props.userEmail;
    const analyticsShown = userRights["show_analytics"].indexOf(userEmail);
    if (analyticsShown && hasAnalytics) {
      const save = window.confirm(
        "Möchten Sie die Analysen der Inhalte speichern?"
      );
      if (save) {
        this.handleAnalyticsSave(appsAnalytics, webarAnalytics);
      }
    }

    // Delete from local data
    data = data.filter(({clipThisId}) => clipThisId !== clipThisIdToDelete);
    this.setState({ data: data });

    // Delete from server
    try {
      this.deleteFromServer(clipThisIdToDelete);
    } catch (e) {
      alert(e);
      return;
    }
  }

  deleteFromServer(clipThisId) {
    // Delete from dynamoDB.
    // dynamoDB updatetriggers the lambda `clipthis-platform-upload` 
    // to delete from the qr and premium buckets.
    this.props.onDelete({ clipThisId });

    let bucket = config.s3.ATTACHMENTS.BUCKET;
    const region = config.s3.REGION;

    // Delete image in private attachments folder
    const imageKeyjpg = clipThisId + ".jpg";
    const imageKeypng = clipThisId + ".png";
    try {
      Storage.vault.remove(imageKeyjpg, { bucket, region });
      Storage.vault.remove(imageKeypng, { bucket, region });
    } catch (e) {
      console.log("Error deleting image " + clipThisId, e);
    }

    // Delete video in private attachments folder
    const videoKey = clipThisId + ".mp4";
    try {
      Storage.vault.remove(videoKey, { bucket, region });
    } catch (e) {
      console.log("Error deleting video " + videoKey, e);
    }

    // Delete ui data analytics files
    const analyticsKey = clipThisId + '.json';
    try {
      bucket = config.s3.ANALYTICS.APPS;
      Storage.remove(analyticsKey, { 
        bucket: bucket
      });
      bucket = config.s3.ANALYTICS.WEBAR;
      Storage.remove(analyticsKey, { 
        bucket: bucket
      });
    } catch (e) {
      console.log("Error deleting analytics file " + analyticsKey, e);
    }
  }

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <GridContainer>
              <Sidebar />
            </GridContainer>
            <div className={classes.container}>
              <Card>
                <CardBody>
                  <div className={classes.spinner}>
                    <ClipLoader
                      className={classes.spinner}
                      sizeUnit={"px"}
                      size={150}
                      color={"#123abc"}
                      loading={this.state.isLoading}
                    />
                  </div>
                  <GridContainer className={classes.listItemContainer}>
                    <Modal
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      open={this.state.isImageModalOpen}
                      onClose={this.handleClose}
                      style={{
                        paddingLeft:
                          window.innerWidth / 2 -
                          this.state.imageWidth / 2 +
                          "px",
                        paddingTop:
                          window.innerHeight / 2 -
                          this.state.imageHeight / 2 +
                          "px"
                      }} >
                      <img
                        src={this.state.image}
                        style={{
                          width: this.state.imageWidth,
                          heigth: this.state.imageHeight
                        }}
                        alt=""/>
                    </Modal>
                    <Modal
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      open={this.state.isVideoModalOpen}
                      onClose={this.handleClose}
                      style={{
                        paddingLeft:
                          window.innerWidth / 2 -
                          this.state.videoWidth / 2 +
                          "px",
                        paddingTop:
                          window.innerHeight / 2 -
                          this.state.videoHeight / 2 +
                          "px"
                      }} >
                      <Player
                        src={this.state.videoFile}
                        fluid={false}
                        height={250}
                        poster={null} />
                    </Modal>
                    <GridItem xs={11}>
                      <h2>Inhalte</h2>
                    </GridItem>
                    <GridItem xs={1}>
                      <div style={{ algin: "right" }}>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            this.refresh();
                          }}
                          color="primary"
                          className="remove">
                          <RefreshIcon />
                        </Button>
                      </div>
                    </GridItem>
                    <GridItem xs={12}> 
                      <ItemsTable
                        data={this.state.data}
                        placeholderText="Sie haben noch keine CLIPTHIS Inhalte erstellt."/>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          </div>
        </MediaQuery>
        <MobileMessage />
      </div>
    );
  }
}

ClipThisItems.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(
  compose(
    graphql(ListItemsPerUser, {
      options: props => ({
        fetchPolicy: "cache-and-network",
        variables: { userId: props.userName }
      }),
      props: props => ({
        clipThisItems: props.data.listClipThisItemsPerUser
          ? props.data.listClipThisItemsPerUser.items
          : []
      })
    }),
    graphql(DeleteItem, {
      props: props => ({
        onDelete: clipThisItem =>
          props.mutate({
            variables: clipThisItem,
            optimisticResponse: {
              __typename: "Mutation",
              deleteItem: { ...clipThisItem, __typename: "clipThisItem" }
            }
          })
      })
    })
  )(ClipThisItems)
);
