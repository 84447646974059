import React from "react";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import {Image} from "react-bootstrap";
import { Player } from 'video-react';
import Table from "../../../components/Table/Table.jsx";

import productCyclePageStyle from "../../../assets/views/productCycleStyle.jsx";

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.isValidated = this.isValidated.bind(this); 
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  isValidated() {
    let valid = true;
    if (this.state.numberOfCards === 0){
        valid = false;
    };
    
    return valid;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <MediaQuery minWidth={1224}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}><h4 className={classes.infoText}>Übersicht</h4></GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Bild / Video </h5>
          </GridItem>
          <GridItem xs={12} sm={6}>
          <Image className={"Image"} src={this.props.content.imageFile}/>
          </GridItem>
          <GridItem xs={12} sm={5}>
          <Player  
            src={this.props.content.videoFile}
            fluid={false}
            height={250}
            poster={null}/>
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Karteninhalt</h5>
              {this.props.content.kartenText !== "empty"
              ? <h5 className={classes.overviewTextMobile}>{this.props.content.kartenText}</h5>
              :<h5 className={classes.overviewTextMobile}>- Kein Kartentext eingegeben.</h5>}
              {this.props.content.checkedAnleitung
              ? <h5 className={classes.overviewTextMobile}>- Anleitung ist aktiviert.</h5>
              :<h5 className={classes.overviewTextMobile}>- Anleitung ist deaktiviert.</h5>}
              
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Adressen</h5>
            {this.props.content.tableData.length !== 0
            ?  <Table tableData={this.props.content.tableData} tableHead={["Name","Strasse","Postleitzahl","Stadt","Anrede", "Gruss", "Ihr Name"]}/>
            : <h5 className={classes.overviewTextMobile}>- Keine Adressen angegeben.</h5>}
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Versand</h5>
            {this.props.content.product === "PrintAtHome"
            ?  <h5 className={classes.overviewTextMobile}>- Zu Hause drucken: Wir senden Ihnen eine Druck Datei per Email.</h5>
            : this.props.content.product === "SendHome"
              ? <h5 className={classes.overviewTextMobile}>- Wir drucken alle Karten aus und senden Ihnen diese zu.</h5>
              : <h5 className={classes.overviewTextMobile}>- Wir drucken alle Karten für Sie aus und senden diese direkt an die oben aufgeführten Adressen.</h5>}
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Menge / Preis </h5>
            <h5 className={classes.overviewTextMobile}>- Anzahl Karten: {this.props.content.numberOfCards}</h5>
            <h5 className={classes.overviewTextMobile}>- Preis: {this.props.content.priceTotal} {this.props.content.currency}</h5>
          </GridItem>
        </GridContainer>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}><h4 className={classes.infoTextMobile}>Übersicht</h4></GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Bild / Video </h5>
          </GridItem>
          <GridItem xs={12} sm={11}>
          <Image 
            src={this.props.content.imageFile}
            style={{width:"250px"}}/>
          </GridItem>
          <GridItem xs={12} sm={11}>
          <br/>
          <Player  
            src={this.props.content.videoFile}
            fluid={false}
            width={250}
            poster={null}/>
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Karteninhalt</h5>
              {this.props.content.kartenText !== "empty"
              ? <h5 className={classes.overviewTextMobile}>{this.props.content.kartenText}</h5>
              :<h5 className={classes.overviewTextMobile}>- Kein Kartentext eingegeben.</h5>}
              {this.props.content.checkedAnleitung
              ? <h5 className={classes.overviewTextMobile}>- Anleitung ist aktiviert.</h5>
              :<h5 className={classes.overviewTextMobile}>- Anleitung ist deaktiviert.</h5>}
              
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Adressen</h5>
            {this.props.content.tableData.length !== 0
            ?  <Table tableData={this.props.content.tableData} tableHead={["Name","Strasse","Postleitzahl","Stadt","Anrede", "Gruss", "Ihr Name"]}/>
            : <h5 className={classes.overviewTextMobile}>- Keine Adressen angegeben.</h5>}
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Versand</h5>
            {this.props.content.product === "PrintAtHome"
            ?  <h5 className={classes.overviewTextMobile}>- Zu Hause drucken: Wir senden Ihnen eine Druck Datei per Email.</h5>
            : this.props.content.product === "SendHome"
              ? <h5 className={classes.overviewTextMobile}>- Wir drucken alle Karten aus und senden Ihnen diese zu.</h5>
              : <h5 className={classes.overviewTextMobile}>- Wir drucken alle Karten für Sie aus und senden diese direkt an die oben aufgeführten Adressen.</h5>}
          </GridItem>
          <GridItem xs={12} sm={11}>
            <h5 className={classes.overviewTextMobileBold}>Menge / Preis </h5>
            <h5 className={classes.overviewTextMobile}>- Anzahl Karten: {this.props.content.numberOfCards}</h5>
            <h5 className={classes.overviewTextMobile}>- Preis: {this.props.content.priceTotal} {this.props.content.currency}</h5>
          </GridItem>
        </GridContainer>
        
        </MediaQuery>
      </div>
    );
  }
}

export default withStyles(productCyclePageStyle)(Overview);
