// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
} from "../views/material-dashboard-pro-react.jsx";

const footerStyle = {
  logoButton: {
    ...defaultFont,
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "500",
    color: "#fff"
  },
  logoButtonMobile: {
    ...defaultFont,
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "500",
    color: "#fff",
    display: "flex"
  },
  copyright: {
    margin: "0",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#646464",
    textAlign: "center",
    paddingBottom: "40px"
  },
  footer: {
    ...defaultFont,
    backgroundColor: "#141414",
    paddingTop: "40px",
    paddingBottom: "40px",
    zIndex: 4,
    fontFamily: "Roboto,Arial,sans-serif"
  },
  footerHeader: {
    color: "#949494",
    fontSize: "18px",
    lineHeight: "40px",
    margin: "0",
    fontWeight: "600",
    fontFamily: "Roboto,Arial,sans-serif"
  },
  footerNavlink: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "40px",
    textDecoration: "none",
    whiteSpace: "nowrap",
    "&:hover,&:focus": {
       color: "#FFFFFF"
      }
  },
  footerFollowUs: {
    color: "#949494",
    fontSize: "18px",
    lineHeight: "40px",
    fontWeight: "400",
    fontFamily: "Roboto,Arial,sans-serif",
    textAlign:"center",
    marginBottom: "16px"
  },
  footerStoreImg: {
    padding: "0",
    width: " 150px",
    height: "350",
  },
  socialMediaIcons: {
    backgroundColor: "#fff",
    width: "30px",
    borderRadius: "3px",
    marginBottom: "25px",
    marginRight: "25px",
    marginLeft: "25px"
  },
  ul: {
    margin: "0",
    padding: "0",
    listStyle: "none"
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  containerMobile: {
    ...container,
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    zIndex: 3,
    position: "relative",
    backgroundColor: "#141414"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  copyrightLink: {
    color: "#FFFFFF",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: "#FFFFFF"
     }
  },
  footerMenuMobile: {
    width: "30%",
    minWidth: "30%",
    maxWidth: "30%",
    marginLeft: "4%",
    marginRight: "16%",
    marginTop: "5%"
  },
  footerStoreMobile: {
    margin: "30px auto", 
    textAlign: "center"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  }
};
export default footerStyle;
