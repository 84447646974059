import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// Mobile
import homePageStyle from "../../../assets/views/homePageStyle.jsx";

class WebAR extends React.Component {
  constructor(props) {
    super(props);

    this.nextPath = this.nextPath.bind(this);
  }

  componentDidMount() {
    // We've included a slightly modified version of A-Frame, which fixes some polish concerns
    const script1 = document.createElement("script");
    script1.src = 'https://cdn.8thwall.com/web/aframe/8frame-0.8.2.min.js';
    script1.async = true;
    document.body.appendChild(script1);

    // XR Extras - provides utilities like load screen, almost there, and error handling
    // See github.com/8thwall/web/xrextras
    const script2 = document.createElement("script");
    script2.src = 'https://cdn.8thwall.com/web/xrextras/xrextras.js';
    script2.async = true;
    document.body.appendChild(script2);

    // 8thWall Web - Replace the app key here with your own app key
    const script3 = document.createElement("script");
    script3.src = 'https://apps.8thwall.com/xrweb?appKey=xBDHas4kRatvwP40awgDVGQ9iiu9qyXBGQD4fxftKEXeeT3QvSlxsk3T6EJHmvg8JJw5j';
    script3.async = true;
    document.body.appendChild(script3);
  }

  nextPath(path) {
    this.props.history.push({
      pathname: path
      //state: {detail: packet}
    });
  }

  render() {
    // Example of image target and video using Aframe + 8thWall: 
    // https://github.com/8thwall/web/tree/master/examples/aframe/flyer

    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>

        <a-scene
    xrweb="disableWorldTracking: true"
    xrextras-gesture-detector
    xrextras-almost-there
    xrextras-loading
    xrextras-runtime-error>

    <a-assets>
      
      <a-asset-item id="jelly-glb" src="jellyfish-model.glb"></a-asset-item>
      <img id="jelly-thumb" src="targets/video-target.jpg" alt=""/>
      <video
        id="jelly-video"
        autoplay
        muted
        crossorigin="anonymous"
        loop="true"
        src="">
      </video>
    </a-assets>

    <a-camera
      position="0 4 10"
      raycaster="objects: .cantap"
      cursor="fuse: false; rayOrigin: mouse;">
    </a-camera>

    <a-light type="directional" intensity="0.5" position="1 1 1"></a-light>

    <a-light type="ambient" intensity="1"></a-light>

    <a-entity
      xrextras-named-image-target="name: clipthis"
      xrextras-play-video="video: #jelly-video; thumb: #jelly-thumb; canstop: true"
      geometry="primitive: plane; height: 1; width: 1;">
    </a-entity>

    <a-entity xrextras-named-image-target="name: model-target">
      
      <a-entity xrextras-one-finger-rotate gltf-model="#jelly-glb"></a-entity>
    </a-entity>

  </a-scene>
            </div>
        </div>
    );
  }
}

WebAR.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homePageStyle)(WebAR);
