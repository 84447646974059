import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MediaQuery from "react-responsive";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/AddCircle";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import CardBody from "../../../components/Card/CardBody";
import { Card } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

// AWS App Sync
import AWSAppSyncClient from "aws-appsync";
import awsmobile from "../../../aws-exports";
import gql from "graphql-tag";
import { Auth } from "aws-amplify";
import { getItems } from "../../../graphql/queries";
import {
  updateClipThisCampaignsDev,
  updateClipThisItem
} from "../../../graphql/mutations";

// Style
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";

class AddItemToCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      items: [],
      data: [],
      isModalOpen: false,
      clipThisItems: null,
      mobileOpen: false,
      miniActive: false
    };
    this.addItem = this.updateCampaignItemList.bind(this);
    this.editCampaign = this.editItemCampaign.bind(this);
    this.addItemandUpdateCampaign = this.addItemandUpdateCampaign.bind(this);
  }

  componentWillMount() {
    let currentItems = this.props.location.state.items;
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    try {
      this.setState({
        isLoading: true
      });
      client
        .query({
          query: gql(getItems),
          variables: { userId: this.props.userName }
        })
        .then(({ data: { getItems } }) => {
          const data = getItems.items
            .filter(item => !currentItems.includes(item.clipThisId))
            .map((prop, key) => {
              return {
                id: key,
                clipThisId: prop.clipThisId,
                name: prop.displayName,
                actions: (
                  // custom button actions
                  <div className="actions-right">
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        // Go to edit item page and feed obj data
                        this.addItemandUpdateCampaign(prop.clipThisId);
                      }}
                      color="warning"
                      className="edit"
                    >
                      <AddIcon />
                    </Button>{" "}
                  </div>
                )
              };
            });

          this.setState({
            data: data,
            isLoading: false
          });
        });
    } catch (e) {
      console.log("Error ", e);
    }
  }

  addItemandUpdateCampaign(clipThisId) {
    let items = this.props.location.state.items;
    items.push(clipThisId);

    for (var i = 0; i < items.length; i++) {
      this.editItemCampaign(items[i], items);
    }

    this.updateCampaignItemList(items);
  }

  async editItemCampaign(clipThisId, items) {
    let campaign = {};
    campaign["description"] = this.props.location.state.description;
    campaign["searchKeywords"] = this.props.location.state.searchKeywords;
    campaign["title"] = this.props.location.state.title;
    campaign["userDisplayName"] = this.props.location.state.userDisplayName;
    campaign["isPublic"] = this.props.location.state.isPublic;
    campaign["userId"] = this.props.location.state.userId;
    campaign["tags"] = this.props.location.state.tags;
    campaign["items"] = items;
    campaign = JSON.stringify(campaign);
    const userId = this.props.location.state.userId;
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    try {
      this.setState({
        isLoading: true
      });
      const edit = true;
      const result = await client.mutate({
        mutation: gql(updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            campaign,
            userId,
            edit
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async updateCampaignItemList(items) {
    const userId = this.props.location.state.userId;
    const title = this.props.location.state.title;

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    try {
      this.setState({
        isLoading: true
      });
      var updateClipThisCampaignsDevInput = {};
      updateClipThisCampaignsDevInput["userId"] = userId;
      updateClipThisCampaignsDevInput["title"] = title;
      updateClipThisCampaignsDevInput["items"] = items;
      client
        .mutate({
          mutation: gql(updateClipThisCampaignsDev),
          variables: {
            input: updateClipThisCampaignsDevInput
          }
        })
        .then(({ data: { updateClipThisCampaignsDev } }) => {
          alert("Inhalt erfolgreich zur Kampagne hinzugefügt.");

          this.props.history.push({
            pathname: "/pages/listcampaignitems",
            state: {
              userId: this.props.location.state.userId,
              items: items,
              title: title,
              description: this.props.location.state.description,
              searchKeywords: this.props.location.state.searchKeywords,
              userDisplayName: this.props.location.state.userDisplayName,
              isPublic: this.props.location.state.isPublic,
              tags: this.props.location.state.tags
            }
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    window.scrollTo(0, 0);
  }

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <GridContainer>
              <Sidebar />
            </GridContainer>
            <div className={classes.container}>
              <Card>
                <CardBody>
                  <GridContainer className={classes.listItemContainer}>
                    <div style={{ textAlign: "left" }}>
                      <h2>
                        Inhalt zu Kampagne "{this.props.location.state.title}"
                        hinzufügen
                      </h2>
                    </div>
                    <GridItem xs={12}>
                      <ReactTable
                        data={this.state.data}
                        noDataText={"No items found"}
                        columns={[
                          {
                            Header: "ItemName",
                            accessor: "name"
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false
                          }
                        ]}
                        defaultPageSize={30}
                        defaultSorted={[
                          {
                            id: "name",
                            desc: false
                          }
                        ]}
                        showPaginationBottom={true}
                        className={classes.table}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          </div>
        </MediaQuery>
        <MobileMessage />
      </div>
    );
  }
}

AddItemToCampaign.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(AddItemToCampaign);
