import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';
import MediaQuery from "react-responsive";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import BulletListIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import { Card } from "@material-ui/core";
import { ClipLoader } from 'react-spinners';

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import CardBody from "../../../components/Card/CardBody";
import GridItem from "../../../components/Grid/GridItem.jsx";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";

// AWS App Sync
import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import { Auth } from "aws-amplify";

import {getCampaigns} from '../../../graphql/queries'
import {deleteClipThisCampaignsDev} from "../../../graphql/mutations";

class ClipThisItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      items: [],
      data: [],
      isModalOpen: false
    };
    this.loadCampaigns = this.loadCampaigns.bind(this);
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    window.scrollTo(0, 0)
  }

  componentWillMount() {
    this.setState({isLoading: true});
    this.loadCampaigns();
  };

  refresh() {
    this.setState({isLoading: true});
    this.loadCampaigns();
  };

  awsClient = new AWSAppSyncClient({
    url: awsmobile.aws_appsync_graphqlEndpoint,
    region: awsmobile.aws_appsync_region,
    auth: {
      type: awsmobile.aws_appsync_authenticationType,
      apiKey: awsmobile.aws_appsync_apiKey,
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
  });

  async loadCampaigns() {
    this.awsClient.query({
        query: gql(getCampaigns),
        variables: {userId: this.props.userName}
      })
      .then(({ data: { getCampaigns } }) => {  
        const data = getCampaigns.items.map((prop, key) => {
          // Make string from tag array
          let tagString = [];
          for (var i = 0; i < prop.tags.length; i++) {
            tagString.push(prop.tags[i]+" ")
          }
    
          return {
            title: prop.title,
            owner: prop.userDisplayName,
            description: prop.description,
            tags: tagString,
            actions: (
              // custom button actions
              <div className="actions-right">            
                {/* use this button to add a edit kind of action */}
                <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.props.history.push({
                      pathname: "/pages/editcampaign",
                      state:{
                        title: prop.title,
                        description: prop.description,
                        userId: prop.userId,
                        isPublic: prop.isPublic,
                        items: prop.items,
                        tags: prop.tags,
                        userDisplayName: prop.userDisplayName
                        }
                      })
                    }}
                  color="warning"
                  className="edit"
                  >
                  <Edit />
                </Button>{" "}
                {/* use this button to list all items */}
                <Button justIcon round simple onClick={() => {
                  this.props.history.push({
                    pathname: "/pages/listcampaignitems",
                    state:{
                      userId: prop.userId,
                      title: prop.title,
                      items: prop.items,
                      description: prop.description,
                      searchKeywords: prop.searchKeywords,
                      userDisplayName: prop.userDisplayName,
                      isPublic: prop.isPublic,
                      tags: prop.tags
                      }
                    })
                  }}
                  color="danger"
                  className="remove"
                >
                  <BulletListIcon />
                </Button>{" "}
                {/* use this button to list all items */}
                <Button justIcon round simple onClick={() => {
                  this.props.history.push({
                    pathname: "/pages/additemtocampaign",
                    state:{
                      userId: prop.userId,
                      title: prop.title,
                      items: prop.items,
                      description: prop.description,
                      searchKeywords: prop.searchKeywords,
                      userDisplayName: prop.userDisplayName,
                      isPublic: prop.isPublic,
                      tags: prop.tags
                      }
                    })
                  }}
                  color="danger"
                  className="remove"
                >
                  <AddIcon />
                </Button>{" "}
                <Button justIcon round simple 
                  onClick={() => { this.handleDeletion(prop) }}
                  color="danger"
                  className="remove">
                  <DeleteIcon />
                </Button>
              </div>
            )
          };
        })
        this.setState({
          data: data,
          isLoading: false });
      });
  }

  handleDeletion(campaignData) {
    const confirmed = window.confirm(
      "Sind Sie sich sicher, dass Sie diese Kampagne löschen möchten?"
    );
    if (!confirmed) {
      return false;
    }

    // Don't allow deletion of campaigns which have items
    if (campaignData.items != null 
      && campaignData.items.length > 0) {
        alert(
          "Die Kampagne enthält Inhalte. Bitte entfernen Sie zuerst die Inhalte aus der Kampagne. You could do this in the campaign's contents list under the list icon."
        );
        return;
    }

    // Delete the campaign from the local list
    const data = this.state.data.filter(c => c.title !== campaignData.title
       && c.userId !== campaignData.userId)
    this.setState({
      data: data,
      isLoading: false
    })

    // Delete the campaign in the backend
    var input = {
      "userId": campaignData["userId"],
      "title": campaignData["title"]
    };
    try {
      this.awsClient.mutate({
        mutation: gql(deleteClipThisCampaignsDev),
        variables: {
          input: input
        }
      }).then((response) => {
        console.log(response);
        if (response["data"]["deleteClipThisCampaignsDev"] == null) {
          this.recoverFromError();
        } else {
          // Success.
        }
      });
    } catch (e) {
      this.recoverFromError();
    }
  }

  recoverFromError() {
    alert('Etwas ist schief gegangen. Bitte versuchen Sie es erneut');
    this.refresh();
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
        <GridContainer>
          <Sidebar />
        </GridContainer>
        <div className={classes.container}>
          <Card>
            <CardBody>
            <div className={classes.spinner}>
            <ClipLoader
            className={classes.spinner}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={this.state.isLoading}
            /></div>
              <GridContainer className={classes.listItemContainer}>
                <GridItem xs={11}>
                  <h2>Kampagnen</h2>
                </GridItem>
                <GridItem xs={1}>
                <div style={{algin:"right"}}>
                  <Button justIcon round simple onClick={() => {
                    this.refresh()
                    }}
                  color="primary"
                  className="remove"
                >
                  <RefreshIcon />
                </Button>
                </div>
                </GridItem>
                <GridItem xs={12}>
                <ReactTable
                  data={this.state.data}
                  noDataText={"Sie haben noch keine Kampagnen erstellt."}
                  columns={[
                    {
                      Header: "Kampagnen Name",
                      accessor: "title"
                    },
                    {
                      Header: "Besitzer",
                      accessor: "owner"
                    },
                    {
                      Header: "Beschreibung",
                      accessor: "description"
                    },
                    {
                      Header: "Tags",
                      accessor: 'tags'
                    },
                    {
                      Header: "Aktionen",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultSorted={[
                    {
                      id: "title",
                      desc: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom={true}
                  className={classes.table}
                />
                </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </div>
        </MediaQuery>
        <MobileMessage/>
        </div>
      );
  }
}

ClipThisItems.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(ClipThisItems)
