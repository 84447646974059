// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    // uncomment to connect to development API
    // "aws_appsync_graphqlEndpoint": "https://rsgeyc7vebdo3fgubjnhusl6du.appsync-api.eu-central-1.amazonaws.com/graphql”,
    "aws_appsync_graphqlEndpoint": "https://km4kvmpe2bcerestkk4pnw3mru.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    // uncomment to connect to development API
    // "aws_appsync_apiKey": "da2-b5g4ciqfffablfz3pevl36bqyq",
    "aws_appsync_apiKey": "da2-rtdheii3z5cchjqvgfx6a3nueq",
};

export default awsmobile;