import {
    container,
    defaultFont,
    cardTitle,
    colors
  } from "./material-dashboard-pro-react.jsx";
  
  const weddingPageStyle = {
    container,
    content: {
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    },
    title: {
      ...defaultFont,
      color: "#FFFFFF",
      marginTop: "13vh",
      marginBottom: "30px",
      textAlign: "center"
    },
    titleMobile:{
      //fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "1.33",
      color: "#000",
      marginTop: "24px",
      marginBottom: "20px",
      textAlign: "left",
    },
    description: {
      fontSize: "18px",
      color: "#FFFFFF",
      textAlign: "center"
    },
    descriptionMobile: {
      fontSize: "16px",
      color: "#3A4649",
    },
    iconBulletMobile: {
      fontSize: "12px",
      color: "#3A4649",
      verticalAlign: "middle"
    },
    bulletsMobile: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "1.33"
    },
    buttonMobile: {
      marginTop:"20px",
      backgroundColor: colors.primaryButtonColor
    },
    buttonDescriptionMobile: {
      fontSize: "16px",
      color: "#fff",
      fontWeight:"400"
    },
    quote: {
      paddingTop: "5px",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      color: "#000",
      textAlign: "center",
      marginTop: "30px",
      marginBottom: "30px"
    },
    cardTitle,
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    cardTitleWhite: {
      ...cardTitle,
      color: "#FFFFFF !important"
    },
    cardCategory: {
      color: "#999999",
      marginTop: "10px",
      fontSize: "16px"
    },
    cardCategoryWhite: {
      color: "#FFFFFF",
      marginTop: "10px"
    },
    cardMobile: {
      marginBottom: "0px "
    },
    icon: {
      color: "#333333",
      margin: "10px auto 0",
      width: "130px",
      height: "130px",
      border: "1px solid #E5E5E5",
      borderRadius: "50%",
      lineHeight: "174px",
      "& svg": {
        width: "55px",
        height: "55px"
      }
    },
    iconTitle:{
      color: "#FFFFFF",
      textAlign: "center",
      fontSize: "20px"

    },
    iconWhite: {
      color: "#FFFFFF"
    },
    iconRose: {
      color: colors.roseColor
    },
    marginTop30: {
      marginTop: "30px"
    },
    label: {
      cursor: "pointer",
      paddingLeft: "0",
      color: "rgba(0, 0, 0, 0.26)",
      fontSize: "12px",
      lineHeight: "1.428571429",
      fontWeight: "400",
      display: "inline-flex"
    },
    labelHorizontal: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "24px",
      lineHeight: "1.33",
      color: "#fff",
      marginTop: "24px",
      marginBottom: "20px",
      textAlign: "left",
      }

};

export default weddingPageStyle;