import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

// aws immport
import { randomString } from "../../../libs/randomString";
//import s3Upload from "../../libs/awsLib"
import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Image, FormGroup, FormControl } from "react-bootstrap";
import { Player } from "video-react";
import { Rnd } from "react-rnd"; // Move image libary
import "../../../containers/DragQRCode.css"; // Needed for the libary used to make the qr code dragable
import isUrl from "is-url";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";
import qrcode from "../../../assets/image/qrcode.png";
import TextField from "@material-ui/core/TextField";

import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";
import { Card } from "@material-ui/core";
import { ClipLoader } from "react-spinners";

import awsmobile from "../../../aws-exports";
import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import * as mutations from "../../../graphql/mutations";
import { getItems } from "../../../graphql/queries";

import config from "./../../../config.js";
import userRights from "./user_rights.json";
import userWL from "./user_whitelabel.json";

class NewItem extends React.Component {
  constructor(props) {
    super(props);

    this.fileVideo = null;
    this.fileImage = null;

    this.state = {
      isLoading: false, // Showing rotating loading spinner
      imageWidth: 401, //1604,
      imageHeight: 250, //902,
      qrSize: 0,
      qrposx: 0,
      qrposy: 0,
      videoWidth: 852,
      videoHeight: 0,
      videoPoster: null, // Image in video player
      videoFrameHeight: 0,
      contentCreationAllowed: true,

      // Database entries
      videoFile: null, //props.getStore().videofile,
      imageFile: null, //props.getStore().imagefile,
      videoChanged: false,
      imageChanged: false,
      qrposxpercentage: 0,
      qrposypercentage: 0,

      email: this.props.email,
      clipThisId: null,
      userId: this.props.userName,
      views: 0,
      actionData: null,
      actionType: null,
      actionName: "Erfahren Sie mehr",
      displayName: "Test Item",
      premium: false,
      active: true,
      datalaws: false,
      description: "No description available",
      createdAt: Date.now(),
      updatedAt: null,
      edit: false,
      maxViews: config.DEMO_NUMBER_OF_VIEWS,
      webapp: "clipthis",

      publishInApps: false,
      publishInWebAR: false
    };

    this.nextPath = this.nextPath.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkIfCanCreateContents();
    this.updateDefaultNumberOfViews();
  }

  updateDefaultNumberOfViews() {
    const email = this.props.email;
    const numOfViews = userRights["number_of_views"][email];
    if (numOfViews !== undefined) {
      this.setState({
        maxViews: numOfViews
      });
    }
  }

  checkIfCanCreateContents() {
    // Query items of current user and check if more are allowed.
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    this.setState({
      isLoading: true
    });

    client
      .query({
        query: gql(getItems),
        variables: { userId: this.props.userName }
      })
      .then(({ data: { getItems } }) => {
        var isAllowed = true;
        const email = this.props.email;
        if (userRights["number_of_contents"][email] !== undefined) {
          const max = userRights["number_of_contents"][email];
          const n = getItems.items.length;
          isAllowed = n < max;
        }

        this.setState({
          isLoading: false,
          contentCreationAllowed: isAllowed
        });
      });
  }

  handleNameChange = name => event => {
    if (event.target.value.length > 25) {
      alert("Maximale Länge ist 25 Zeichen.");
      const name = this.state.displayName;
      this.setState({
        displayName: name.substr(0, 25)
      });
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleCheckboxChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });
  };

  handleAmountChange = name => event => {
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  handleChargedCheckboxChange = name => event => {
    let checked = event.target.checked;
    if (checked) {
      this.showChargesConfirmationAlert()
    } 
    this.setState({ [name]: checked });
  }

  showChargesConfirmationAlert = () => {
    alert("Diese Einstellung verursacht Kosten in Abhängigkeit von Ihrem Vertrag mit der AI Simplicity GmbH. Wir werden Ihren Fall prüfen und Sie bei Bedarf kontaktieren. Mit dem Ankreuzen dieses Kontrollkästchens bestätigen Sie, dass Ihnen die möglichen Gebühren bekannt sind.");
  }

  validateForm() {
    return this.state.datalaws;
  }

  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("video");
    video.onloadeddata = function(e) {
      var canvas = document.createElement("canvas");
      var w = video.videoWidth;
      var h = video.videoHeight;
      canvas.height = h;
      canvas.width = w;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback({ width: w, height: h, image: img, error: e });
    };
    video.onerror = function(e) {
      callback.call(undefined, undefined, undefined, e);
    };
    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }

  // Called when user chooses video. Sets new video, triggers getting of width and height.
  handleVideoFileChange = event => {
    this.fileVideo = event.target.files[0];
    if (this.fileVideo.type !== "video/mp4") {
      alert(`Bitte wählen Sie ein Video im Format .mp4 aus.`);
      return;
    }

    if (this.fileVideo && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Die Größe ihres Videos sollte ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB nicht überschreiten.`
      );
      return;
    }

    let url = URL.createObjectURL(this.fileVideo);
    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null,
      videoFrameHeight: 250
    });

    // callback function after video is changed
    this.getVideoInfos(url, result => {
      this.setState({ 
        videoWidth: result.width,
        videoHeight: result.height
      });
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w = img.naturalWidth || img.width;
      var h = img.naturalHeight || img.height;
      callback({ width: w, height: h });
    };
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  }

  // Called when user changes new image. Triggers width, height extraction
  // Triggers quality check of image


  getMaxVideoSizeMb() {
    return config.MAX_ATTACHMENT_SIZE / 1000000
  } 

  handleImageFileChange = event => {
    this.fileImage = event.target.files[0];
    if (
      this.fileImage.type !== "image/jpeg" &&
      this.fileImage.type !== "image/png"
    ) {
      alert(`Bitte wählen sie eines der folgenden Formate: .png oder .jpeg.`);
      return;
    }

    if (this.fileImage && this.fileImage.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Die Größe ihres Bildes sollte ${this.getMaxVideoSizeMb()} MB nicht überschreiten.`
      );
      return;
    }

    let url = URL.createObjectURL(this.fileImage);
    this.setState({
      imageFile: url,
      imageChanged: true,
      qrposx: 0,
      qrposy: 0
    });

    // Callback function after Image is changed
    this.getImageWidthHeight(url, result => {
      var width = 0;
      var height = 0;
      var qrSize = 0;
      // Height > Width
      if (result.width <= result.height) {
        // Check if height is bigger than screen
        if (result.height > 1200) {
          height = 1200;
          width = (height / result.height) * result.width;
        } else {
          width = result.width;
          height = result.height;
        }
        qrSize = 0.2 * width;
      }
      // Width > Height
      else {
        // Check if width is bigger than screen
        if (result.width > 600) {
          width = 600;
          height = (width / result.width) * result.height;
        } else {
          width = result.width;
          height = result.height;
        }
        qrSize = 0.2 * height;
      }
      this.setState({
        qrSize: qrSize,
        imageWidth: width,
        imageHeight: height
      });
    });
    alert('Klicken Sie auf den QR-Code und verschieben Sie ihn an die gewünschte Stelle, wenn Sie einen QR-basierten Inhalt erstellen.');
  };

  nextPath(path) {
    this.props.history.push({
      pathname: path
    });
  }

  handleSubmit = async ({ token, error }) => {
    const valid = this.validateForm();
    if (!valid) {
      return;
    }

    var aspectRatioImage =
      Math.round((this.state.imageWidth / this.state.imageHeight) * 10) / 10;
    const tollerance = 0.2;
    const aspectRatioVideo =
      Math.round((this.state.videoWidth / this.state.videoHeight) * 10) / 10;
    if (
      aspectRatioImage - aspectRatioVideo > tollerance ||
      aspectRatioVideo - aspectRatioImage > tollerance
    ) {
      alert(
        "Fehler: Seitenverhältnisse von Bild und Video stimmen nicht überein."
      );
      return;
    }

    this.setState({ 
      isLoading: true
    });

    // If a collision in clipThisId happends, 
    // dynamoDB will not insert the entry.
    // Therefore, no need to check for collisions.
    let clipThisId = randomString(7);

    try {
      let {
        email, // this email of the target reciever, not of the user
        views,
        actionData,
        actionType,
        actionName,
        displayName,
        premium,
        active,
        qrposxpercentage,
        qrposypercentage,
        description,
        createdAt,
        maxViews,
        webapp,
        publishInApps,
        publishInWebAR
      } = this.state;
      const qrposx = qrposxpercentage;
      const qrposy = qrposypercentage;
      const analytics = JSON.stringify({});
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;

      let now = Date.now();
      let updatedAt = {};
      updatedAt["file"] = now;
      updatedAt["videoFile"] = now;
      updatedAt = JSON.stringify(updatedAt);

      // properties store email of the current user
      if (this.props.email in userWL) 
        webapp = userWL[this.props.email];

      // File Upload
      const bucket = config.s3.ATTACHMENTS.BUCKET;
      const region = config.s3.REGION;
      const visibility = config.s3.ATTACHMENTS.LEVEL;
      const selectedFile = this.fileImage;
      const selectedVideo = this.fileVideo;

      let file;
      let videoFile;

      if (selectedFile) {
        const { name: fileName, type: mimeType } = selectedFile;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${"."}${extension}`;
        file = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedFile
        };
      }

      if (selectedVideo) {
        const { name: fileName, type: mimeType } = selectedVideo;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${"."}${extension}`;
        videoFile = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedVideo
        };
      }

      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey
        },
        complexObjectsCredentials: () => Auth.currentCredentials()
      });

      if (actionData != null && actionData !== "") {
        if (!isUrl(actionData)) {
          this.setState({ isLoading: false });
          alert("Bitte geben Sie eine gültige URL ein.");
          return;
        } else {
          actionType = "link";
        }
      } else {
        actionData = null;
        actionType = null;
      }

      if (displayName === "") {
        this.setState({ isLoading: false });
        alert("Bitte geben Sie einen Namen an.");
        return;
      }

      if (email === "") {
        this.setState({ isLoading: false });
        alert("Bitte geben Sie eine Email Adresse an.");
        return;
      }

      // Sends us emails about publish requests through a lambda.
      var publishingTypes = []
      if (publishInApps) {
        publishingTypes.push("APPS")
      }
      if (publishInWebAR) {
        publishingTypes.push("WEBAR")
      }
      for (var i = 0; i < publishingTypes.length; i++) {
        await client.mutate({
          mutation:gql(mutations.requestPublishingItem),
          variables: {
            clipThisId: clipThisId,
            publishingType: publishingTypes[i]
          }
        })
      }

      // Item's imageFileLocation, videoFileLocation are updated in the lambda
      //  function which moves original files to the distribution buckets.
      await client.mutate({
        mutation: gql(mutations.createClipThisItem),
        variables: {
          input: {
            clipThisId,
            userId,
            email,
            maxViews: maxViews ? maxViews : config.DEMO_NUMBER_OF_VIEWS,
            views: views ? 0 : 0,
            analytics: analytics ? JSON.stringify({}) : null,
            actionData,
            actionType,
            actionName,
            displayName,
            premium,
            active,
            qrposx,
            qrposy,
            file,
            videoFile,
            description,
            createdAt,
            updatedAt,
            edit: false,
            webapp: webapp,
            webarStatus: publishInWebAR ? 'REQUESTED' : null,
            appsStatus: publishInApps ? 'REQUESTED' : null
          }
        }
      });

      alert(
        "Herzlichen Glückwunsch, Ihr Inhalt wurde erstellt. Ordnen Sie ihn jetzt einer Kampagne zu."
      );
      // Redirect to the list of items
      this.nextPath("/pages/listCampaigns");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;

    if (!this.state.contentCreationAllowed)
      return (
        <div>
          <MediaQuery minWidth={1224}>
            <div className={classes.content}>
              <GridContainer>
                <Sidebar />
              </GridContainer>
              <div className={classes.spinner}>
                <ClipLoader
                  className={classes.spinner}
                  sizeUnit={"px"}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.isLoading}
                />
              </div>
              <div className={classes.container}>
                <Card>
                  <CardBody>
                    <GridContainer justify="center">
                      Sie nutzen alle für Sie verfügbaren Channels. Bitte
                      kontaktieren Sie uns, um mehr Channels hinzuzufügen.
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            </div>
          </MediaQuery>
          <MobileMessage />
        </div>
      );

    const canChangePremium = userRights["make_premium"].includes(this.props.email)
    const canEditViews = userRights["change_views"].includes(this.props.email)

    // Content creation allowed
    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <GridContainer>
              <Sidebar />
            </GridContainer>
            <div className={classes.spinner}>
              <ClipLoader
                className={classes.spinner}
                sizeUnit={"px"}
                size={150}
                color={"#123abc"}
                loading={this.state.isLoading}
              />
            </div>
            <div className={classes.container}>
              <Card>
                <CardBody>
                <GridContainer justify="center">
                  <h2 >Erstellung eines neuen Inhalts</h2>
                  </GridContainer>
                </CardBody>
              </Card>
              <br/>
              <Card>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={11}>
                      <FormGroup>
                        <Player
                          src={this.state.videoFile}
                          fluid={false}
                          height={this.state.videoFrameHeight}
                          poster={null}
                        />
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={5} sm={5}></GridItem>
                    <GridItem xs={2} sm={2}>
                      <input
                        name="isGoing"
                        onChange={this.handleVideoFileChange}
                        type="file"
                        className={classes.customFileInputInvisible}
                        id="videoInput"
                      />
                      <label
                        htmlFor="videoInput"
                        className={classes.customFileInputStyle}
                      >
                        Video auswählen*
                      </label>
                    </GridItem>
                    <GridItem xs={5} sm={5}></GridItem>
                    <h5 className={classes.infoText}>
                      Video sollte .mp4 sein und sollte die Größe von{" "}
                      {this.getMaxVideoSizeMb()} MB nicht überschreiten.
                    </h5>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={11}>
                      <FormGroup>
                        <Image
                          style={{
                            width: this.state.imageWidth,
                            heigth: this.state.imageHeight
                          }}
                          className={"Image"}
                          src={this.state.imageFile}
                        />
                        <Rnd
                          position={{
                            x: this.state.qrposx,
                            y: this.state.qrposy
                          }}
                          size={{
                            width: this.state.qrSize,
                            height: this.state.qrSize
                          }}
                          bounds={".Image"}
                          onDragStop={(e, d) => {
                            this.setState({
                              qrposx: d.x,
                              qrposy: d.y,
                              qrposxpercentage:
                                100 *
                                (d.x /
                                  (this.state.imageWidth - this.state.qrSize)), // Relative width of image
                              qrposypercentage:
                                100 *
                                (d.y /
                                  (this.state.imageHeight - this.state.qrSize))
                            });
                          }}
                        >
                          <img
                            style={{
                              height: this.state.qrSize,
                              width: this.state.qrSize
                            }}
                            src={qrcode}
                            alt="QR Code Position"
                            title="QR Code Position"
                            className={classes.dragme}
                          />
                        </Rnd>
                      </FormGroup>
                    </GridItem>
                    <GridItem xs={5} sm={5}></GridItem>
                    <GridItem xs={2} sm={2}>
                      <input
                        onChange={this.handleImageFileChange}
                        type="file"
                        className={classes.customFileInputInvisible}
                        id="imageInput"
                      />
                      <label
                        htmlFor="imageInput"
                        className={classes.customFileInputStyle}
                      >
                        Bild auswählen*
                      </label>
                    </GridItem>
                    <GridItem xs={5} sm={5}></GridItem>
                    <h5>
                      Bild sollte entweder .jpg, oder .png sein. Die
                      Seitenverhältnisse von Bild und Video sollten
                      übereinstimmen.
                    </h5>
                  </GridContainer>

                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>Name des Inhalts*</h4>
                      <h5 className={classes.infoText}> Max 25 Zeichen</h5>
                    </GridItem>
                    <GridItem xs={12}>
                      <BasicCustomInput
                        labelText=""
                        id="displayname"
                        formControlProps={{
                          fullWidth: true
                        }}
                        onChange={this.handleNameChange("displayName")}
                        value={this.state.displayName}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>
                        Emailadresse für den Empfang des physischen Markers,
                        inkl. QR-Code*
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <BasicCustomInput
                        labelText=""
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        onChange={this.handleChange("email")}
                        value={this.state.email}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>Weiterleitungslink</h4>
                      <h5 className={classes.infoText}>
                        Aktiviert den "Erfahren Sie mehr Button" in der App bei
                        Aufruf des Inhalts und verweist bei Klick auf diese
                        Seite
                      </h5>
                    </GridItem>
                    <GridItem xs={12}>
                      <BasicCustomInput
                        labelText=""
                        id="actionData"
                        formControlProps={{
                          fullWidth: true
                        }}
                        onChange={this.handleChange("actionData")}
                        value={this.state.actionData}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>Beschreibung</h4>
                      <h5>Wird in der Galerie in Kampagnendetails angezeigt.</h5>
                    </GridItem>
                    <GridItem xs={12}>
                      <textarea
                        style={{
                          backgroundColor: "white",
                          width: "100%",
                          border: "#cccccc 1px solid",
                          borderRadius: "4px",
                          padding: "0.429em 0.857em"
                        }}
                        data-limit-rows={true}
                        rows={5}
                        onChange={this.handleChange("description")}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>
                        <br />
                        Maximale Anzahl von Aufrufen
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <h4>{this.state.maxViews}</h4>
                    </GridItem>
                    {canEditViews ? (
                      <GridItem xs={12}>
                        <br />
                        <h4 className={classes.infoText}>
                          Maximale Anzahl von Aufrufen ändern
                        </h4>
                        <TextField
                          id="maxViews"
                          label=""
                          value={this.state.maxViews}
                          onChange={this.handleChange("maxViews")}
                          type="number"
                          className={classes.textField}
                          margin="normal"
                        />
                      </GridItem>
                    ) : null}
                    {canChangePremium ? (
                      <GridItem xs={12}>
                        <br />
                        <h4 className={classes.infoText}>Premium machen</h4>
                        <h5 className={classes.infoText}>
                          Premium-Bilder benötigen keinen QR-Code und werden
                          beim Start der App automatisch geladen.
                        </h5>
                        <FormControl
                          type="checkbox"
                          value={this.state.premium}
                          onChange={this.handleCheckboxChoice("premium")}
                          placeholder=""
                        />
                      </GridItem>
                    ) : null}
                    {!canEditViews ? (
                      <GridItem xs={12}>
                        <br />
                        <h4 className={classes.infoText}>In Apps veröffentlichen</h4>
                        <h5 className={classes.infoText}>
                          Erhöht die Anzahl der maximalen Ansichten in Apps für den Inhalt. Ausgeführt innerhalb von 24 Stunden.
                        </h5>
                        <h5>
                          Verursacht Kosten in Abhängigkeit von Ihrem Vertrag.
                        </h5>
                        <FormControl
                          type="checkbox"
                          value={this.state.publishInApps}
                          onChange={this.handleChargedCheckboxChange("publishInApps")}
                          placeholder=""
                        />
                      </GridItem>
                    ) : null}
                    <GridItem xs={12}>
                        <br />
                        <h4 className={classes.infoText}>In WebAR veröffentlichen</h4>
                        <h5 className={classes.infoText}>
                          Ausgeführt innerhalb von 24 Stunden. 
                        </h5>
                        <h5>
                          Verursacht Kosten in Abhängigkeit von Ihrem Vertrag.
                        </h5>
                        <FormControl
                          type="checkbox"
                          value={this.state.publishInWebAR}
                          onChange={this.handleChargedCheckboxChange("publishInWebAR")}
                          placeholder=""
                        />
                    </GridItem>
                    <GridItem xs={12}>
                      <br/>
                      <h4 className={classes.infoText}>
                        Bitte bestätigen Sie unsere
                        <a href="pages/datenschutz" target="_blank">
                          {" "}Datenschutzregeln*
                        </a>
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl
                        type="checkbox"
                        value={this.state.datalaws}
                        onChange={this.handleCheckboxChoice("datalaws")}
                        placeholder=""
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <br/>
                      <Button
                        round
                        color="primary"
                        disabled={!this.validateForm()}
                        onClick={this.handleSubmit}>
                          Erstellen
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          </div>
        </MediaQuery>
        <MobileMessage />
      </div>
    );
  }
}

NewItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(privateUploadPageStyle)(NewItem);
