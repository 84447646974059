import React from "react";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';
import { API } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Divider from '@material-ui/core/Divider';

// Material Icons

import invitationGIF from '../../../assets/image/wedding/InvitationCardGIF.gif';
import thankyouGIF from '../../../assets/image/wedding/ThankYouGIF.gif';
import bookGIF from '../../../assets/image/wedding/WeddinBookGIF.gif';
import weddingImage from '../../../assets/image/wedding/wedding.jpg';
import hand from '../../../assets/image/wedding/hand.png';

// Contact
import LoaderButton from "../../../components/LoaderButton.js";
import FormLabel from "@material-ui/core/FormLabel";
import Textfield from "@material-ui/core/TextField";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";


// Mobile
import {Button as ReactButton} from "@material-ui/core"

import weddingPageStyle from "../../../assets/views/weddingPageStyle.jsx";

class Wedding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      firmenname: "empty",
      firmenwebsite: "empty",
      ansprechperson: "",
      email: "",
      telefonnummer:"",
      branche: "",
      anliegen:""
    };
    // we use this to make the card to appear after the page has been rendered
    
    this.nextPath = this.nextPath.bind(this);
    this.holdirdieapp = this.holdirdieapp.bind(this);
    this.contactRequest = this.contactRequest.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  holdirdieapp(){

  }

  validateForm() {
    return(
      this.state.email.length > 0,
      this.state.anliegen.length > 0,
      this.state.telefonnummer.length > 0,
      this.state.ansprechperson.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleToggle(value) {
    const branchen = ["Branche1", "Branche2"];
    this.setState({
      branche: branchen.indexOf(value)
    });
  }

  // API post to create new item
  contactRequest(item){

    return API.post("clpthis", "/clpthis-contact", {
      body: item
      });
  } 

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
    // Wait until new database entry is created successfully
    await this.contactRequest({
      firmenname: this.state.firmenname,
      firmenwebsite: this.state.firmenwebsite,
      ansprechperson: this.state.ansprechperson,
      email: this.state.email,
      telefonnummer: this.state.telefonnummer,
      branche: "not defined",//this.state.branche,
      anliegen: this.state.anliegen      
      });
    
      alert("Vielen Dank für Ihre Nachricht. Wir melden uns in der Regeln in 1-2 Tagen bei Ihnen.")
    // Redirect to payment
    this.props.history.push("/");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };


  nextPath(path) {

    this.props.history.push({
      pathname: path
      //state: {detail: packet}
      });
  }
      
  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div style={{backgroundColor:"#333333"}}>
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>CLIPTHIS Wedding</h2>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}><img src={hand} alt="CLIPTHIS" style={{width:"50%"}}/></GridItem>
              <GridItem xs={12} sm={12} md={6}><img src={weddingImage} alt="CLIPTHIS" style={{width:"100%"}}/></GridItem>
          </GridContainer>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.quote}>Unser Angebot</h2>
              </GridItem>
            </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={4} md={4} align="center">
                  <img src={bookGIF} alt="CLIPTHIS" style={{width:"50%"}}/>
                  <h2 className={classes.quote}>
                  Hochzeitsbücher
                  </h2>               
                  <Divider style={{width:"50%"}}/>
                  <h5 className={classes.quote}>Halte deine schönsten Erinnerungen nicht nur durch Fotos fest – erwecke sie mit CLIPTHIS zum Leben</h5>
                </GridItem>
                <GridItem xs={12} sm={4} md={4} align="center">
                    <img src={invitationGIF} alt="CLIPTHIS" style={{width:"50%"}}/>
                    <h2 className={classes.quote}>
                    Save the Date
                  </h2>               
                  <Divider style={{width:"50%"}}/>
                  <h5 className={classes.quote}>Der Termin und die Gäste stehen – verschicke personalisierte Einladungen und Save-the-Date Karten die sich von dem Standard abheben</h5>
                </GridItem>
                <GridItem xs={12} sm={4} md={4} align="center">
                  <img src={thankyouGIF} alt="CLIPTHIS" style={{width:"50%"}}/>
                  <h2 className={classes.quote}>
                    Danksagungen
                  </h2>               
                  <Divider style={{width:"50%"}}/>
                  <h5 className={classes.quote}>Bedanke dich bei deinen Gästen mit dem schönsten Moment der Hochzeit – etwas an das sich jeder gerne erinnert </h5>
                </GridItem>
              </GridContainer>
              <br/>
            </CardBody>
          </Card>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Kontaktieren Sie uns</h2>
            </GridItem>
            <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                (Firmenname)
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
                <CustomInput
                id="firmenname"
                value={this.state.firmenname}
                onChange={this.handleChange}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text"
                }}
                />
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                (Firmenwebsite)
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
                <CustomInput
                id="firmenwebsite"
                value={this.state.firmenwebsite}
                onChange={this.handleChange}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text"
                }}
                />
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                Name*
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
                <CustomInput
                id="ansprechperson"
                value={this.state.ansprechperson}
                onChange={this.handleChange}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text"
                }}
                helpText="A block of help text that breaks onto a new line."
                />
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                Email Adresse*
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
                <CustomInput
                id="email"
                value={this.state.email}
                onChange={this.handleChange}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text"
                }}
                />
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                Telefonnummer*
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
                <CustomInput
                id="telefonnummer"
                value={this.state.telefonnummer}
                onChange={this.handleChange}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text"
                }}
                />
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                Anliegen*
                </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
                <Textfield
                id="anliegen"
                value={this.state.anliegen}
                onChange={this.handleChange}
                
                inputProps={{
                    type: "textfield"
                }}
                fullWidth
                multiline
                rows="15"
                />
            </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <LoaderButton
                  block
                  bsSize="large"
                  type="submit"
                  disabled={!this.validateForm()}
                  isLoading={this.state.isLoading}
                  text="Absenden"
                  loadingText="Sendet…"
                />
                <br/><br/><br/><br/><br/>
              </GridItem>
              </GridContainer>
          </GridContainer>
        </div>
      </div>
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div style={{backgroundImage:null, backgroundColor:"#F5F5F7"}}>
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.titleMobile}>Augmented Reality selber machen</h2>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={6} sm={4} align="center">
              <img src={invitationGIF} alt="CLIPTHIS" style={{width:"50%"}}/>

              </GridItem>
              <GridItem xs={6} sm={2} align="left">
                  <div className={classes.descriptionMobile}>CLIPTHIS erlaubt dir deine eigenen Augmented Reality Inhalte zu erstellen und mit deinen Freunden zu teilen.</div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12}>
              <a href="https://itunes.apple.com/us/app/clipthis/id1427352327?ls=1&mt=8"> 
              <ReactButton fullWidth variant="contained" className={classes.buttonMobile}>
                <div className={classes.buttonDescriptionMobile}>Hol dir die App</div>
                </ReactButton></a>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>AR selber machen</h2>
                <p>Lade mit ein paar Klicks dein eigenes Video und Bild hoch. Ein paar Minuten später sind deine Inhalte in der App abrufbar.</p>
              </GridItem>
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>Statistik Feedback </h2>
                <p>Wie viele Personen haben deine Bilder angeschaut? Verfolge den Erfolg deiner Inhalt live auf deinem Statistik Dashboard.</p>
              </GridItem>
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>Druck und Versand</h2>
                <p>Gestalte deine eigene Weihnachtskarte. Wir versenden diese automatisch an die Adressen deiner Wahl.</p>
              </GridItem>     
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <img src={bookGIF} alt="CLIPTHIS"/>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </MediaQuery>
  </div>
    );
  }
}

Wedding.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(weddingPageStyle)(Wedding);
