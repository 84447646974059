import React, {Component} from 'react';
import PropTypes from "prop-types";

import { Elements, StripeProvider } from "react-stripe-elements";
import withStyles from "@material-ui/core/styles/withStyles";
import PaymentForm from '../../../components/CheckoutForm/PaymentForm.jsx';
import { API } from "aws-amplify";

import loginPageStyle from "../../../assets/views/loginPageStyle.jsx";

import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";


class Payment extends Component {
  constructor(props) {
    super(props);

    this.amount = 0;
    
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  billUser(details) {
    return API.post("clpthis", "/billing", {
      body: details
    });
  }



  handleFormSubmit = async ({ token, error }) => {
    if (error) {
      alert(error);
      return;
    }
  
    this.setState({ isLoading: true });
    try {
      await this.billUser({
        amount: this.amount,
        source: token.id,
      });
  
      alert("Your card has been charged successfully!");
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { classes } = this.props;
    this.amount = this.props.location.state.amount * 100;
    console.log("Amount ", this.amount);
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
                <Card>
                  <CardBody>
                    <StripeProvider apiKey="pk_test_UMGfFUYWBhT4jHDIN1xsNjmk">
                      <Elements>
                        <PaymentForm
                          loading={this.state.isLoading}
                          onSubmit={this.handleFormSubmit}
                        />
                      </Elements>
                    </StripeProvider>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
    );
  }
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired
};

export default  withStyles(loginPageStyle)(Payment);
