import {
    container,
    cardTitle
  } from "./material-dashboard-pro-react.jsx";
  
  const agbdatenschutzPageStyle = {
    container,
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    },
    
    textCenter: {
      textAlign: "center"
    },
    content: {
      paddingTop: "18vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    }
}

export default agbdatenschutzPageStyle;
