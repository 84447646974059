import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Clear";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DownIcon from "@material-ui/icons/ArrowDownward";
import ImageIcon from "@material-ui/icons/Image";
import VideoIcon from "@material-ui/icons/PlayCircleFilled";
import AddIcon from "@material-ui/icons/AddCircle";
import { Card } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem.jsx";
import { Player } from "video-react";
import Modal from "@material-ui/core/Modal";
import { ClipLoader } from "react-spinners";

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import CardBody from "../../../components/Card/CardBody";
import ItemsTable from "./ItemsTable.jsx";

// AWS App Sync
import * as mutations from "../../../graphql/mutations";
import AWSAppSyncClient from "aws-appsync";
import awsmobile from "../../../aws-exports";
import gql from "graphql-tag";
import { Storage, Auth } from "aws-amplify";
import { batchGetItems } from "../../../graphql/queries";

import userRights from "./user_rights.json";
import config from "./../../../config.js";
import {getWebarStateName, getAppsStateName} from "../../../utils/helpers.js";

class ClipThisItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      items: this.props.location.state.items,
      data: [],
      isImageModalOpen: false,
      isVideoModalOpen: false,
      clipThisItems: null,
      mobileOpen: false,
      miniActive: false,
      imageUrl: null,
      videoUrl: null,
      videoFile: null,
      image: null,
      imageHeight: 200,
      imageWidth: 200,
      videoHeight: 200,
      videoWidth: 200
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    window.scrollTo(0, 0);
    this.setState({ isLoading: false });

    if (this.props.location.state.items.length === 0) {
      return;
    }

    // Query using a parameter
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    var clipThisIds = {};
    clipThisIds["strings"] = this.props.location.state.items;
    client
      .query({
        query: gql(batchGetItems),
        variables: { clipThisIds: clipThisIds }
      })
      .then(({ data: { batchGetItems } }) => {
        const data = batchGetItems.map((prop, key) => {
          return {
            id: key,
            clipThisId: prop.clipThisId,
            userId: prop.userId,
            name: prop.displayName,
            views: prop.views + ' / ' + prop.maxViews,
            webarStatus: getWebarStateName(prop),
            appsStatus: getAppsStateName(prop),
            actions: (
              // custom button actions
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    this.handleQRDownload(prop.clipThisId);
                  }}
                  color="info"
                  className="edit"
                >
                  <DownIcon />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    this.handleVideoModal(prop.videoFileLocation);
                  }}
                  color="info"
                  className="edit"
                >
                  <VideoIcon />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    this.handleImageModal(prop.imageFileLocation);
                  }}
                  color="info"
                  className="edit">
                  <ImageIcon />
                </Button>{" "}
                {userRights["show_analytics"].indexOf(prop.email) >= 0 ? (
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      // Go to edit item page and feed obj data
                      this.props.history.push({
                        pathname: "/pages/itemdashboard",
                        state: {
                          displayName: prop.displayName,
                          analytics: prop.analytics
                        }
                      });
                    }}
                    color="info"
                    className="like"
                  >
                    <DashboardIcon />
                  </Button>
                ) : null }
                <Button
                  justIcon
                  round
                  simple
                  onClick={()=>{ this.handleEdit(prop) }}
                  color="warning"
                  className="edit">
                  <Edit />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={()=>{ this.handleDelete(key) }}
                  color="danger"
                  className="remove">
                  <RemoveIcon />
                </Button>{" "}
              </div>
            )
          };
        });
        this.setState({ data });
      });
  }

  handleEdit = prop => {
    // Go to edit item page and feed obj data
    this.props.history.push({
      pathname: "/edititem",
      state: {
        clipThisId: prop.clipThisId,
        maxViews: prop.maxViews,
        views: prop.views,
        analytics: prop.analytics,
        actionData: prop.actionData,
        actionType: prop.link,
        actionName: prop.actionName,
        displayName: prop.displayName,
        active: prop.active,
        premium: prop.premium,
        email: prop.email,
        qrposx: prop.qrposx,
        qrposy: prop.qrposy,
        createdAt: prop.createdAt,
        description: prop.description,
        webarStatus: prop.webarStatus,
        appsStatus: prop.appsStatus,
        updatedAt: prop.updatedAt
      }
    });
  }

  handleDelete = key => {
    var data = this.state.data;
    var items = this.state.items;
    data.find((o, i) => {
      if (o.id === key) {
        // here you should add some custom code so you can delete the data
        // from this component and from your server as well
        let obj = data.find(o => o.id === key);
        let clipThisId = obj.clipThisId;

        const confirmed = window.confirm(
          "Diesen Inhalt aus der Kampagne entfernen?"
        );
        if (!confirmed) {
          return false;
        }
        
        // delete from server
        try {
          this.removeItem(clipThisId);
        } catch (e) {
          alert(e);
          return false;
        }
        // delete from data
        data.splice(i, 1);
        items = items.filter(id => id !== clipThisId)
        return true;
      }
      return false;
    });
    this.setState({ 
      items: items,
      data: data,
      isLoading: false 
    });
  
    // Delete item from the location state.
    // Otherwise the deleted item will be shown on page reload.
    const { state } = this.props.location;
    state.items = items;
    this.props.history.replace({ state: state });
  }

  handleQRDownload = async clipThisId => {
    const bucket = config.s3.QR_CODES.BUCKET;
    const region = config.s3.QR_CODES.BUCKET;
    const level = config.s3.QR_CODES.LEVEL;
    const qrUrl = await Storage.get(clipThisId + ".jpg", {
      bucket,
      region,
      level
    });

    setTimeout(() => {
      const response = {
        file: qrUrl
      };
      // now, let's download:
      window.open(response.file);
      // window.location.href = response.file;
    }, 100);
  };

  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      var w = img.naturalWidth || img.width;
      var h = img.naturalHeight || img.height;
      callback({ width: w, height: h });
    };
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  }

  handleImageModal = async imageLocation => {
    var infos = JSON.parse(imageLocation);

    const bucket = config.s3.ANALYTICS.BUCKET;
    const region = config.s3.REGION;
    const level = config.s3.ANALYTICS.LEVEL;
    let imageKey = infos["key"];
    imageKey = imageKey.split("/")[1];

    try {
      const imageUrl = await Storage.get(imageKey, { bucket, region, level });

      this.setState({
        image: imageUrl
      });

      this.getImageWidthHeight(imageUrl, result => {
        var width = 0;
        var height = 0;
        // Height > Width
        if (result.width <= result.height) {
          // Check if height is bigger than screen
          if (result.height > 600) {
            height = 600;
            width = (height / result.height) * result.width;
          } else {
            width = result.width;
            height = result.height;
          }
        }
        // Width > Height
        else {
          // Check if width is bigger than screen
          if (result.width > 600) {
            width = 600;
            height = (width / result.width) * result.height;
          } else {
            width = result.width;
            height = result.height;
          }
        }
        this.setState({
          imageWidth: width,
          imageHeight: height
        });
      });
    } catch {
      console.log("Image Error");
    }

    this.handleImageOpen();
  };

  handleVideoModal = async videoLocation => {
    var infos = JSON.parse(videoLocation);

    const bucket = config.s3.ANALYTICS.BUCKET;
    const region = config.s3.REGION;
    const level = config.s3.ANALYTICS.LEVEL;
    const videoKey = infos["key"];

    try {
      const videoUrl = await Storage.vault.get(videoKey, {
        bucket,
        region,
        level
      });
      this.setState({
        videoFile: videoUrl
      });
      // callback function after Video is changed
      this.getVideoWidthHeight(videoUrl, result => {
        this.setState({ videoWidth: result.width });
        this.setState({ videoHeight: result.height });
      });
    } catch {
      console.log("Video Error");
    }

    this.handleVideoOpen();
  };

  getVideoWidthHeight(url, callback) {
    var vid = document.createElement("VIDEO");
    vid.onloadedmetadata = function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w = vid.videoWidth;
      var h = vid.videoHeight;
      callback({ width: w, height: h });
    };
    // Setting the source makes it start downloading and eventually call `onload`
    vid.src = url;
  }

  handleImageOpen = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleVideoOpen = () => {
    this.setState({ isVideoModalOpen: true });
  };

  handleClose = () => {
    this.setState({
      isImageModalOpen: false,
      isVideoModalOpen: false
    });
  };

  async updateCampaign(title, userId, items) {
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    const result = await client.mutate({
      mutation: gql(mutations.updateClipThisCampaignsDev),
      variables: {
        input: {
          title,
          userId,
          items
        }
      }
    });
  }

  async cleanCampaign(clipThisId) {
    let campaign = {};
    campaign = JSON.stringify(campaign);
    const userId = this.props.location.state.userId;

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    try {
      this.setState({
        isLoading: true
      });
      const edit = true;
      const result = await client.mutate({
        mutation: gql(mutations.updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            campaign,
            userId,
            edit
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async editCampaignAttribute(clipThisId, items) {
    let campaign = {};
    campaign["description"] = this.props.location.state.description;
    campaign["searchKeywords"] = this.props.location.state.searchKeywords;
    campaign["title"] = this.props.location.state.title;
    campaign["userDisplayName"] = this.props.location.state.userDisplayName;
    campaign["isPublic"] = this.props.location.state.isPublic;
    campaign["userId"] = this.props.location.state.userId;
    campaign["tags"] = this.props.location.state.tags;
    campaign["items"] = items;
    campaign = JSON.stringify(campaign);
    const userId = this.props.location.state.userId;

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey
      },
      complexObjectsCredentials: () => Auth.currentCredentials()
    });

    try {
      this.setState({
        isLoading: true
      });
      const edit = true;
      const result = await client.mutate({
        mutation: gql(mutations.updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            campaign,
            userId,
            edit
          }
        }
      });
      this.setState({
        isLoading: false
      });
    } catch (e) {
      console.log(e);
    }
  }

  removeItem(clipThisId) {
    try {
      // Get campaign details
      let old_items = this.props.location.state.items;
      let new_items = old_items.filter(function(value, index, arr) {
        return value !== clipThisId;
      });

      let title = this.props.location.state.title;
      let userId = this.props.location.state.userId;

      // Update campaign in campaign table
      this.updateCampaign(title, userId, new_items);

      // Update campaign attribute in the items
      // which remained in the campaign
      for (var i = 0; i < new_items.length; i++) {
        this.editCampaignAttribute(new_items[i], new_items);
      }

      // Reset campaign attribute in the item 
      // which was deleted from the campaign
      this.cleanCampaign(clipThisId);
    } catch {
      console.log("Failed to remove item from campaign!");
    }
  }

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <GridContainer>
          <Sidebar />
        </GridContainer>
        <div className={classes.container}>
          <Card>
            <CardBody>
              <div className={classes.spinner}>
                <ClipLoader
                  className={classes.spinner}
                  sizeUnit={"px"}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.isLoading}
                />
              </div>
              <GridContainer className={classes.listItemContainer}>
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={this.state.isImageModalOpen}
                  onClose={this.handleClose}
                  style={{
                    paddingLeft:
                      window.innerWidth / 2 - this.state.imageWidth / 2 + "px",
                    paddingTop:
                      window.innerHeight / 2 - this.state.imageHeight / 2 + "px"
                  }}
                >
                  <img
                    src={this.state.image}
                    style={{
                      width: this.state.imageWidth,
                      heigth: this.state.imageHeight
                    }}
                  />
                </Modal>
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={this.state.isVideoModalOpen}
                  onClose={this.handleClose}
                  style={{
                    paddingLeft:
                      window.innerWidth / 2 - this.state.videoWidth / 2 + "px",
                    paddingTop:
                      window.innerHeight / 2 - this.state.videoHeight / 2 + "px"
                  }}
                >
                  <Player
                    src={this.state.videoFile}
                    fluid={false}
                    height={250}
                    poster={null}
                  />
                </Modal>
                <GridItem xs={11}>
                  <div style={{ textAlign: "left" }}>
                    <h2>
                      Inhalte der Kampagne "{this.props.location.state.title}"
                    </h2>
                  </div>
                </GridItem>
                <GridItem>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/pages/additemtocampaign",
                          state: {
                            userId: this.props.location.state.userId,
                            title: this.props.location.state.title,
                            items: this.props.location.state.items,
                            description: this.props.location.state.description,
                            searchKeywords: this.props.location.state
                              .searchKeywords,
                            userDisplayName: this.props.location.state
                              .userDisplayName,
                            isPublic: this.props.location.state.isPublic,
                            tags: this.props.location.state.tags
                          }
                        });
                      }}
                      color="danger"
                      className="remove"
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <ItemsTable 
                    data={this.state.data}
                    placeholderText="Die Kampagne hat noch keine Inhalte." />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

ClipThisItems.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(ClipThisItems);
