import React, { Component } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/PlayCircleFilled';
import Dashboard from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import ExerciseIcon from "@material-ui/icons/AccessibilityNew";
import AddAlertIcon from "@material-ui/icons/AddAlert";

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import { Card } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";

// AWS App Sync
import { graphql, compose } from 'react-apollo'
import DeleteItem from "../../../mutations/DeleteItem"
import ListItemsPerUser from '../../../queries/ListItemsPerUser'

import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import * as queries from "../../../graphql/queries";
import { Auth } from "aws-amplify";

import SendMessageForm from "./SendMessageForm.jsx"
import MessageList from "./MessageList.jsx"

// Style
import feedbackforpatientPageStyle from "../../../assets/views/feedbackforpatientPageStyle.jsx";

const DUMMY_DATA = [
    {
      senderId: "Herr Müller",
      text: "Hallo Andi, ich habe noch eine Frage zur Übung Double Leg Raise. Muss ich da den Bauch anspannen?"
    },
    {
      senderId: "Andi",
      text: "Hallo Herr Müller, ja! Unbedingt den Bauch anspannen."
    },
    {
      senderId: "Herr Müller",
      text: "Super danke! Wird gemacht."
    }
  ]

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: DUMMY_DATA
    };
  }

  async componentDidMount() {
  
    window.scrollTo(0, 0)
    this.setState({ isLoading: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <Card>
            <CardBody>
            <GridContainer justify="center">
                <GridItem xs={12} sm={11}>
                <h2>Unterhaltung mit Herr Müller</h2>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={11}>
                  <MessageList messages={this.state.messages} />
                  <SendMessageForm />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={1}>
                  <div><br/></div>
                  <div><br/></div>
                  <div><br/></div>
                  <div><br/></div>
                  <div><br/></div>

                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
       </div>
      </div>
      );
  }
}

Feedback.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(feedbackforpatientPageStyle)(Feedback);
