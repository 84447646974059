import React, { Component } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from '@material-ui/icons/Edit';
import Dashboard from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import ExerciseIcon from "@material-ui/icons/AccessibilityNew";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import AddIcon from "@material-ui/icons/ControlPoint"
import DeAddIcon from "@material-ui/icons/HighlightOff"


// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Table from "../../../components/Table/Table.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
//import Danger from "../../components/Typography/Danger.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";

// AWS App Sync
import { graphql, compose } from 'react-apollo'
import DeleteItem from "../../../mutations/DeleteItem"
import ListItemsPerUser from '../../../queries/ListItemsPerUser'

import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import * as queries from "../../../graphql/queries";
import { Auth } from "aws-amplify";

import Exercise from "./Exercise.jsx"

// Style
import listItemsPageStyle from "../../../assets/views/listExercisesPageStyle.jsx";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
},
});


class ListExercises extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",
      visibility: "private",

      isLoading: true,
      items: [],
      data: [],
      isModalOpen: false,
      clipThisItems: null,
    };
  }

  async componentDidMount() {
  
    window.scrollTo(0, 0)
    this.setState({ isLoading: false });

    const patientenData = [
      {
        clipThisId: 1,
        name: 'Superman statisch',
      },
      {
        clipThisId: 2,
        name: 'Superman dynamisch',
      },
      {
        clipThisId: 3,
        name: 'Squat',
      },
      {
        clipThisId: 4,
        name: 'Single Leg Raise',
      }      
      ]


    const data = patientenData.map((prop, key) => {
      return {
        id: key,
        clipThisId: prop.clipThisId,
        behandlung: prop.behandlung,
        name: prop.name,
        week: prop.woche,
        overview: prop.overview,
        exercises: prop.exercises,
        analytics: prop.analytics,
        actions: (
          // custom button actions
          <div className="actions-right">
            <Button justIcon round simple onClick={() => {
              // Go to edit item page and feed obj data
              this.props.history.push({
                pathname: "/physio/feedbackforpatient",
                state:{
                  displayName: prop.displayName,
                  analytics: prop.analytics}
                })
              }}
              color="warning"
              className="like"
              >
            <EditIcon />
            </Button>
            {" "}
            <Button justIcon round simple onClick={() => {
              // Go to edit item page and feed obj data
              this.props.history.push({
                pathname: "/physio/listexercises",
                state:{
                  displayName: prop.displayName,
                  analytics: prop.analytics}
                })
              }}
              color="danger"
              className="like"
              >
              <DeAddIcon />
            </Button>{" "}
            {/* use this button to remove the data row */}
          </div>
        )
      };
    })

    this.setState({ data });
  }

  render() {

    const name1 = 'Bridging Beine Anheben';
    const name2 = 'Bridging Statisch';
    const name3 = 'Double Leg Raise';
    const name4 = 'Extension Im Liegen';
    const name5 = 'Plank';
    const name6 = 'Schwimmen Auf Pezzi Ball';
    const item1 = "ohne Geräte";
    const item2 = "ohne Geräte";
    const item3 = "ohne Geräte";
    const item4 = "ohne Geräte";
    const item5 = "ohne Geräte";
    const item6 = "PezziBall";
    const bullet1 = "Liegen in der Rückenlage, mit angestellten Beinen. Das Becken wird abgehoben und dann abwechselnd die Beine angehoben.";
    const bullet2 = "Liegen in der Rückenlage, mit angestellten Beinen. Das Becken wird abgehoben und in der Position gehalten.";
    const bullet3 = "Rückenlage, beide Beine werden gerade nach oben genommen. Beine werden wieder zusammen in Richtung Boden gebracht, bis man den LWS- Bereich nicht mehr am Boden halten kann.";
    const bullet4 = "Rückenlage, beide Beine werden gerade nach oben genommen. Beine werden abwechselnd in Richtung Boden gebracht, ohne abzulegen.";
    const bullet5 = "Liegen in der Bauchlage. Mit den Armen den Körper vom Boden hochdrücken. Das Becken bleibt dabei liegen.";
    const bullet6 = "Gerade mit dem OK auf einem Pezziball liegen. Arme gerade aus nach vorne genommen. Arme bewegen sich in kurzer, schneller Frequenz nach oben und unten.";
    const image1 = 1;
    const image2 = 2;
    const image3 = 3;
    const image4 = 4;
    const image5 = 5;
    const image6 = 6;


    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <Card>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}>Übungen</h2>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridContainer xs={10}>
                  <GridItem xs={4}>
                    <Exercise 
                    name={name1}
                    item={item1}
                    image={image1}
                    bullets={bullet1}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Exercise 
                    name={name2}
                    item={item2}
                    image={image2}
                    bullets={bullet2}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Exercise 
                    name={name3}
                    item={item3}
                    image={image3}
                    bullets={bullet3}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Exercise 
                    name={name4}
                    item={item4}
                    image={image4}
                    bullets={bullet4}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Exercise 
                    name={name5}
                    item={item5}
                    image={image5}
                    bullets={bullet5}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <Exercise 
                    name={name6}
                    item={item6}
                    image={image6}
                    bullets={bullet6}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={2}>
                <GridItem>
                    <ReactTable
                        data={this.state.data}
                        noDataText={"No items found"}
                        columns={[
                          {
                            Header: "Übung Name",
                            accessor: "name"
                          },
                          {
                            Header: "Aktion",
                            accessor: "actions",
                            sortable: false,
                            filterable: false
                          }
                        ]}
                        defaultPageSize={10}
                        showPaginationBottom={false}
                        className={classes.table}
                      />
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      </div>
      );
  }
}

ListExercises.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(
  compose(
    graphql(ListItemsPerUser, {
      options: (props) => ({
        fetchPolicy: 'cache-and-network',
        variables: { userId: props.userName }
      }),
      props: props => ({
        clipThisItems: props.data.listClipThisItemsPerUser? props.data.listClipThisItemsPerUser.items : [],
        })
    }),
    graphql(DeleteItem, {
      props: props => ({
        onDelete: clipThisItem => props.mutate({
          variables: clipThisItem,
          optimisticResponse: {
            __typename: 'Mutation',
            deleteItem: { ...clipThisItem,  __typename: 'clipThisItem' }
          },
        })
      })
    })
  )(ListExercises)
)
