import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "../CustomButtons/Button.jsx";
import Card from "../Card/Card.jsx";

// aws immport
import { API } from "aws-amplify";
import { s3Upload } from "../../libs/awsLib";

import wizardStyle from "../../assets/components/wizardStyle.jsx";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.imageFile = null;
    this.videoFile = null;
    
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      },

      // Step 2
      imageChanged: false,
      videoChanged: false,
      qrposxpercentage: 0,
      qrposypercentage: 0,

      // Step 3
      fontSize: 12,
      checkedAnleitung: true,
      kartenText: "Der Weihnachtstisch ist öd und leer, die Kinder schauen blöd daher",
      // Step 4
      tableData: [],
      amountCards:0,              // Number of cards to calculate price
      priceTotal: 0,              // Calculated Price
      priceSingle: 0,
      
      isLoading: false,
      name: this.props.name,
      email: this.props.email,
      date:"empty",
      product: "PrintAndSend",
    };

    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  // Next tab
  nextButtonClick() {
    if (this[this.props.steps[this.state.currentStep].stepId].isValidated()){

      if (this.state.currentStep === 1){
        // Update image and video file
        this.imageFile = this[this.props.steps[this.state.currentStep].stepId].imageFile;
        this.videoFile = this[this.props.steps[this.state.currentStep].stepId].videoFile;
        this.setState({
          imageChanged: this[this.props.steps[this.state.currentStep].stepId].state.imageChanged,
          videoChanged: this[this.props.steps[this.state.currentStep].stepId].state.videoChanged,
          qrposxpercentage: this[this.props.steps[this.state.currentStep].stepId].state.qrposxpercentage,
          qrposypercentage: this[this.props.steps[this.state.currentStep].stepId].state.qrposypercentage
        });
      }
      if (this.state.currentStep === 2){
        this.setState({
          fontSize: this[this.props.steps[this.state.currentStep].stepId].state.fontSize,
          checkedAnleitung: this[this.props.steps[this.state.currentStep].stepId].state.checkedAnleitung,
          kartenText: this[this.props.steps[this.state.currentStep].stepId].state.kartenText,
        });
      }
      if (this.state.currentStep === 3){
        this.setState({
          tableData: this[this.props.steps[this.state.currentStep].stepId].state.tableData,
          amountCards: this[this.props.steps[this.state.currentStep].stepId].state.amountCards,
          priceTotal: this[this.props.steps[this.state.currentStep].stepId].state.priceTotal,
          priceSingle: this[this.props.steps[this.state.currentStep].stepId].state.priceSingle,
        });
      }
      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }

  // Previous tab
  previousButtonClick() {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      this.setState({
        allStates: [
          ...this.state.allStates,
          {
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState()
          }
        ]
      });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }

  // API post to create new item
  createItem(item) {    
    return API.post("clpthis", "/clpthis", {
      body: item
    });
  } 

  // API to pay
  billUser(details) {
    return API.post("clpthis", "/billing", {
      body: details
    });
  }

  nextPath(path, product, preis, amount, preistotal) {

    this.props.history.push({
      pathname: path,
      state: {
        product: product,
        preis: preis,
        amount: amount,
        preistotal: preistotal,
        isLoading: false,
      }
      });
  }

  handleSubmit = async ({ token, error }) => {
    if (error) {
      alert(error);
      return;
    }
    

    // Activate loading spinner
    this.setState({ isLoading: false });

    this.setState({
      name: this[this.props.steps[this.state.currentStep].stepId].state.name,
      email: this[this.props.steps[this.state.currentStep].stepId].state.email,
      date: this[this.props.steps[this.state.currentStep].stepId].state.startDate,
    });

    try {
      var attachment = "original";
      if (this.state.videoChanged){
        // Wait until video is uploaded to S3
        attachment = this.fileVideo
          ? await s3Upload(this.fileVideo)
          : null;
      }
      var imageAttachment = "original";
      if (this.state.imageChanged){
        // Wait until image is uploaded to S3
        imageAttachment = this.fileImage
          ? await s3Upload(this.fileImage)
          : null;
      }
    
      await this.billUser({
        amount: this.state.priceTotal*100,
        source: token.id,
      });  
        
      // Wait until new database entry is created successfully
      await this.createItem({
        attachment: attachment,
        imageAttachment: imageAttachment,
        imageChanged: this.state.imageChanged,
        qrposxpercentage: this.state.qrposxpercentage,
        qrposypercentage: this.state.qrposypercentage,
        checkedAnleitung: this.state.checkedAnleitung,
        kartenText: this.state.kartenText,
        fontSize: this.state.fontSize,
        tableData: this.state.tableData,
        date: this.state.date,
        wunschLink: this.state.wunschLink,
        email: this.state.email,
        product: this.state.product,
        priceSingle: this.state.priceSingle,
        numberCards: this.state.amountCards,
        nameCustomer: this.state.name
      });
      
      // Go to Summary
      this.nextPath("/pages/confirmation", this.state.product, this.state.priceSingle, this.state.amountCards, this.state.priceTotal);
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  finishButtonClick() {

    this.setState({
      name: this[this.props.steps[this.state.currentStep].stepId].state.name,
      email: this[this.props.steps[this.state.currentStep].stepId].state.email,
      date: this[this.props.steps[this.state.currentStep].stepId].state.date,
    });

  }
  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.wizard.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  render() {
    const { classes, title, subtitle, color, steps } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.wizardContainer} ref="wizard">
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <div className={classes.stepsAnchor}>
                      {prop.stepName}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={node => (this[prop.stepId] = node)}
                    name = {this.props.name}
                    email = {this.props.email}
                    handleSubmit = {this.handleSubmit}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
            <div className={classes.right}>
              {this.state.nextButton ? (
                <Button
                  color="rose"
                  className={this.props.nextButtonClasses}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.props.nextButtonText}
                </Button>
              ) : null}
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
      <div className={classes.wizardContainerMobile} ref="wizard">
        <h2>{title}</h2>
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <div className={classes.stepsAnchor}>
                      {prop.stepName}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={node => (this[prop.stepId] = node)}
                    name = {this.props.name}
                    email = {this.props.email}
                    handleSubmit = {this.handleSubmit}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
            <div className={classes.right}>
              {this.state.nextButton ? (
                <Button
                  color="rose"
                  className={this.props.nextButtonClasses}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.props.nextButtonText}
                </Button>
              ) : null}
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
      </MediaQuery>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Weiter",
  finishButtonClasses: "",
  finishButtonText: "Bestellen"
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.func.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool,
  isLoading: PropTypes.bool,
  email: PropTypes.string,
  name: PropTypes.string,
  handleSubmit: PropTypes.func,
};

export default withStyles(wizardStyle)(Wizard);
