// ##############################
// // // LoginPage view styles
// #############################

import {
    container,
    colors,
    cardTitle
  } from "../views/material-dashboard-pro-react.jsx";
  
  const feedbackforpatientPageStyle = {
    container,
    listItemContainer: {
      position: "flex",
      // display: "webkit-box",
      // display: "ms-flexbox",
      flexDirection: "column",
      backgroundColor: "#fff",
      padding: "5px 7px",
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "3px"
    },
    content: {
      paddingTop: "18vh",
      paddingBottom: "9vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    },
    table:{
      textAlign: "center"
    },
    message: {
        margin: "15px 0"
    },

    messageChild1: {
      fontSize: "11px",
      color: "#000",
      opacity: "0.9",
      marginBottom: "6px"
    },
    
    messageChild2: {
      background: colors.primaryColor,
      color: "#000",
      display: "inline",
      padding: "4px 18px",
      borderRadius: "8px"
    },

    messageList: {
      boxSizing: "borderBox",
      padding: "0 0 0 6px",
      margin: "0",
      width: "100%",
      height: "100%",
      overflow: "scroll",
      background: "white"
    },

    sendMessageForm: {
        background: "var(--send-message-form)",
        display: "flex",
        boxSizing: "border-box",
        height: "60px",

        input: {
          width: "100%",
          padding: "15px 10px",
          border: "none",
          margin: "0",
          background: "var(--send-message-form)",
          fontWeight: "200",
        
          focus:{
            outlineWidth:"0"
          },
          placeholder:{
            color: "black"
          }
        }
      }
  };
  
  export default feedbackforpatientPageStyle;
  