import gql from 'graphql-tag'

export default gql`
  mutation deleteClipThisItem(
    $clipThisId: String!,
  ) {
    deleteClipThisItem(input: {
      clipThisId: $clipThisId
    }) {
      clipThisId
    }
  }
  `