/*const dev = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "clipthis-platformupload"
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://7jxpci4l4h.execute-api.eu-central-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_1OCQlwg9M",
    APP_CLIENT_ID: "66n03ofcdchqqg7ukgrdfdl6t1",
    IDENTITY_POOL_ID: "eu-central-1:2ce73a07-ec7d-4780-9ff3-60e1a29ca6d2"
  },

  STRIPE_KEY: "pk_test_UMGfFUYWBhT4jHDIN1xsNjmk"
};*/

const prod = {
  s3: {
    REGION: "eu-central-1",
    ATTACHMENTS: {
      BUCKET: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      LEVEL: "private"
    },
    ANALYTICS: {
      WEBAR: "clipthis-analytics-ui-ready-webar-data-prod",
      APPS: "clipthis-analytics-ui-ready-data-prod",
      LEVEL: "public"
    },
    QR_CODES: {
      BUCKET: "clipthis-qrcodes",
      LEVEL: "private"
    }
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_a0apQndvW",
    APP_CLIENT_ID: "34vbtmpu0r8lrikii9fpqrj9as",
    IDENTITY_POOL_ID: "eu-central-1:7411e523-a0c4-4fcf-8a68-187acced68e3"
  },
  social: {
    FB: "2158854881043345"
  }

};

const config = prod;
//const config = process.env.REACT_APP_STAGE === 'prod'
//  ? prod
//  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 30000000,
  DEMO_NUMBER_OF_VIEWS: 5,
  ...config
};
    
    