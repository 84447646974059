import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import React, { Component } from "react";
import Button from "../../../components/CustomButtons/Button.jsx";

import listItemsPageStyle from "../../../assets/views/feedbackforpatientPageStyle.jsx";


class SendMessageForm extends React.Component {
  
    constructor(props) {
      super(props);
  
      this.state = {
        message: "Nachricht..."
      };
    }
  
  
    render() {
      const { classes } = this.props;
  
      return (
        <form className={classes.sendMessageForm}>
          <input
            className={classes.sendMessageForm.input}
            onChange={this.handleChange}
            value={this.state.message}
            placeholder="Type your message and hit ENTER"
            type="text" />
            <Button 
              round 
              color="primary" 
              onClick={this.handleSubmit}
              disabled={!this.validateForm}>
              Senden
            </Button>
        </form>
      )
    }
  };

  SendMessageForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(SendMessageForm);
