import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "../../../components/CustomButtons/Button.jsx";

// @material-ui/icons
import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";

import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

import confirmationPageStyle from "../../../assets/views/confirmationPageStyle";


import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,
} from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
  EmailIcon,
} from 'react-share';

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  nextPath(path, packet) {

    this.props.history.push({
      pathname: path,
      state: {detail: packet}
      });
  }
  
  render() {
    const { classes } = this.props;
    const url = "http://clip-this.com";
    const title = "Check out CLIPTHIS";
    return (
      <div className={classes.content}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Bestätigung</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10}>
                    <h4>Herzlichen Glückwunsch! Ihre Bestellung war erfolgreich.</h4>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                  <h4>Was möchten Sie als nächstes unternehmen?</h4>
                  <div className={classes.infoArea}>
                    <div className={classes.iconWrapper + " " + classes["rose"]}>
                      <AddCircle className={classes.icon} />
                    </div>
                    <div className={classes.descriptionWrapper}>
                      <h4 className={classes.title}>Eine weitere Karte erstellen:</h4>
                      <Button round size="sm" color="rose" onClick={() => this.nextPath('/pages/produkte')}>
                      Karte erstellen
                      </Button>
                    </div>
                  </div>
                  <div className={classes.infoArea}>
                    <div className={classes.iconWrapper + " " + classes["rose"]}>
                      <Email className={classes.icon} />
                    </div>
                    <div className={classes.descriptionWrapper}>
                      <h4 className={classes.title}>Kontaktieren Sie uns für eine Zusammenarbeit:</h4>
                      <Button round size="sm" color="rose" onClick={() => this.nextPath('/pages/contact')}>
                      Kontaktieren Sie uns
                      </Button>
                    </div>
                  </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                 
                  <div className={classes.infoArea}>
                    <div className={classes.iconWrapper + " " + classes["rose"]}>
                      <Group className={classes.icon} />
                    </div>
                    <div className={classes.descriptionWrapper}>
                      <h4 className={classes.title}>Seite mit Freunden teilen</h4>
                      <div>
                      <FacebookShareButton url={url} title={title}><FacebookIcon size={32} round={true}/></FacebookShareButton>
                      <TwitterShareButton url={url} title={title}><TwitterIcon size={32} round={true}/></TwitterShareButton>
                      <WhatsappShareButton url={url} title={title}><WhatsappIcon size={32} round={true}/></WhatsappShareButton>
                      <PinterestShareButton url={url} title={title}><PinterestIcon size={32} round={true}/></PinterestShareButton>
                      <EmailShareButton url={url} title={title}><EmailIcon size={32} round={true}/></EmailShareButton>
                      </div>
                    </div>
                  </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      </div>
    );
  }
}

ConfirmationPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(confirmationPageStyle)(ConfirmationPage);
