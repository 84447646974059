// ##############################
// // // ExtendedForms view styles
// #############################

import {
  container,
  defaultFont,
  cardTitle,
  colors
} from "./material-dashboard-pro-react.jsx";


const extendedFormsStyle = {
  container,
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4"
  },
  title: {
    ...defaultFont,
    color: "#FFFFFF",
    marginTop: "13vh",
    marginBottom: "30px",
    textAlign: "center"
  },
  description: {
    fontSize: "18px",
    color: "#FFFFFF",
    textAlign: "center"
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "5px",
    marginBottom: "0px",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF !important"
  },
  cardDescription: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "16px",
    fontColor: "#000"
  },
  cardExplanation: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "12px",
    fontColor: "#000"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  cardCategoryWhite: {
    color: "#FFFFFF",
    marginTop: "10px"
  },
  icon: {
    color: "rgba(255, 255, 255, 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  iconWhite: {
    color: "#FFFFFF"
  },
  iconRose: {
    color: colors.roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: "20px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  image:{
    position: "relative",
    height: "250px",
    paddingRight: "50",
    paddingBottom: "0px",
  },
  dragme:{
    position: "absolute",
    width: "50px",
    height: "50px",
    cursor: "move",
  },
  anleitungThumbnail: {
    position: "relative"
  },
  anleitungCaption: {
    position: "absolute",
    top: "10%",
    left: "5%",
    width: "60%",
    textAlign: "left", 
    fontSize: "28px"
  },

  anleitungBodytext: {
    position: "absolute",
    top: "20%",
    left: "5%",
    width: "100%",
    textAlign: "left",
    font:"arial"
  },

  anleitungEndtext: {
    position: "absolute",
    left: "5%",
    width: "60%",
    textAlign: "left",
    fontSize: "18px",
  },
  anleitungAdresse: {
    position: "absolute",
    top: "25%",
    left: "72%",
    width: "20%",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "1",
  },
  postcard: {
    position: "relative",
    width: "100%",
    boxShadow: "5px 5px 5px grey"
  },
  anleitung: {
    position: "absolute",
    bottom: "0%",
    left: "0%",
    width: "70%"
  },
  spinner: {
    
    display: "block",
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "5",
    margin: "0 auto"
  },
  selectButton:{
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: colors.grayColor,
    color: "#FFFFFF",
    boxShadow:
      "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "12px 30px",
    margin: ".3125rem 1px",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: "#FFFFFF",
      backgroundColor: colors.grayColor,
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    "& .fab,& .fas,& .far,& .fal": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle"
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle"
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal": {
        marginTop: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
      }
    }
  }
};

export default extendedFormsStyle;
