import React from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Textfield from "@material-ui/core/TextField";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardText from "../../../components/Card/CardText.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

import regularFormStyle from "../../../assets/views/regularFormsStyle";
import LoaderButton from "../../../components/LoaderButton.js";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      isLoading: false,
      firmenname: "empty",
      firmenwebsite: "empty",
      ansprechperson: "",
      email: "",
      telefonnummer:"",
      branche: "",
      anliegen:"",
      isAuthenticated:false
    };

    this.contactRequest = this.contactRequest.bind(this);
  }

  //componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    //setTimeout(
      //function() {
        //this.setState({ cardAnimaton: "" });
      //}.bind(this),
     // 700
    //);
  //}

  validateForm() {
    return(
      this.state.email.length > 0,
      this.state.anliegen.length > 0,
      this.state.telefonnummer.length > 0,
      this.state.ansprechperson.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleToggle(value) {
    const branchen = ["Branche1", "Branche2"];
    this.setState({
      branche: branchen.indexOf(value)
    });
  }

  async componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    try {
      if (await Auth.currentCredentials()) {
        this.setState({isAuthenticated:true});
        console.log("current Credentials worked")
      }
    }
    catch(e) {
      console.log("Did not work")
      if (e !== 'No current user') {
        console.log("No current user")
      }
    }
  }

  // API post to create new item
  contactRequest(item){

    return API.post("clpthis", "/clpthis-contact", {
      body: item
      });
  } 

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
    // Wait until new database entry is created successfully
    await this.contactRequest({
      firmenname: this.state.firmenname,
      firmenwebsite: this.state.firmenwebsite,
      ansprechperson: this.state.ansprechperson,
      email: this.state.email,
      telefonnummer: this.state.telefonnummer,
      branche: "not defined",//this.state.branche,
      anliegen: this.state.anliegen      
      });
    
      alert("Vielen Dank für Ihre Nachricht. Wir melden uns in der Regeln in 1-2 Tagen bei Ihnen.")
    // Redirect to payment
    this.props.history.push("/");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };
      
  render() {
    const { classes } = this.props;
    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
                <GridContainer className={classes.listItemContainer}>
                  <GridItem xs={8} sm={8} md={8}>
                    <Card className={classes[this.state.cardAnimaton]}>
                      <CardBody>
                      <h2>Kontaktformular</h2>
                      <h4 className={classes.infoText}>Sehr geehrte Damen und Herren,</h4>
                        <h4 className={classes.infoText}>bitte senden Sie uns Ihre Nachricht an:</h4>
                        <h4 className={classes.infoText}><a href="mailto:info@clip-this.com">info@clip-this.com</a></h4>
                        <h4 className={classes.infoText}></h4>
                        <h4 className={classes.infoText}>Wir kommen in den nächsten 2 Werktagen auf Sie zurück.</h4>
                        <h4 className={classes.infoText}>Mit freundlichen Grüssen,</h4>
                        <h4 className={classes.infoText}>CLIPTHIS Team</h4>
                          </CardBody>
                      </Card>
                      </GridItem>
                </GridContainer>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className={classes.contentMobile}>
            <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem xs={12}>
                    <h2 className={classes.titleMobile}>Kontaktformular</h2>
                      <h4 className={classes.infoText}>Sehr geehrte Damen und Herren,</h4>
                        <h4 className={classes.infoText}>bitte senden Sie uns Ihre Nachricht an:</h4>
                        <h4 className={classes.infoText}><a href="mailto:info@clip-this.com">info@clip-this.com</a></h4>
                        <h4 className={classes.infoText}></h4>
                        <h4 className={classes.infoText}>Wir kommen in den nächsten 2 Werktagen auf Sie zurück.</h4>
                        <h4 className={classes.infoText}>Mit freundlichen Grüssen,</h4>
                        <h4 className={classes.infoText}>CLIPTHIS Team</h4>
                  </GridItem>
                </GridContainer>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(regularFormStyle)(Contact);
