import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import NotFoundStyle from "../../../assets/views/NotFoundStyle";


class NotFound extends React.Component {
    
    componentDidMount() {
        window.scrollTo(0, 0)
      }

    render() {
        const { classes } = this.props;
        return(
            <div className={classes.NotFound}>
                <h3>Sorry, page not found!</h3>
            </div>
        )
    }
}

export default withStyles(NotFoundStyle)(NotFound);


