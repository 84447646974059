import React from "react";
import PropTypes from "prop-types";

// aws immport
import { randomString } from "../../../libs/randomString";
//import s3Upload from "../../libs/awsLib"
import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {Image, FormGroup, FormControl} from "react-bootstrap";
import { Player } from 'video-react';
import {Rnd} from "react-rnd";  // Move image libary
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import Button from "../../../components/CustomButtons/Button.jsx";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";

import qrcode from '../../../assets/image/qrcode.png'
import config from "../../../config";

import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";
import { Card } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import { ClipLoader } from 'react-spinners';

import awsmobile from '../../../aws-exports';
import AWSAppSyncClient from "aws-appsync";
import gql from 'graphql-tag'
import * as mutations from "../../../graphql/mutations"

class NewExercises extends React.Component {
  constructor(props) {
    super(props);

    this.fileVideo = null;
    this.fileImage = null;

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",

      // General paramters
      isLoading: false,           // Showing rotating loading spinner
      imageWidth: 401,//1604,
      imageHeight: 250,//902,
      qrSize:0,
      qrposx: 0,
      qrposy: 0,
      videoWidth: 852,
      videoHeight:0,
      videoPoster: null,    // Image in video player
      videoFrameHeight:0,

      // Database entries
      videoFile: null, //props.getStore().videofile,
      imageFile: null, //props.getStore().imagefile,
      videoChanged: false,
      imageChanged: true,
      qrposxpercentage: 0,
      qrposypercentage: 0,

      email: this.props.email,
      clipThisId: "",
      userId: this.props.userName,
      maxViews: 1000,
      views: 0,
      actionData: "http://clip-this.com",
      actionType: "link",
      actionName: "Erfahren Sie mehr",
      displayName: "Test Item",
      premium: false,
      active: true,
      description: "No description available",
      createdAt: Date.now(),
      updatedAt: null
    };

    this.nextPath = this.nextPath.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });
  };

  handleAmountChange = name => event => {
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  validateForm() {
    let valid = true;

    if (this.state.videoChanged){
      // Make sure video is mp4
      if (this.fileVideo.type !== "video/mp4") {
        alert(`Bitte wählen Sie ein Video mit dem Format .mp4.`);
        valid = false;
      }
      // Check video size
      if (this.fileVideo && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Die Größe ihres Videos sollte ${config.MAX_ATTACHMENT_SIZE/1000000} MB nicht überschreiten.`);
        valid = false;
      }
    }
    
    if (this.state.imageChanged){
      // Check image for format
      if (this.fileImage.type !== "image/jpeg" && this.fileImage.type !== "image/png") {
        alert(`Bitte wählen sie eines der folgenden Formate: .png oder .jpeg.`);
        valid = false;
      }

      var aspectRatioImage = Math.round(this.state.imageWidth / this.state.imageHeight * 10) / 10;

      if (aspectRatioImage < 1.6 || aspectRatioImage > 1.9) {
        alert(`Das Seitenverhältniss ihres Bildes entspricht nicht 16:9.`);
        valid = false;
      }
    }

    return valid;
  }


  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("VIDEO");

    video.onloadeddata = function(e) {
      var canvas = document.createElement('canvas');
      var w = video.videoWidth;
      var h = video.videoHeight;
      canvas.height = h;
      canvas.width = w;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback({width: w, height: h, image: img, error: e});
    };
    video.onerror = function(e) {
      callback.call(undefined, undefined, undefined, e);
    };
    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }
  
  // Called when user chooses video. Sets new video, triggers getting of width and height.
  handleVideoFileChange = event => {
    this.fileVideo = event.target.files[0];
    if (this.fileVideo.type !== "video/mp4") {
      alert(`Please pick an video with the format .mp4.`);
      return;
    }
    let url = URL.createObjectURL(this.fileVideo);
    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null,
      videoFrameHeight: 250
    });
  
    // callback function after video is changed
    this.getVideoInfos(url, (result) => {
      this.setState({videoWidth : result.width});
      this.setState({videoHeight : result.height});
      //this.setState({videoPoster: result.image.path});
      this.setState({videoWasChanged: true})
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };
  
  // Called when user changes new image. Triggers width, height extraction
  // Triggers quality check of image
  handleImageFileChange = event => {
    this.fileImage = event.target.files[0];
    let url = URL.createObjectURL(this.fileImage);
    this.setState({
      imageFile: url,
      imageChanged: true,
      qrposx: 0,
      qrposy:0
    });

    // Check Image Quality
    //this.f(this.fileImage).then(alert('Done'))

    // Callback function after Image is changed
    this.getImageWidthHeight(url, (result) => {
      var width = 0
      var height = 0
      var qrSize = 0
      // Height > Width
      if(result.width <= result.height){
        // Check if height is bigger than screen
        if(result.height>1200){
          height = 1200
          width = height / result.height * result.width
          }else{
            width = result.width
            height = result.height
          }
        qrSize = 0.2*width;
        }
      // Width > Height
      else{
        // Check if width is bigger than screen
        if(result.width>1000){
          console.log("width = 1000")
          width = 1000
          height = width / result.width * result.height
          }
        else{
          console.log("original data")
          width = result.width
          height = result.height
          }
        qrSize = 0.2*height;
        }
      this.setState({
        qrSize: qrSize,
        imageWidth : width,
        imageHeight: height,
      });
    });
  };

  nextPath(path) {
    this.props.history.push({
    pathname: path
    });
  }

  handleSubmit = async ({ token, error }) => {

    // Activate loading spinner
    this.setState({ isLoading: true });
    let clipThisId = ""

    if(this.state.link !== "empty"){
      clipThisId = "Link_" + randomString(7);      
    }
    else{
      clipThisId = randomString(7);
    }

    this.setState({clipThisId: clipThisId});
  
    try { 
      
      //// Add new item to database
      // Get item details
      const { email, maxViews, views, actionData, actionType, actionName, displayName, premium, active, qrposxpercentage, qrposypercentage, description, createdAt } = this.state;
      const qrposx = qrposxpercentage;
      const qrposy = qrposypercentage;
      const analytics = null;

      let now = Date.now().toString();
      const changeList = ["actionData", "actionName", "actionType", "active", "displayName", "file", "videoFile", "description"];
      let updatedAt = {};
      updatedAt[now] = changeList
      updatedAt = JSON.stringify(updatedAt);
      // File Upload
      const { bucket, region } = this.state;
      const visibility = 'private';
      const selectedFile = this.fileImage;
      const selectedVideo = this.fileVideo;
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;

      let file;
      let videoFile;

      if (selectedFile) {
        const { name: fileName, type: mimeType } = selectedFile;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        
        file = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedFile
        };
      }

      if (selectedVideo) {
        const { name: fileName, type: mimeType } = selectedVideo;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        
        videoFile = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedVideo
        };
      };
      
      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey,
        },
        complexObjectsCredentials: () => Auth.currentCredentials(),
      });

      const result = await client.mutate({
        mutation: gql(mutations.createClipThisItem),
        variables: {
          input: {
            clipThisId,
            userId,
            email,
            maxViews,
            views,
            analytics,
            actionData,
            actionType,
            actionName,
            displayName,
            premium,
            active,
            qrposx,
            qrposy,
            file: file,
            videoFile: videoFile,
            description: description,
            createdAt: createdAt,
            updatedAt: updatedAt
          }
        }
      });
            
      // Redirect to homepage
      this.nextPath("/");
      // alert("Item was added successfully!");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
      <div className={classes.spinner}>
        <ClipLoader
          className={classes.spinner}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.isLoading}
        /> </div>
        <div className={classes.container}>
        <Card>
        <CardBody>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <FormGroup >
              <Player  
                src={this.state.videoFile}
                fluid={false}
                height={this.state.videoFrameHeight}
                poster={null}/>
            </FormGroup>
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
          <GridItem xs={2} sm={2}>
            <input
              name="isGoing"
              onChange={this.handleVideoFileChange}
              type="file"
              className={classes.customFileInputInvisible}
              id="videoInput"
            />
            <label htmlFor="videoInput" className={classes.customFileInputStyle}>Choose Video</label>              
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <FormGroup>
              <Image style={{width: this.state.imageWidth, heigth: this.state.imageHeight}} className={"Image"} src={this.state.imageFile}/>
                <Rnd 
                  position={{x:this.state.qrposx, y: this.state.qrposy}}
                  size={{width:this.state.qrSize, height: this.state.qrSize}}
                  bounds={".Image"} 
                  onDragStop={(e, d) => { 
                    this.setState({
                      qrposx: d.x,
                      qrposy: d.y,
                      qrposxpercentage: 100*(d.x / (this.state.imageWidth-this.state.qrSize)), // Relative width of image
                      qrposypercentage: 100*(d.y / (this.state.imageHeight-this.state.qrSize))
                    })
                    console.log("x, y ", this.state.qrposxpercentage, " ", this.state.qrposypercentage)
                    }}>
                  <img style={{height:this.state.qrSize, width:this.state.qrSize}} src={qrcode} alt="QR Code Position"  title="QR Code Position"  className={classes.dragme}/>
                </Rnd>
              </FormGroup> 
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
          <GridItem xs={2} sm={2}>     
            <input
              onChange={this.handleImageFileChange}
              type="file"
              className={classes.customFileInputInvisible}
              id="imageInput"
            />
            <label htmlFor="imageInput" className={classes.customFileInputStyle}>Choose Image</label> 
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
        </GridContainer>

        <GridContainer justify="left">
          <GridItem xs={6}><h4 className={classes.infoText}>Displayed name of item</h4></GridItem>
          <GridItem xs ={6}>
            <BasicCustomInput
              labelText={this.state.displayName}
              id="displayname"
              formControlProps={{
                fullWidth: true
              }}
              onChange={this.handleChange("displayName")}
              value={this.state.displayName}
            />
          </GridItem>
          <GridItem xs={6}><h4 className={classes.infoText}>Email address to send confirmation email</h4></GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.email}
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("email")}
          value={this.state.email}
        />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Name of Action Button in App</h4>
          <p>For example: Visit Website / Find out more etc.</p>
        </GridItem>
        <GridItem xs={6}>
          <BasicCustomInput
            labelText={this.state.link}
            id="link"
            formControlProps={{
              fullWidth: true
            }}
            onChange={this.handleChange("link")}
            value={this.state.email}
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Link for "Visit Website" Button in the App</h4>
        </GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.link}
          id="link"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("link")}
          value={this.state.email}
        />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Maximal views of this item</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            type="number"
            value={this.state.maxViews}
            onChange={this.handleAmountChange("maxViews")}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Premium content</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            checked={this.state.premium}
            type="checkbox"
            value={this.state.premium}
            onChange={this.handleCheckboxChoice("premium")}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Content active</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            checked={this.state.active}
            type="checkbox"
            value={this.state.active}
            onChange={this.handleCheckboxChoice("active")}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Activation date</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            type="checkbox"
            value={this.state.numberOfCards}
            onChange={this.handleAmountChange}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Deactivation date</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
                type="checkbox"
                value={this.state.numberOfCards}
                onChange={this.handleAmountChange}
                placeholder=""
              />
        </GridItem>
        <GridItem>
          <Button 
            round 
            color="primary" 
            onClick={this.handleSubmit}
            disabled={!this.validateForm}>
            Submit
          </Button>
        </GridItem>
      </GridContainer>

        </CardBody>
        </Card>
      </div>
      </div>
    );
  }
}

NewExercises.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(privateUploadPageStyle)(NewExercises)