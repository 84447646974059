import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import MediaQuery from "react-responsive";
import logo from "../../assets/image/logo.png";
import appStore from "../../assets/image/appStore.png";
import playStore from "../../assets/image/playStore.png";
import fbLogo from "../../assets/image/fbLogo.png";
import instaLogo from "../../assets/image/instaLogo.jpeg";
import Button from "../CustomButtons/Button";
import { NavLink,  } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import footerStyle from "../../assets/components/footerStyle";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });

  return (
    <div>
    <MediaQuery minWidth={1224}>
    <footer className={classes.footer}>
      <div className={container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Button href="/" className={classes.logoButton} color="transparent">
              <img src={logo} alt="Logo" style={{width: "50px"}} hspace="20" /> CLIPTHIS
            </Button>
            <br/>
            <div>
            <a href="https://play.google.com/store/apps/details?id=clipthis.arapp" target="_blank" color="transparent">
              <img src={playStore} alt="playStore" className={classes.footerStoreImg}/>
            </a>

            <a href="https://itunes.apple.com/us/app/clipthis/id1427352327" target="_blank" color="transparent">
              <img src={appStore} alt="appStore" style={{padding: "8px"}}className={classes.footerStoreImg}/>
            </a>
            </div>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={6} style={{display: "flex"}}>
            <div style={{minWidth: "220px"}}>
              <h3 className={classes.footerHeader}>Company</h3>
              <ul className={classes.ul}>
                <li>
                  <NavLink to={"/"} className={classes.footerNavlink}>
                    Home
                  </NavLink>
                </li>
              </ul>
            </div>
            <div style={{minWidth: "220px"}}>
              <h3 className={classes.footerHeader}>Legal</h3>
              <ul className={classes.ul}>
                <li>
                  <NavLink to={"/pages/agb"} className={classes.footerNavlink}>
                    Privacy Terms
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/pages/datenschutz"} className={classes.footerNavlink}>
                    Data
                  </NavLink>
                </li>
              </ul>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.footerFollowUs}>
              Follow us
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{justifyContent: "center", display: "flex"}}>
            <div>
              <a href="https://www.facebook.com/clipthisar/" target="_blank">
                <img src={fbLogo} alt="fbLogo" className={classes.socialMediaIcons} hspace="20" />
              </a>
              <a href="https://www.instagram.com/clipthisar/" target="_blank" color="transparent">
                <img src={instaLogo} alt="instaLogo" className={classes.socialMediaIcons} hspace="20" />
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.copyright}>
              &copy; {1900 + new Date().getYear()}{" "}
              <a href="https://clip-this.com" target="_blank" className={classes.copyrightLink}>
                CLIPTHIS
              </a>
              - The augmented reality directly on your smartphone!
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
    </MediaQuery>

    <MediaQuery maxWidth={1224}>
      <footer>
      <div className={classes.containerMobile}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button href="/" className={classes.logoButtonMobile} color="transparent">
              <img src={logo} alt="Logo" style={{width: "50px", marginLeft: "0"}} hspace="20" /> CLIPTHIS
            </Button>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={12} style={{display: "flex"}}>
          <div className={classes.footerMenuMobile}>
            <h3 className={classes.footerHeader}>Company</h3>
            <ul className={classes.ul}>
              <li>
                <NavLink to={"/"} className={classes.footerNavlink}>
                  Home
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={classes.footerMenuMobile}>
            <h3 className={classes.footerHeader}>Legal</h3>
            <ul className={classes.ul}>
              <li>
                <NavLink to={"/pages/agb"} className={classes.footerNavlink}>
                  Privacy Terms
                </NavLink>
              </li>
              <li>
                <NavLink to={"/pages/datenschutz"} className={classes.footerNavlink}>
                  Data
                </NavLink>
              </li>
            </ul>
          </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.footerStoreMobile}>
              <a href="https://play.google.com/store/apps/details?id=clipthis.arapp" target="_blank" color="transparent">
                <img src={playStore} alt="playStore" className={classes.footerStoreImg}/>
              </a>

              <a href="https://itunes.apple.com/us/app/clipthis/id1427352327" target="_blank" color="transparent">
                <img src={appStore} alt="appStore" style={{padding: "8px"}}className={classes.footerStoreImg}/>
              </a>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.footerFollowUs}>
              Follow us
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{justifyContent: "center", display: "flex"}}>
            <div>
              <a href="https://www.facebook.com/clipthisar/" target="_blank">
                <img src={fbLogo} alt="fbLogo" className={classes.socialMediaIcons} hspace="20" />
              </a>
              <a href="https://www.instagram.com/clipthisar/" target="_blank" color="transparent">
                <img src={instaLogo} alt="instaLogo" className={classes.socialMediaIcons} hspace="20" />
              </a>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.copyright}>
              &copy; {1900 + new Date().getYear()}{" "}
              <a href="https://clip-this.com" target="_blank" className={classes.copyrightLink}>
                CLIPTHIS
              </a>
              - The augmented reality directly on your smartphone!
            </p>
          </GridItem>
        </GridContainer>
      </div>
      </footer>
    </MediaQuery>
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
