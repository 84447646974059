// ##############################
// // // LoginPage view styles
// #############################

import {
    container,
    cardTitle,
    colors
  } from "../views/material-dashboard-pro-react.jsx";
  
  const listExercisesPageStyle = {
    container,
    listItemContainer: {
      position: "flex",
      // display: "webkit-box",
      // display: "ms-flexbox",
      flexDirection: "column",
      backgroundColor: "#fff",
      padding: "5px 7px",
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "3px"
    },
    content: {
      paddingTop: "18vh",
      paddingBottom: "9vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4"
    },
    table:{
      textAlign: "center"
    },
    exerciseCard:{
        backgroundColor: colors.lightgrayColor
    }
    
    // cardTitle: {
    //   ...cardTitle,
    //   textAlign: "center"
    // },
    // cardLIst: {
    //   borderRadius: "6px",
    //   boxShadow:
    //     "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    //   marginBottom: "100px",
    //   padding: "40px 0px",
    //   marginTop: "15vh"
    // },
    // center: {
    //   textAlign: "center"
    // },
    // right: {
    //   textAlign: "right"
    // },
    // left: {
    //   textAlign: "left"
    // },
    // form: {
    //   padding: "0 20px",
    //   position: "relative"
    // },
    // socialTitle: {
    //   fontSize: "18px"
    // },
    // inputAdornment: {
    //   marginRight: "18px",
    //   position: "relative"
    // },
    // inputAdornmentIcon: {
    //   color: "#555"
    // },
    // customFormControlClasses: {
    //   margin: "0 12px"
    // },
    // checkboxLabelControl: {
    //   margin: "0"
    // },
    // checkboxLabel: {
    //   marginLeft: "6px",
    //   color: "rgba(0, 0, 0, 0.26)"
    // }
  };
  
  export default listExercisesPageStyle;
  