import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

import agbdatenschutzPageStyle from "../../../assets/views/agbdatenschutzStyle.jsx";

class AGB extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
      <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <h4 className={classes.cardTitle}>
                  Allgemeine Geschäftsbedingungen
                  </h4>
                </CardHeader>
                <CardBody>
                <h4 className={classes.cardTitle}>
                Übersicht über die Allgemeinen Geschäftsbedingungen
                  </h4>
                  1. Geltungsbereich 2. Vertragspartner 3. Angebot und Vertragsschluss 4. Widerrufsrecht 5. Preise und Versandkosten 6. Lieferung 7. Zahlung Weitere Informationen Bestellvorgang Vertragstext Allgemeine Geschäftsbedingungen (Die nachstehenden AGB enthalten zugleich gesetzliche Informationen zu Ihren Rechten nach den Vorschriften über Verträge im Fernabsatz und im elektronischen Geschäftsverkehr.) 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                1. Geltungsbereich
                  </h4>
                  Für alle Lieferungen der AI Simplicity GmbH an Verbraucher (§ 13 BGB) gelten diese Allgemeinen Geschäftsbedingungen (AGB). Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann.
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                2. Vertragspartner
                  </h4>
                  Der Kaufvertrag kommt zustande mit der: 
                  <br />
                  AI Simplicity GmbH®
                  <br />
                  AI Simplicity GmbH, c/o Weinrausch GmbH, St.-Karli-Strasse 13c, 6004 Telefon: info@Clip-this.com www.clip-this.com 
                  <br />
                  <br /> 
                  Sie erreichen unseren Kundendienst für Fragen, Reklamationen und Beanstandungen Montag bis Donnerstag von 8:00 bis 17:00 Uhr und Freitags von 8:00 bis 14:00 Uhr per E-Mail unter info@clip-this.com. 
                  <br />
                  <br />
                <h4 className={classes.cardTitle}>
                3. Angebot und Vertragsschluss
                  </h4>
                  3.1 Die Darstellung der Produkte im Online-Shop stellt kein rechtlich bindendes Angebot, sondern eine Aufforderung zur Bestellung dar. Irrtümer vorbehalten. 3.2 Durch Anklicken des Buttons [Kaufen] geben Sie eine verbindliche Bestellung der auf der Bestellseite aufgelisteten Waren ab. Der Kaufvertrag kommt zustande, wenn wir Ihre Bestellung durch eine Auftragsbestätigung per E-Mail unmittelbar nach dem Erhalt Ihrer Bestellung annehmen
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                4. Widerrufsrecht
                  </h4>
                  Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat. 
                <br />
                <br />
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                AI Simplicity GmbH
                  </h4>
                  AI Simplicity GmbH, c/o Weinrausch GmbH, St.-Karli-Strasse 13c, 6004 Telefon: info@Clip-this.com www.clip-this.com 
                <br />
                <br />
                mittels einer eindeutigen Erklärung (z. B. Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. 
                <br />
                <br />
                Ein Muster-Widerrufsformular (als PDF) finden Sie hier: Muster-Widerrufsformular Folgen des Widerrufs Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit Ihnen zurückzuführen ist. Hinweis: das Widerrufsrecht besteht nicht bei den folgenden Verträgen: Verträge zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind. 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                5. Preise und Versandkosten
                  </h4>
                  5.1 Für den Versand nach Deutschland wird keine Mehrwertsteuer ausgewiesen. 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                6. Lieferung
                  </h4>
                  6.1 Die Lieferung erfolgt innerhalb Deutschland mit UPS oder der deutschen Post 6.2 Angaben zu Lieferzeiten finden Sie auf der jeweiligen Produktseite. 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                7. Zahlung
                  </h4>
                  Die Zahlung erfolgt wahlweise per Barzahlung bei Abholung, Rechnung oder Vorkasse. 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                Vertragstext
                  </h4>
                  Der Vertragstext wird auf unseren Systemen gespeichert. Die Allgemeinen Geschäftsbedingungen können Sie jederzeit auf dieser Seite einsehen. Soweit Sie ein Kundenkonto erstellt haben, können Sie Ihre Kundendaten jederzeit unter [Mein Konto] einsehen. Die Bestelldaten werden Ihnen per E-Mail zugesendet. Nach Abschluss der Bestellung ist der Vertragstext aus Sicherheitsgründen nicht mehr über das Internet zugänglich. 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                Fehler oder Irrtümer
                  </h4>
                  Alle Preisangaben sind unverbindlich. Fehler und Irrtum vorbehalten. 
                <br />
                <br />
                <h4 className={classes.cardTitle}>
                Europäische Kommission
                  </h4>
                  Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter http://ec.europa.eu/consumers/odr/ 
                <br />
                <br />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

        </div>
        </div>
    );
  }
}

AGB.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(agbdatenschutzPageStyle)(AGB);
