import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import FileCopy from "@material-ui/icons/FileCopy";
import Store from "@material-ui/icons/Store";
import Info from "@material-ui/icons/Info";
//import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import ExerciseIcon from "@material-ui/icons/AccessibilityNew";
import ForwardIcon from "@material-ui/icons/Forward";
import WallpaperIcon from "@material-ui/icons/Wallpaper";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Table from "../../../components/Table/Table.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
//import Danger from "../../components/Typography/Danger.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";

import Calendar from 'react-calendar';

// AWS App Sync
import { graphql, compose } from 'react-apollo'
import GetItem from '../../../queries/GetItem'

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
  pieChart
} from "../../../variables/charts";

import dashboardStyle from "../../../assets/views/dashboardStyle";

const thumb_dancing = require("../../../assets/image/thumbs/thumb_dancing.jpg");
const thumb_ballet = require("../../../assets/image/thumbs/thumb_ballet.jpg");
const thumb_wedding = require("../../../assets/image/thumbs/thumb_wedding.jpg");
const thumb_christmas = require("../../../assets/image/thumbs/thumb_christmas.jpg");
const thumb_nutrician = require("../../../assets/image/thumbs/thumb_nutrician.jpg");
const thumb_physio = require("../../../assets/image/thumbs/thumb_korb.jpg");


class StatsOfPatient extends React.Component {

  state = {

    displayName: "Patienten Statistik Beispiel",
    analytics: null,

    viewsTotal: 0,
    viewsLast30Days: 0,  
    usersTotal: 0,
    usersLast30Days: 0,
    averageActions: 0,
    averageViewsPerUser: 0,
    stdViewsPerUser: 0,

    numberDays: 7,
    dailyViewsChart: {labels: ["M", "T", "W", "T", "F", "S", "S"], series:[[14,20,43,0,0,46,17]]},
    dailyActionChart: null,
    date: new Date(),
  };

  async componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div style={{backgroundColor:"#333333"}}>
      <div className={classes.content}>
        <div className={classes.container}>
       
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>{this.state.displayName}</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <CardHeader>
                <h3>Aktivitätsanzeige</h3>
              </CardHeader>
              <CardBody>
                <Calendar
                  onChange={this.onChange}
                  value={this.state.date}
                  />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={this.state.dailyViewsChart}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.lineGraph}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Aktivität</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  Steigerung in heutigen Views.
                </p>*/}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardBody>
                <h3>Bridging Beine Anheben</h3>
                <h4>Wohlbefinden/ Schmerzen</h4>
                <h4>Fitness</h4>
                <h4>Kommentare</h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardBody>
                <h3>Bridging Statisch</h3>
                <h4>Wohlbefinden/ Schmerzen</h4>
                <h4>Fitness</h4>
                <h4>Kommentare</h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardBody>
                <h3>Double Leg Raise</h3>
                <h4>Wohlbefinden/ Schmerzen</h4>
                <h4>Fitness</h4>
                <h4>Kommentare</h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardBody>
                <h3>Extension Im Liegen</h3>
                <h4>Wohlbefinden/ Schmerzen</h4>
                <h4>Fitness</h4>
                <h4>Kommentare</h4>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      </div>
      </div>
    );
  }
}

StatsOfPatient.propTypes = {
  classes: PropTypes.object.isRequired
};
 
export default withStyles(dashboardStyle)(StatsOfPatient)

