import React from "react";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';

import devices from "../../../assets/image/devices.png";
import devicesMobile from "../../../assets/image/devices_mobile.png";
import monkeyImage from "../../../assets/image/monkey.jpg";
import analyticsImage from "../../../assets/image/analyticsImage.png"
import demosPDF from "../../../assets/image/demosPDF.pdf"
import demosImage from "../../../assets/image/CLIPTHISDemos.png"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";

import homeGif1 from '../../../assets/image/homeGifs/homeGif1.gif';
import homeGif2 from '../../../assets/image/homeGifs/homeGif2.gif';
import homeGif3 from '../../../assets/image/homeGifs/homeGif3.gif';
import homeGif4 from '../../../assets/image/homeGifs/homeGif4.gif';
import homeGif5 from '../../../assets/image/homeGifs/homeGif5.gif';
import homeGif6 from '../../../assets/image/homeGifs/homeGif6.gif';
// Mobile
import homePageStyle from "../../../assets/views/homePageStyle.jsx";

import {AppContainer} from "../../../assets/views/material-dashboard-pro-react.jsx";

class Home extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
  
    this.state = {
      email: this.props.email,
      userName: this.props.userName,
    };

    this.nextPath = this.nextPath.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  nextPath(path) {

    this.props.history.push({
      pathname: path
      //state: {detail: packet}
      });
  }
      
  render() {
    
    const { classes } = this.props;
    
    return (
      <div>
      {/* <MediaQuery minWidth={1224}> */}
      <div className={classes.content}>
        <AppContainer>
          <GridContainer className={classes.wowpostContainer}>
            <GridItem className={classes.gridItemImg} xs={12} sm={12} md={7} >
            <MediaQuery minWidth={1224}>
            <div >
              <img src={devices} alt="CLIPTHIS EXAMPLE IMAGE" className={classes.homePageImg}/>
            </div>
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
            <div >
              <img src={devicesMobile} alt="CLIPTHIS EXAMPLE IMAGE" className={classes.homePageImg}/>
            </div>
            </MediaQuery>
            </GridItem>
            <GridItem className={classes.wowPostItems} xs={12} sm={12} md={5}>
              <div className={classes.wowPostText}>
                <h1 className={classes.header}>
                  Augmented Reality einfach erstellen und erleben
                </h1>
                <h3 className={classes.cardTitle}>CLIPTHIS erweckt deine Bilder zum Leben</h3>
                <Button className={classes.button} round color="primary" onClick={() => this.nextPath('/apps' , 1)}>
                  JETZT HERUNTERLADEN
                </Button>        
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <h2 className={classes.title}></h2>
                </GridItem>
              </GridContainer>
          <GridContainer>
            <GridItem className={classes.wowPostItems} xs={12} sm={12} md={3}>
              <div className={classes.videoPlayerText}>
                <h2 className={classes.headerBlack}>
                  AR direkt auf deinem Smartphone
                </h2>
                <h3 className={classes.cardSubTitle}>Mit der kostenlosen CLIPTHIS App kannst du deine Realität erweitern und dir zusätzliche Inhalte auf Printmedien anzeigen lassen.</h3>       
              </div>
            </GridItem>
            <GridItem className={classes.homeVideoPlayer} xs={12} sm={12} md={9}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                <div className={classes.imgWrap}>
                  <img src={homeGif1} className={classes.homePageGif}/>
                  <div className={classes.imgDescription}>
                  <div className={classes.textOnHover}>
                    <h3 className={classes.headerGif}>A Little Party Never Killed Nobody</h3>
                    <p className={classes.paragraphGif}> Zeig deinen Fans wie du auf der Bühne abgehst und bring die ganzen Emotionen vom Festival auf deine Werbeplakate.</p>
                    </div>
                  </div>
                </div>
                <br/>
                <div className={classes.imgWrap}>
                  <img src={homeGif2} className={classes.homePageGif}/>
                  <div className={classes.imgDescription}>
                  <div className={classes.textOnHover}>
                    <h3 className={classes.headerGif}>Wenn ALLE POLKA muss EINER TANGO</h3>
                    <p className={classes.paragraphGif}> Sei anders als die meisten und zeig das deinen Kunden.</p>
                  </div>
                  </div>
                </div>
                <br/>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className={classes.imgWrap}>
                  <img src={homeGif3} className={classes.homePageGif}/>
                  <div className={classes.imgDescription}>
                  <div className={classes.textOnHover}>
                    <h3 className={classes.headerGif}>Das Auge isst mit</h3>
                    <p className={classes.paragraphGif}> Zeige deinen Kunden Videos von deinen Speisen, ihrer Herkunft oder ihrer Zubereitung.</p>
                  </div>
                  </div>
                </div>
                <br/>
                <div className={classes.imgWrap}>
                  <img src={homeGif4} className={classes.homePageGif}/>
                  <div className={classes.imgDescription}>
                  <div className={classes.textOnHover}>
                    <h3 className={classes.headerGif}>Keep Calm and Go Shopping</h3>
                    <p className={classes.paragraphGif}> Zeig deinen Kunden, wie deine Kleider auf dem Laufsteg ankamen und verlinke sie direkt in deinen Warenkorb.</p>
                  </div>
                  </div>
                </div>
                <br/>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <div className={classes.imgWrap}>
                  <img src={homeGif5} className={classes.homePageGif}/>
                  <div className={classes.imgDescription}>
                  <div className={classes.textOnHover}>
                    <h3 className={classes.headerGif}>Zeig mir was du liest und ich sag dir wer du bist</h3>
                    <p className={classes.paragraphGif}> Mach die Bilder in deiner Zeitung lebendig und vermittle mehr Emotion durch Bild und Ton.</p>
                  </div>
                  </div>
                </div>
                <br/>
                <div className={classes.imgWrap}>
                  <img src={homeGif6} className={classes.homePageGif}/>
                  <div className={classes.imgDescription}>
                  <div className={classes.textOnHover}>
                    <h3 className={classes.headerGif}>Vergiss Mein Nicht</h3>
                    <p className={classes.paragraphGif}> Hinterlege deine Visitenkarte mit einem Video und stelle sicher, dass auch nach großen Events jeder weiß wer du bist.</p>
                  </div>
                  </div>
                </div>
                <br/>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <h2 className={classes.title}></h2>
                </GridItem>
              </GridContainer>
          <GridContainer className={classes.gridContainerWhite}>
            <GridItem xs={12} sm={12} md={7}>
              <div>
                <img src={analyticsImage} alt="Analytics Dashboard" className={classes.homePageImgAnalytics}/>
              </div>
              </GridItem>
              <GridItem className={classes.wowPostItems} xs={12} sm={12} md={5}>
                <div className={classes.wowPostText}>
                  <h2 className={classes.headerBlack}>
                    Ausführliche Statistik
                  </h2>
                  <h3 className={classes.cardSubTitle}>Verstehe, welche Inhalte wann und wo angeschaut werden.</h3>
                  <h3 className={classes.cardSubTitle}>Nutze CLIPTHIS als neuen Kanal zur Activation neuer Kunden.</h3>
                  <h3 className={classes.cardSubTitle}>Verfolge die Attraktivität und Effektivität deiner Kampagnen.</h3>      
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={12} md={1}></GridItem>
            <GridItem className={classes.wowPostItems} xs={12} sm={12} md={5}>
                <div className={classes.wowPostText}>
                  <h2 className={classes.headerBlack}>
                    Eigene Inhalte erstellen
                  </h2>
                  <h3 className={classes.cardSubTitle}>Mit Augmented Reality Freunde, Familie, Mitarbeiter oder Kunden überraschen? CLIPTHIS macht es dir so einfach wie nie!</h3>
                  <h3 className={classes.cardSubTitle}>Gestalte und erstelle deine eigenen Inhalte direkt vom Smartphone oder Webapp; du brauchst nur ein Bild und ein Video.</h3>
                  <h3 className={classes.cardSubTitle}>Du willst das auch? Erstelle jetzt deine eigenen Inhalte für dich und deine Freunde</h3>
                  <Button round color="primary" onClick={() => this.nextPath('/tryit' , 1)}>
                      JETZT LOSLEGEN
                  </Button> 
                </div>
              </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <div>
                <img src={monkeyImage} alt="Eigene Inhalte erstellen" className={classes.homePageImgMonkey}/>
              </div>
              </GridItem>
            </GridContainer>
        <GridContainer justify="center" className={classes.gridContainerWhite}>
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title}>Werde CLIPTHIS Partner</h2>
            <h4 className={classes.subTitle}>Agenturen, Druckereien und Creative Guys bekommen bei CLIPTHIS ihre eigenen Accounts mit mehr Spielraum und attraktiven Umsatzbeteiligungen.</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={5}></GridItem>
          <GridItem xs={12} sm={12} md={2} className={classes.homeButtons}>
            <Button round color="primary" onClick={() => this.nextPath('/partner' , 1)}>
                      JETZT ANFRAGEN
            </Button> 
          </GridItem>
          <GridItem xs={12} sm={12} md={5}></GridItem>
        </GridContainer>
        <GridContainer justify="center" gridalign="center" className={classes.gridBottomContainer}>
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title} style={{color:"#fff"}}>CLIPTHIS Beispiele</h2>
            <h4 className={classes.subTitle} style={{color:"#fff"}}> Das bewegt Menschen!</h4>
            <h4 className={classes.subTitle} style={{color:"#fff"}}> Entdecke beliebte Inhalte und teste unsere Demos.</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div style={{textAlign: "center", margin: "30px 0"}}>
              <a href={demosPDF} target="_blank" className={classes.button} >DEMOS HERUNTERLADEN</a>
            </div>                     
          </GridItem >
          <img src={demosImage} alt="CLIPTHIS Demos" className={classes.bottomImg}/>
        </GridContainer>
        </AppContainer>
      </div>
  </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homePageStyle)(Home);
