// @material-ui/core 
import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { Image, FormGroup, FormControl } from "react-bootstrap";
import { Player } from "video-react";
import { ClipLoader } from "react-spinners";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

// Custom components
import Button from "../../../components/CustomButtons/Button.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";
import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";

// AWS
import { Storage, Auth } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import * as mutations from "../../../graphql/mutations";

// Our configs
import config from "./../../../config.js";
import userRights from "./user_rights.json";

// https://serverless-stack.com/chapters/display-a-note.html

class EditItem extends Component {
  constructor(props) {
    super(props);

    this.fileVideo = null;
    this.fileImage = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      imageWidth: 200,
      imageHeight: 200,
      item: null,
      attachmentURL: null,
      imageChanged: false,
      imageFile: null,
      qrSize: 50,

      clipThisId: this.props.location.state.clipThisId,
      userId: this.props.userName,
      maxViews: this.props.location.state.maxViews,
      views: this.props.location.state.views,
      analytics: this.props.location.state.analytics,
      actionData: this.props.location.state.actionData,
      actionType: this.props.location.state.link,
      actionName: this.props.location.state.actionName,
      displayName: this.props.location.state.displayName,
      active: this.props.location.state.active,
      premium: this.props.location.state.premium,
      qrposx: 0,
      qrposy: 0,
      createdAt: this.props.location.state.createdAt,
      description: this.props.location.state.description,
      edit: true,
      webarStatus: this.props.location.state.webarStatus,
      appsStatus: this.props.location.state.appsStatus,
      updatedAt: JSON.parse(this.props.location.state.updatedAt),

      publishInApps: false,
      publishInWebAR: false
    };
    console.log(this.state);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const bucket = config.s3.ATTACHMENTS.BUCKET;
    const region = config.s3.REGION;
    const level = config.s3.ATTACHMENTS.LEVEL;
    const imageKey = this.state.clipThisId + ".jpg";
    const videoKey = this.state.clipThisId + ".mp4";

    try {
      const imageUrl = await Storage.vault.get(imageKey, {
        bucket,
        region,
        level
      });
      this.setState({
        imageFile: imageUrl
      });
      this.fileImage = imageUrl;
      // Callback function after Image is changed
      this.getImageWidthHeight(imageUrl, result => {
        var width = 0;
        var height = 0;
        var qrSize = 0;
        // Height > Width
        if (result.width <= result.height) {
          // Check if height is bigger than screen
          if (result.height > 1200) {
            height = 1200;
            width = (height / result.height) * result.width;
          } else {
            width = result.width;
            height = result.height;
          }
          qrSize = 0.2 * width;
        }
        // Width > Height
        else {
          // Check if width is bigger than screen
          if (result.width > 600) {
            width = 600;
            height = (width / result.width) * result.height;
          } else {
            width = result.width;
            height = result.height;
          }
          qrSize = 0.2 * height;
        }
        this.setState({
          qrSize: qrSize,
          imageWidth: width,
          imageHeight: height
        });
      });
    } catch (e) {
      alert(e);
    }

    try {
      const videoUrl = await Storage.vault.get(videoKey, {
        bucket,
        region,
        level
      });
      this.setState({
        videoFile: videoUrl,
        videoPoster: null
      });
      this.videoFile = videoUrl;
    } catch (e) {
      alert(e);
    }
  }

  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("VIDEO");

    video.onloadeddata = function(e) {
      var canvas = document.createElement("canvas");
      var w = video.videoWidth;
      var h = video.videoHeight;
      canvas.height = h;
      canvas.width = w;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback({ width: w, height: h, image: img, error: e });
    };
    video.onerror = function(e) {
      callback.call(undefined, undefined, undefined, e);
    };
    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }

  handleChargedCheckboxChange = name => event => {
    let checked = event.target.checked;
    if (checked) {
      this.showChargesConfirmationAlert()
    } 
    this.setState({ [name]: checked });
  }

  showChargesConfirmationAlert = () => {
    alert("Diese Einstellung verursacht Kosten in Abhängigkeit von Ihrem Vertrag mit der AI Simplicity GmbH. Wir werden Ihren Fall prüfen und Sie bei Bedarf kontaktieren. Mit dem Ankreuzen dieses Kontrollkästchens bestätigen Sie, dass Ihnen die möglichen Gebühren bekannt sind.");
  }

  validateForm() {
    return this.state.datalaws;
  }

  // Called when user chooses video. Sets new video, triggers getting of width and height.
  handleVideoFileChange = event => {
    this.fileVideo = event.target.files[0];
    if (this.fileVideo.type !== "video/mp4") {
      alert(`Please pick an video with the format .mp4.`);
      return;
    }
    let url = URL.createObjectURL(this.fileVideo);

    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null,
      videoFrameHeight: 250
    });

    // callback function after video is changed
    this.getVideoInfos(url, result => {
      this.setState({ videoWidth: result.width });
      this.setState({ videoHeight: result.height });
      //this.setState({videoPoster: result.image.path});
      this.setState({ videoWasChanged: true });
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      var w = img.naturalWidth || img.width;
      var h = img.naturalHeight || img.height;
      callback({ width: w, height: h });
    };
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleCheckboxChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });
  };

  handleAmountChange = name => event => {
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    if (this.fileVideo 
      && this.fileVideo.type !== "video/mp4" 
      && this.fileVideo.type !== "video/mov") {
      alert(`Please pick an video with the format .mp4 or .mov.`);
      return;
    }
    if (this.state.imageChanged) {
      if (this.fileImage 
        && this.fileImage.type !== "image/jpeg" 
        && this.fileImage.type !== "image/png") {
        alert(`Please pick an image with format .png or .jpeg.`);
        return;
      }
    }

    if (this.fileVideo 
      && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `The size of your video has to be smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    try {
      // Update the item in database
      const {
        clipThisId,
        maxViews,
        views,
        actionData,
        actionType,
        actionName,
        displayName,
        premium,
        active,
        qrposxpercentage,
        qrposypercentage,
        description,
        createdAt,
        publishInApps,
        publishInWebAR,
        updatedAt
      } = this.state;
      const qrposx = qrposxpercentage;
      const qrposy = qrposypercentage;

      // File Upload
      const bucket = config.s3.ATTACHMENTS.BUCKET;
      const region = config.s3.REGION;
      const visibility = config.s3.ATTACHMENTS.LEVEL;
      const selectedFile = this.fileImage;
      const selectedVideo = this.fileVideo;
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;

      let file;
      let videoFile;

      if (selectedFile) {
        const { name: fileName, type: mimeType } = selectedFile;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${"."}${extension}`;

        file = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedFile
        };

        // Record that image file was just updated
        updatedAt['file'] = Date.now().toString(); 
      }

      if (selectedVideo) {
        const { name: fileName, type: mimeType } = selectedVideo;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${"."}${extension}`;

        videoFile = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedVideo
        };

        // Record that video file was just updated
        updatedAt['videoFile'] = Date.now().toString(); 
      }

      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey
        },
        complexObjectsCredentials: () => Auth.currentCredentials()
      });

      var publishingTypes = []
      if (publishInApps) {
        publishingTypes.push("APPS")
      }
      if (publishInWebAR) {
        publishingTypes.push("WEBAR")
      }
      for (var i = 0; i < publishingTypes.length; i++) {
        await client.mutate({
          mutation:gql(mutations.requestPublishingItem),
          variables: {
            clipThisId: clipThisId,
            publishingType: publishingTypes[i]
          }
        })
      }

      await client.mutate({
        mutation: gql(mutations.updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            userId,
            maxViews,
            views,
            actionData,
            actionType,
            actionName,
            displayName,
            premium,
            active,
            qrposx,
            qrposy,
            file: file,
            videoFile: videoFile,
            description: description,
            createdAt: createdAt,
            updatedAt: JSON.stringify(updatedAt),
            webarStatus: publishInWebAR ? 'REQUESTED' : null,
            appsStatus: publishInApps ? 'REQUESTED' : null
          }
        }
      });

      this.props.history.push("/pages/listitems");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;

    // If user can edit views,
    // no need to show the option to request  the increase from us.
    const canEditViews = userRights["change_views"].includes(this.props.email);

    // We can request publishing in apps/webar was never requested, i.e. is null.
    const canPublishInWebAR = !this.state.webarStatus;
    const canPublishInApps = !canEditViews && !this.state.appsStatus;

    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <GridContainer>
              <Sidebar />
            </GridContainer>
            <div className={classes.spinner}>
              <ClipLoader
                className={classes.spinner}
                sizeUnit={"px"}
                size={150}
                color={"#123abc"}
                loading={this.state.isLoading}
              />{" "}
            </div>
            <div className={classes.container}>
              <Card>
                <CardBody>
                  <GridContainer justify="center">
                    <h2> Inhaltsbearbeitung </h2>
                  </GridContainer>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>Name des Inhalts</h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <BasicCustomInput
                        labelText={this.state.displayName}
                        id="displayname"
                        formControlProps={{fullWidth: true}}
                        onChange={this.handleChange("displayName")}
                        value={this.state.displayName}/>
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>
                        Weiterleitungslink (Aktiviert den "Erfahren Sie mehr
                        Button" in der App bei Aufruf des Inhalts und verweist
                        bei Klick auf diese Seite)
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <BasicCustomInput
                        labelText={this.state.actionData}
                        id="actionData"
                        formControlProps={{ fullWidth: true }}
                        onChange={this.handleChange("actionData")}
                        value={this.state.actionData}/>
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>Beschreibung</h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <textarea
                        style={{
                          backgroundColor: "white",
                          width: "100%",
                          border: "#cccccc 1px solid",
                          borderRadius: "4px",
                          padding: "0.429em 0.857em"
                        }}
                        data-limit-rows={true}
                        rows={5}
                        onChange={this.handleChange("description")}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>
                        Maximale Anzahl von Aufrufen
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <h4>5</h4>
                    </GridItem>
                    {canEditViews ? (
                      <GridItem xs={12}>
                        <br />
                        <h4 className={classes.infoText}>
                          Maximale Anzahl von Aufrufen ändern
                        </h4>
                        <TextField
                          id="maxViews"
                          label=""
                          value={this.state.maxViews}
                          onChange={this.handleChange("maxViews")}
                          type="number"
                          className={classes.textField}
                          margin="normal"/>
                      </GridItem>
                    ) : null}
                    {canPublishInApps ? (
                       <GridItem xs={12}>
                       <br />
                       <h4 className={classes.infoText}>In Apps veröffentlichen</h4>
                       <h5 className={classes.infoText}>
                         Erhöht die Anzahl der maximalen Ansichten in Apps für den Inhalt. Ausgeführt innerhalb von 24 Stunden.
                       </h5>
                       <h5>
                         Verursacht Kosten in Abhängigkeit von Ihrem Vertrag.
                       </h5>
                       <FormControl
                         type="checkbox"
                         value={this.state.publishInApps}
                         onChange={this.handleChargedCheckboxChange("publishInApps")}
                         placeholder=""
                       />
                     </GridItem>
                    ) : null }
                    {canPublishInWebAR ? (
                      <GridItem xs={12}>
                        <br />
                        <h4 className={classes.infoText}>
                          In WebAR veröffentlichen
                        </h4>
                        <h5 className={classes.infoText}>
                          Ausgeführt innerhalb von 24 Stunden.
                        </h5>
                        <h5>
                          Verursacht Kosten in Abhängigkeit von Ihrem Vertrag.
                        </h5>
                        <FormControl
                          type="checkbox"
                          value={this.state.publishInWebAR}
                          onChange={this.handleChargedCheckboxChange("publishInWebAR")}
                          placeholder=""
                        />
                      </GridItem>
                    ) : null }
                    <GridItem xs={12}>
                      <h4 className={classes.infoText}>
                        Bitte bestätigen Sie unsere{" "}
                        <a href="pages/datenschutz" target="_blank">
                          Datenschutzregeln*
                        </a>
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl
                        type="checkbox"
                        value={this.state.datalaws}
                        onChange={this.handleCheckboxChoice("datalaws")}
                        placeholder=""
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Button
                        round
                        color="primary"
                        disabled={!this.validateForm()}
                        onClick={this.handleSubmit}>
                        Ändern
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          </div>
        </MediaQuery>
        <MobileMessage />
      </div>
    );
  }
}

EditItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(privateUploadPageStyle)(EditItem);
