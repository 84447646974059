// ##############################
// // // LoginPage view styles
// #############################

import {
    container,
    largeScreenSideBar,
    mediumScreenSideBar,
    cardTitle,
    colors
  } from "./material-dashboard-pro-react.jsx";
  
  const privateUploadPageStyle = theme => ({
    container: {
      ...container,
      [theme.breakpoints.up("1224")]: {
        marginRight: `calc((100vw - ${mediumScreenSideBar}px)/2)`, // align content center on remaining of page
        width: "970px"
      },
      [theme.breakpoints.up("1390")]: {
        marginRight: `calc((100vw - ${largeScreenSideBar}px)/2)`, // align content center on remaining of page
        width: "1170px"
      },
    },
    cardTitle: {
      ...cardTitle,
      color: "#FFFFFF"
    },
    textCenter: {
      textAlign: "center"
    },
    content: {
      backgroundColor: "#eee",
      paddingTop: "18vh",
      paddingBottom: "9vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4",
      [theme.breakpoints.down("1224")]: {
        paddingTop: "0"
      },
    },
    justifyContentCenter: {
      justifyContent: "center !important"
    },
    customButtonClass: {
      "&,&:focus,&:hover": {
        color: "#FFFFFF"
      },
      marginLeft: "5px",
      marginRight: "5px"
    },
    inputAdornment: {
      marginRight: "18px"
    },
    inputAdornmentIcon: {
      color: "#555"
    },
    cardHidden: {
      opacity: "0",
      transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
      marginBottom: "20px"
    },
    socialLine: {
      padding: "0.9375rem 0"
    },
    registerText: {
      cursor: "pointer",
      color: "#3C4858",
      textDecoration: "none",
      fontWeight: "300",
      marginTop: "30px",
      marginBottom: "25px",
      marginLeft: "15px",
      minHeight: "32px",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
      }
    },
    infoText: {
      fontWeight: "300",
      margin: "5px 0 5px",
      textAlign: "left"
    },
    choiche: {
      textAlign: "center",
      cursor: "pointer",
      marginTop: "20px"
    },
    dragme: {
      position: "absolute",
      cursor: "move",
    },
    spinner: {
      display: "block",
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: "5",
      margin: "0 auto"
    },
    customFileInputInvisible: {
      opacity: "0",
      position: "absolute",
      pointerEvents: "none",
      // alternative to pointer-events, compatible with all browsers, just make it impossible to find
      width: "1px",
      height: "1px",
    },
    customFileInputStyle: {
      minHeight: "30px",
      minWidth: "150px",
      backgroundColor: colors.primaryColor,
      color: "#FFFFFF",
      boxShadow:
        "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
      border: "none",
      borderRadius: "3px",
      position: "relative",
      padding: "12px 30px",
      margin: ".3125rem 1px",
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "uppercase",
      letterSpacing: "0",
      willChange: "box-shadow, transform",
      transition:
        "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
      lineHeight: "1.42857143",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      cursor: "pointer",
      "&:hover,&:focus": {
        color: "#FFFFFF",
        backgroundColor: colors.grayColor,
        boxShadow:
          "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
      },
    }
  });
  
  export default privateUploadPageStyle;
  