import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import NavPills from "../../../components/NavPills/NavPills.jsx";
import MediaQuery from 'react-responsive';


const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 20px",
    textAlign: "left"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Step1Explanation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  isValidated(){
    return true;
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={11}>
          <h4 className={classes.infoText}>Lieber Kunde,</h4>
          <h4 className={classes.infoText}>wir freuen uns, dass Sie dieses Jahr ganz besondere Weihnachtskarten versenden möchten.</h4>
          <h4 className={classes.infoText}>Um Ihnen den <span style={{fontWeight: "bold"}}>Bestellprozess möglichst einfach und fehlerfrei</span> zu garantieren, würden wir Sie bitten, sich kurz die Beschreibung zu den vier erforderlichen Schritten durchzulesen.</h4>
          <h4 className={classes.infoText}>Besten Dank und frohe Weihnachten</h4>
          <h4 className={classes.infoText}><span style={{fontWeight: "bold"}}>Ihr Team von WowPost</span></h4>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <br/>
          <br/>
          <Divider/>
          <br/>
          <br/>
        </GridItem>
        <GridItem xs={12} sm={11}>
          <NavPills
            color="rose"
            horizontal={{
              tabsGrid: { xs: 12, sm: 12, md: 4 },
              contentGrid: { xs: 12, sm: 12, md: 8 }
            }}
            tabs={[
              {
                tabButton: "1. Bild / Video",
                tabContent: (
                  <span>
                    <h4 className={classes.infoText}>Hier haben Sie die Wahl Ihre eigene Karte zu kreiieren. 
                      Laden Sie dazu Ihr Video und Ihr Bild in den <span style={{fontWeight: "bold"}}>Abmassen 16:9</span> hoch. 
                      Alternativ dürfen Sie auch den tanzenden Santa aus der Vorauswahl übernehmen.
                    </h4>
                  </span>
                )
              },
              {
                tabButton: "2. Karte",
                tabContent: (
                  <span>
                    <h4 className={classes.infoText}>In diesem Bereich erstellen Sie die <span style={{fontWeight: "bold"}}>Nachricht, die auf alle Ihre Karten kommt.</span>
                      Die persönliche Anrede und Gruß für jeden Empfänger folgt im nächsten Schritt. 
                      Zusätzlich können Sie entscheiden, ob Sie die Schritt-für-Schritt Anleitung auf der Karte anzeigen möchten.
                    </h4>
                  </span>
                )
              },
              {
                tabButton: "3. Adressen",
                tabContent: (
                  <span>
                    <h4 className={classes.infoText}>Geben Sie an, an wen die Karten geschickt werden sollen. 
                      Bei vielen Empfängern steht Ihnen ein <span style={{fontWeight: "bold"}}>Excel zum Download</span> zur Verfügung. 
                      Zusätzlich haben Sie hier in drei Feldern die Möglichkeit, 
                      Ihre Anrede, Ihren Gruß und Ihren Namen für jeden Empfänger zu personalisieren.
                    </h4>
                  </span>
                )
              },
              {
                tabButton: "4. Checkout",
                tabContent: (
                  <span>
                    <h4 className={classes.infoText}>Geben Sie einen <span style={{fontWeight: "bold"}}>Wunschliefertermin</span> für Ihre Karten an.
                      Wir garantieren die Lieferung innerhalb einer Arbeitswoche. 
                      Anschliessend bezahlen Sie bequem mit Kreditkarte
                    </h4>
                  </span>
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <h4 className={classes.infoText}>Lieber Kunde,</h4>
                  <h4 className={classes.infoText}>wir freuen uns, dass Sie dieses Jahr ganz besondere Weihnachtskarten versenden möchten.</h4>
                  <h4 className={classes.infoText}>Um Ihnen den <span style={{fontWeight: "bold"}}>Bestellprozess möglichst einfach und fehlerfrei</span> zu garantieren, würden wir Sie bitten, sich kurz die Beschreibung zu den vier erforderlichen Schritten durchzulesen.</h4>
                  <h4 className={classes.infoText}>Besten Dank und frohe Weihnachten</h4>
                  <h4 className={classes.infoText}><span style={{fontWeight: "bold"}}>Ihr Team von WowPost</span></h4>
                </GridItem>
              </GridContainer>
          </div>
        </div>
      </MediaQuery>
      </div>
    );
  }
}

export default withStyles(style)(Step1Explanation);
