import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";

import withStyles from "@material-ui/core/styles/withStyles";

import "react-table/react-table.css";
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";

class ItemsTable extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <ReactTable
        data={this.props.data}
        noDataText={this.props.placeholderText}
        columns={[
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header: "Views / Max. Views",
            accessor: "views",
            width: 200,
            // Sort by number of views, ignoring max views
            sortMethod: (a, b) => {
              let viewsA = parseInt(a.split('/')[0]);
              let viewsB = parseInt(b.split('/')[0]);
              return viewsA > viewsB ? 1 : -1;
            }
          },
          {
            Header: "WebAR",
            accessor: "webarStatus",
            width: 150,
            sortable: false,
            filterable: false
          },
          {
            Header: "Apps",
            accessor: "appsStatus",
            width: 150,
            sortable: false,
            filterable: false
          },
          {
            Header: "Aktion",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: "name",
            desc: false
          }
        ]}
        defaultPageSize={10}
        showPaginationBottom={true}
        className={classes.table}
      />
    );
  }
}

ItemsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    placeholderText: PropTypes.string.isRequired
};

export default withStyles(listItemsPageStyle)(ItemsTable);
