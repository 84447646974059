// ##############################
// // // Pages Layout styles
// #############################

import {
    container,
    defaultFont
  } from "../views/material-dashboard-pro-react.jsx";
  
const pagesStyle2 = {
wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
    overflow: "hidden"
},
fullPage: {
    "&:before,&:after": {
    display: "block",
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    zIndex: "2"
    }
},
fullPageMobile: {
    "&:before": {
    backgroundColor: "#F5F5F7"
    },
    "&:before,&:after": {
    display: "block",
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    zIndex: "2"
    }
},
appBar: {
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    zIndex: "1029",
    border: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    height: "71px"
},
container: {
    ...container,
    minHeight: "50px",
    height: "71px",
    margin: "0 !important"
},
flex: {
    flex: 1
},
title: {
    ...defaultFont,
    lineHeight: "30px",
    fontWeight: "700",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "#242424",
    "&:hover,&:focus": {
    background: "transparent",
    color: "#08f"
    }
},
logoStyle: {
    width: "50px", 
    background: "#242424", 
    borderRadius: "50%"
},
list: {
    ...defaultFont,
    fontSize: "10px",
    fontWeight: "700",
    margin: 0,
    marginRight: "-15px",
    paddingLeft: "0",
    listStyle: "none",
    color: "#FFFFFF",
    paddingTop: "0",
    paddingBottom: "0",
    width: "80vw"
},
listItem: {
    float: "left",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
},
navLink: {
    color: "#fff",
    margin: "0 5px",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontWeight: "800",
    fontSize: "14px",
    textTransform: "uppercase",
    lineHeight: "20px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    textDecoration: "none",
    "&:hover": {
    color: "#08f"
    },
    "&:focus":{
    color: "#242424"
    }
},
listItemText: {
    flex: "none",
    padding: "0",
    minWidth: "0",
    margin: 0,
    display: "inline-block",
    position: "relative",
    whiteSpace: "nowrap"
}
};

export default pagesStyle2;
  