import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import MediaQuery from "react-responsive";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Timeline from "@material-ui/icons/Timeline";
import ForwardIcon from "@material-ui/icons/Forward";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import DotIcon from "@material-ui/icons/Lens";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Table from "../../../components/Table/Table.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx"

// AWS App Sync
import { graphql, compose } from 'react-apollo'
import GetItem from '../../../queries/GetItem'

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
  pieChart
} from "../../../variables/charts";

import dashboardStyle from "../../../assets/views/dashboardStyle";

import priceImage1 from "../../../assets/image/card-2.jpeg";
import priceImage2 from "../../../assets/image/card-3.jpeg";
import priceImage3 from "../../../assets/image/card-1.jpeg";

const ch_flag = require("../../../assets/image/flags/CH.png");
const de_flag = require("../../../assets/image/flags/DE.png");
const es_flag = require("../../../assets/image/flags/ES.png");
const gb_flag = require("../../../assets/image/flags/GB.png");
const fr_flag = require("../../../assets/image/flags/FR.png");
const it_flag = require("../../../assets/image/flags/IT.png");

const thumb_dancing = require("../../../assets/image/thumbs/thumb_dancing.jpg");
const thumb_ballet = require("../../../assets/image/thumbs/thumb_ballet.jpg");
const thumb_wedding = require("../../../assets/image/thumbs/thumb_wedding.jpg");
const thumb_christmas = require("../../../assets/image/thumbs/thumb_christmas.jpg");
const thumb_nutrician = require("../../../assets/image/thumbs/thumb_nutrician.jpg");
const thumb_physio = require("../../../assets/image/thumbs/thumb_korb.jpg");

var mapData = {
  ES: 760,
  AU: 550,
  CA: 120,
  DE: 1300,
  GB: 690,
  GE: 200,
  IN: 200,
  FR: 600,
  IT: 300,
  CH: 2920
};

class Dashboard extends React.Component {
  state = {
    viewsTotal: 0,
    viewsLast30Days: 0,
    
    usersTotal: 0,
    usersLast30Days: 0,
    
  };

  async componentDidMount() {
    window.scrollTo(0, 0)

    const data = this.props.clipThisItem;
    console.log("Data ", data);
  }

  notAvailableInDemo() {
    alert("Diese Funktion steht Ihnen in der Demo Version nicht zur Verfügung.")
  }
  
  render() {
    const { classes } = this.props;
    const option = {
      height: '280px',
    };

    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div style={{backgroundColor:"#333333"}}>
      <div className={classes.content}>
        <GridContainer>
          <Sidebar />
        </GridContainer>
        <div className={classes.container}>
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>Analytics Dashboard Beispiel</h2>
              <h5 className={classes.description}>
              <a href="/pages/contact" target="_blank">Kontaktieren</a> Sie uns jetzt, um Analytics auf Ihrem Account freizuschalten. 
              </h5>
            </GridItem>
          </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <p className={classes.cardCategory}><br/></p>
                <h3 className={classes.cardTitle}>
                  80000 <small>/ 650 Usern</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  <WallpaperIcon />
                  Abrufe gesamt
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <p className={classes.cardCategory}><br/></p>
                <h3 className={classes.cardTitle}>4991 <small>/ 120 Usern</small></h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  <WallpaperIcon />
                  Abrufe letzte 30 Tage
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <p className={classes.cardCategory}><br/></p>
                <h3 className={classes.cardTitle}>0.7<small>Klicks pro User</small></h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  <ForwardIcon />
                  Weiterleitungsrate (30Tage)
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <p className={classes.cardCategory}><br/></p>
                <h3 className={classes.cardTitle}>2.1 <small>User kehren zurück</small></h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <ForwardIcon />
                  Wiederkehrhäufigkeit
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={7}>
            <Card>
              <CardHeader color="success" icon>
                <h4 className={classes.cardIconTitle}>
                  Top Kampagnen
                </h4>
              </CardHeader>
              <CardBody>
                    <Table
                      tableHead={["Thumbnail", "Kampagne", "Users", "Views"]}
                      tableData={[
                        [
                          <img src={thumb_dancing} alt="us_flag" style={{width:"20px"}}/>,
                          "Dancing Man",
                          "2.920",
                          "36.301"
                        ],
                        [
                          <img src={thumb_christmas} alt="us_flag" style={{width:"20px"}} />,
                          "Christmas Card",
                          "1.300",
                          "20.436"
                        ],
                        [
                          <img src={thumb_physio} alt="us_flag" style={{width:"20px"}} />,
                          "CLIPTHIS Physio",
                          "760",
                          "10.351"
                        ],
                        [
                          <img src={thumb_nutrician} alt="us_flag" style={{width:"20px"}} />,
                          "CLIPTHIS Nutrition",
                          "690",
                          "7.872"
                        ],
                        [
                          <img src={thumb_ballet} alt="us_flag" style={{width:"20px"}} />,
                          "Ballet",
                          "600",
                          "5.940"
                        ],
                        [
                          <img src={thumb_wedding} alt="us_flag" style={{width:"20px"}} />,
                          "CLIPTHIS Wedding",
                          "550",
                          "4.341"
                        ]
                      ]}
                    />
                    </CardBody>
                    </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                    <Card>
                      <CardHeader color="info" icon>
                        <h4 className={classes.cardIconTitle}>Erreichte User je Kampange</h4>
                      </CardHeader>
                      <CardBody>
                      <ChartistGraph
                        data={pieChart.data}
                        options={option}
                        type={'Pie'}
                        />
                      </CardBody>
                      {/*
                      <CardFooter>
                        <DotIcon color="warning"/>Flyer
                        <DotIcon color="sucess"/>Plakat
                        <DotIcon color="danger"/>Zeitung
                        <DotIcon color="info"/>Messe
                      </CardFooter>*/}
                    </Card>
                  </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.lineGraph}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Views</h4>
                <p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  Steigerung in heutigen Views.
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={emailsSubscriptionChart.options}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                  className={classes.lineGraph}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Email Subscriptions</h4>
                <p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 15%
                  </span>{" "}
                  Steigerung in heutige Subscriptions.
                </p>
              </CardBody>
              <CardFooter chart>
              <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={completedTasksChart.data}
                  type="Line"
                  options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                  className={classes.lineGraph}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Weiterleitungen</h4>
                <p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 35%
                  </span>{" "}
                  Steigerung in heutigen Weiterleitungen.
                </p>
              </CardBody>
              <CardFooter chart>
              <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <h3>Deine Kampagnen</h3>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={priceImage1} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Dancing Man
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4>36.301 Views</h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Place /> Schweiz
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={priceImage2} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Dancing Santa
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4>20.436 Views</h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Place /> Deutschland
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={priceImage3} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon onClick={() => {this.notAvailableInDemo()}}>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Balerina
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4>500 Views</h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Place /> Schweiz
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <h4 className={classes.cardIconTitle}>
                  Kampangen Aufrufe in Europa
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={5}>
                  <VectorMap
                      map={"europe_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "280px"
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: mapData,
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial"
                          }
                        ]
                      }}
                    />
                    
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <Table
                      tableData={[
                        [
                          <img src={ch_flag} alt="ch_flag" />,
                          "Schweiz",
                          "2.920",
                          "53.23%"
                        ],
                        [
                          <img src={de_flag} alt="us_flag" />,
                          "Deutschland",
                          "1.300",
                          "20.43%"
                        ],
                        [
                          <img src={es_flag} alt="es_flag" />,
                          "Spanien",
                          "760",
                          "10.35%"
                        ],
                        [
                          <img src={gb_flag} alt="gb_flag" />,
                          "Großbritanien",
                          "690",
                          "7.87%"
                        ],
                        [
                          <img src={fr_flag} alt="fr_flag" />,
                          "Frankreich",
                          "600",
                          "5.94%"
                        ],
                        [
                          <img src={it_flag} alt="it_flag" />,
                          "Italien",
                          "550",
                          "4.34%"
                        ]
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      </div>
      </div>
      </MediaQuery>
      <MobileMessage/>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
 
export default withStyles(dashboardStyle)(
    graphql(GetItem, {
      options: (props) => ({
        variables: { clipThisId: "Link_Ballet" }
      }),
      props: props => ({
        status: props.data.networkStatus ? props.data.networkStatus : "empty",
        clipThisItem: props.data.clipThisItem ? props.data.clipThisItem : "empty"
        //clipThisItem: props.data.clipThisItem ? props.data.clipThisItem.item : "empty"
        })
    })
  (Dashboard)
)
