import React from "react";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';

import macbook from "../../../assets/image/macbook.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import {AppContainer} from "../../../assets/views/material-dashboard-pro-react.jsx";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";

import homePageStyle from "../../../assets/views/homePageStyle.jsx";


class Test extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    
    this.nextPath = this.nextPath.bind(this);
    this.holdirdieapp = this.holdirdieapp.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  holdirdieapp(){

  }


  nextPath(path) {

    this.props.history.push({
      pathname: path
      //state: {detail: packet}
      });
  }
      
  render() {
    const { classes } = this.props;
    return (
      <div>
      {/*<MediaQuery minWidth={1224}>*/}
      <div className={classes.partnerContent}>
        <AppContainer>
              <GridContainer className={classes.wowpostContainer}>
                <GridItem className={classes.gridItemImg} xs={12} sm={12} md={7}>
                 <img src={macbook} alt="macbook" className={classes.homePageImgMac}/>
                </GridItem>
                <GridItem className={classes.wowPostItems} xs={12} sm={12} md={5}>
                  <div className={classes.wowPostText}>
                    <h2 className={classes.header}>
                      Eigene Inhalte erstellen
                    </h2>
                    <h3 className={classes.cardTitle}>Begeistere deine Freunde, Familie, Mitarbeiter oder Kunden mit deinen Inhalten. Tob dich aus. Werde zum Content Creator und mach deine Beiträge öffentlich. Lehre, unterhalte und bespasse deine Fans und Follower.</h3>
                    <MediaQuery minWidth={1224}>
                    <Button className={classes.button} round color="primary" onClick={() => this.nextPath('/pages/newItem' , 1)}>
                      JETZT LOSLEGEN
                    </Button>     
                    </MediaQuery>
                    <MediaQuery maxWidth={1224}>
                    <h4 className={classes.cardTitle}>Unsere Platform ist momentan nur auf dem Laptop verfügbar.</h4>
                    </MediaQuery>
                  </div>
                </GridItem>
              </GridContainer>
        </AppContainer>
      </div>
      {/*
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div style={{backgroundImage:null, backgroundColor:"#F5F5F7"}}>
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.titleMobile}>Eigene Inhalte erstellen</h2>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={6} sm={4} align="center">
                <Player    
                  autoPlay={true}
                  loop={true}           
                  src={wowpostvideo}
                  fluid={false}
                  width={100}>
                  <BigPlayButton disabled/>
                  <ControlBar disabled/>
                </Player>
              </GridItem>
              <GridItem xs={6} sm={2} align="left">
                  <div className={classes.descriptionMobile}>CLIPTHIS erlaubt dir deine eigenen Augmented Reality Inhalte zu erstellen und mit deinen Freunden zu teilen.</div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12}>
              <a href="https://itunes.apple.com/us/app/clipthis/id1427352327?ls=1&mt=8"> 
              <ReactButton fullWidth variant="contained" color="secondary" className={classes.buttonMobile}>
                <div className={classes.buttonDescriptionMobile}>Hol dir die App</div>
                </ReactButton></a>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>AR selber machen</h2>
                <p>Lade mit ein paar Klicks dein eigenes Video und Bild hoch. Ein paar Minuten später sind deine Inhalte in der App abrufbar.</p>
              </GridItem>
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>Statistik Feedback </h2>
                <p>Wie viele Personen haben deine Bilder angeschaut? Verfolge den Erfolg deiner Inhalt live auf deinem Statistik Dashboard.</p>
              </GridItem>
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>Druck und Versand</h2>
                <p>Gestalte deine eigene Weihnachtskarte. Wir versenden diese automatisch an die Adressen deiner Wahl.</p>
              </GridItem>     
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <img src={descriptionImage} alt="CLIPTHIS"/>
              </GridItem>
            </GridContainer>
            <Products/>
          </div>
        </div>
      </div>
      </MediaQuery> */}
  </div>
    );
  }
}

Test.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homePageStyle)(Test);
