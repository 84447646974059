import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";

// core components
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Payment with stripe
import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentForm from '../../../components/CheckoutForm/PaymentForm.jsx';
import config from '../../../config.js'

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  summaryText:{
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "left"
  },
  spinner:{
    display: "block",
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "5",
    margin: "0 auto"
  },
  calender:{
    fontSize: "15px"
  }

};

class Step6Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.isLoading,
      name: this.props.name,
      email: this.props.email,
      date: "empty",
      startDate: new Date(), 
      endDate: "24-12-2018"
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  isValidated() {
    return true;
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    
    var current = new Date();
    this.setState({
      startDate: new Date(current.getTime() + 2 * 86400000),
      //endDate: new Date(current.getTime() + 30 * 86400000),

    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Checkout</h4>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <GridContainer>
            <GridItem><h4 className={classes.infoText}>Name</h4></GridItem>
            <GridItem>
              <CustomInput
                labelText={this.state.name}
                id="streetname"
                formControlProps={{
                  fullWidth: true
                }}
                onChange={this.handleChange("name")}
                value={this.state.name}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem><h4 className={classes.infoText}>Email</h4></GridItem>
            <GridItem>
          <CustomInput
            labelText={this.state.email}
            id="streetno"
            formControlProps={{
              fullWidth: true
            }}
            onChange={this.handleChange("email")}
            value={this.state.email}
          />
          </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem><h4 className={classes.infoText}>Wunsch Liefertermin</h4></GridItem>
            <GridItem>
          <DatePicker
            selected={moment(this.state.startDate)}
            onChange={this.handleDateChange}
            maxDate={moment("2018-12-24")}
            calendarClassName={classes.calender}
          />
          <br/>
          <br/>
          <h5 className={classes.summaryText}>Der späteste Wunschtermin für Weihnachtskarten ist der 24.12.</h5>
          </GridItem>
          </GridContainer>
        </GridItem>
          <GridItem xs={12} sm={6} md={6}>
              <StripeProvider apiKey={config.STRIPE_KEY}>
                <Elements>
                  <PaymentForm handleSubmit={this.props.handleSubmit}/>
                </Elements>
              </StripeProvider>
          </GridItem>
      </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(Step6Checkout);
