import React from "react";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';

import partnerImage from "../../../assets/image/CLIPTHISPartner.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";

import homePageStyle from "../../../assets/views/homePageStyle.jsx";

import {AppContainer, AppContent} from "../../../assets/views/material-dashboard-pro-react.jsx";

class Partner extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    
    this.nextPath = this.nextPath.bind(this);
    this.holdirdieapp = this.holdirdieapp.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  holdirdieapp(){

  }

  nextPath(path) {

    this.props.history.push({
      pathname: path
      //state: {detail: packet}
      });
  }
      
  render() {
    const { classes } = this.props;
    return (
      <div>
      {/* <MediaQuery minWidth={1224}> */}
      <div className={classes.partnerContent}>
        <AppContainer>
          <GridContainer className={classes.wowpostContainer}>
            <GridItem className={classes.gridItemImg} xs={12} sm={12} md={7}>
              <img src={partnerImage} alt="devices" className={classes.homePageImgMac}/>
            </GridItem>
            <GridItem className={classes.wowPostItems} xs={12} sm={12} md={5}>
              <div className={classes.wowPostText}>
                <h2 className={classes.header}>
                Sei innovativ, sei anders
                </h2>
                <h3 className={classes.cardTitle}>Alle CLIPTHIS Partner bekommen eigene Plattformzugänge und angepasste Pläne, abgestimmt um bestmöglich die jeweilige Zielgruppe anzusprechen. Wir sind kontinuierlich auf der Suche nach strategischen Partnern und freuen uns tauschen uns gerne unverbindlich aus.</h3>
                <Button className={classes.button} round color="primary" onClick={() => this.nextPath('/pages/contact' , 1)}>
                  JETZT INFORMIEREN
                </Button>  
              </div>
            </GridItem>
          </GridContainer>
          {/* <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}></h2>
            </GridItem>
          </GridContainer> */}
        </AppContainer>
      </div>
      {/* </MediaQuery> */}

      {/* <MediaQuery maxWidth={1224}>
        <div style={{backgroundImage:null, backgroundColor:"#F5F5F7"}}>
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h2 className={classes.titleMobile}>Willkommen bei der CLIPTHIS Weihnachtsaktion</h2>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12}>
              <a href="https://itunes.apple.com/us/app/clipthis/id1427352327?ls=1&mt=8"> 
              <ReactButton fullWidth variant="contained" color="secondary" className={classes.buttonMobile}>
                <div className={classes.buttonDescriptionMobile}>Hol dir die App</div>
                </ReactButton></a>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>AR selber machen</h2>
                <p>Lade mit ein paar Klicks dein eigenes Video und Bild hoch. Ein paar Minuten später sind deine Inhalte in der App abrufbar.</p>
              </GridItem>
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>Statistik Feedback </h2>
                <p>Wie viele Personen haben deine Bilder angeschaut? Verfolge den Erfolg deiner Inhalt live auf deinem Statistik Dashboard.</p>
              </GridItem>
              <GridItem xs={12}>
                <h2 className={classes.bulletsMobile}>Druck und Versand</h2>
                <p>Gestalte deine eigene Weihnachtskarte. Wir versenden diese automatisch an die Adressen deiner Wahl.</p>
              </GridItem>     
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <img src={descriptionImage} alt="CLIPTHIS"/>
              </GridItem>
            </GridContainer>
            <Products/>
          </div>
        </div>
      </div>
    </MediaQuery> */}
  </div>
    );
  }
}

Partner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(homePageStyle)(Partner);
