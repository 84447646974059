import { Storage } from "aws-amplify";

export async function s3Upload(file, filename, fileType) {
  
  // User Storage.put if upload is public
  // Storage.vault.put for private upload in users folder
  const stored = await Storage.vault.put(filename, file, {
    contentType: fileType
  });

  return stored.key;
}
